// React
import React, { useState, useEffect } from 'react';

// Prop types
import PropTypes from 'prop-types';

// Mui styles
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';

// Mui core
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditButtonDialog from './EditButtonDialog';

// random string
import randomString from 'random-string';

const styles = () =>
	({
		wrapper : {
			width           : '100%',
			backgroundColor : 'white',
			boxShadow       : '3px 3px 10px #aaa',
			padding         : '0.5% 1%'
		},
		group : {
			marginBottom : '2%'
		},
		pageTitle : {
			color      : 'var(--text-color)',
			fontSize   : '1.2rem',
			lineHeight : '1.4rem'
		},
		createCallTargetBtnWrapper :
		{
			width          : '100%',
			display        : 'flex',
			justifyContent : 'end',
			marginRight    : '20px'
		},
		table :
		{
			marginBottom : '25px'
		},
		noItemRow :
		{
			padding : '20px 0',
			color   : 'var(--text-color)'
		},
		tableBtnRight :
		{
			marginLeft : '10px'
		}

	});

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	},
	overrides : {
		MuiInput : {
			underline : {
				'&:before' : {
					borderBottom : 'none'
				},
				'&:after' : {
					borderBottom : 'none'
				},
				'&:not(.Mui-disabled):hover::before' : {
					borderBottom : 'none'
				},
				'&.Mui-disabled:before' : {
					borderBottomStyle : 'none'
				},
				'&.Mui-disabled' : {
					backgroundColor : '#F6F6F6'
				}
			}
		},
		MuiSelect : {
			select : {
				'&:focus' : {
					borderRadius : '0.4rem'
				}
			}
		},
		MuiTypography : {
			root : {
				color    : 'var(--text-color)',
				fontSize : '0.8rem'
			},
			body1 : {
				color    : 'var(--text-color)',
				fontSize : '0.8rem'
			}
		}
	}
});

const MultiBtnsSetting = ({
	classes,
	type,
	buttons,
	setButtons
}) =>
{
	const [ editButtonDialogState, setEditButtonDialogState ] = useState({
		show        : false,
		button      : {},
		buttonIndex : null,
		newItem     : false
	});

	// add btnkey if type === 'connect'
	const [ newBtnKey, setNewBtnKey ] = useState('');

	const showEditButtonDialog = (index, newItem) =>
	{
		setEditButtonDialogState({
			show        : true,
			button      : (index || index === 0) ? buttons[index] : {},
			buttonIndex : (index || index === 0) ? index : buttons.length,
			newItem     : newItem,
			newBtnKey   : newBtnKey
		});
	};

	const updateButton = (btnState, index) =>
	{
		if (index || index === 0)
		{
			setButtons((state) =>
			{
				const newButtons = [ ...state ];

				newButtons[index]
					= { ...btnState };

				return newButtons;
			});
		}
	};

	const deleteButton = (index) =>
	{
		if (index >= 0)
		{
			setButtons((state) =>
			{
				const newButtons = [ ...state ];

				newButtons.splice(index, 1);

				return newButtons;
			});

			setEditButtonDialogState({
				show        : false,
				button      : {},
				buttonIndex : null,
				newItem     : false
			});
		}
	};

	useEffect(() =>
	{
		if (type === 'connect')
		{
			const buttonsKeys = [];

			buttons.forEach((btn) =>
			{
				if (btn.btnKey)
				{
					buttonsKeys.push(btn.btnKey);
				}
			});

			let newBtnKeyVal = '';

			while (!newBtnKeyVal)
			{
				const newBtnKeyCandidate = randomString({ length: 8 }).toLowerCase();

				if (!buttonsKeys.includes(newBtnKeyCandidate))
				{
					newBtnKeyVal = newBtnKeyCandidate;
				}
			}

			setNewBtnKey(newBtnKeyVal);
		}
	}, [ type, setNewBtnKey, buttons ]);

	return (
		<MuiThemeProvider theme={theme}>
			<EditButtonDialog
				show={editButtonDialogState.show}
				button={editButtonDialogState.button}
				btnIndex={editButtonDialogState.buttonIndex}
				newItem={editButtonDialogState.newItem}
				updateButton={updateButton}
				deleteButton={deleteButton}
				closeMethod={() => setEditButtonDialogState({
					show        : false,
					button      : {},
					buttonIndex : null,
					newItem     : false
				})}
				type={type}
				newBtnKey={newBtnKey}
			/>
			<Box className={`${classes.group}`}>
				<Box className={`${classes.wrapper}`}>
					<Typography className={classes.pageTitle}>
						ボタン設定
					</Typography>
					<Box className={classes.createCallTargetBtnWrapper}>
						<Button
							variant='contained'
							color='primary'
							onClick={() => showEditButtonDialog(null, true)}
						>
							追加
						</Button>
					</Box>

					<TableContainer component={Paper}>
						<Table className={classes.table} aria-label='call target table'>
							<TableHead>
								<TableRow>
									<TableCell align='left'>サブドメイン</TableCell>
									<TableCell align='left'>スキルID</TableCell>
									<TableCell align='left'>名称</TableCell>
									<TableCell align='right'>操作</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{ buttons && buttons.length > 0 ?
									<>
										{	buttons.map((b, index) => (
											<TableRow key={index}>
												<TableCell align='left'>{b.subdomain}</TableCell>
												<TableCell align='left'>{b.skillId}</TableCell>
												<TableCell align='left'>
													{b.label ? b.label : b.btnText}
												</TableCell>
												<TableCell align='right'>
													<Button
														variant='contained'
														color='primary'
														onClick={
															() => showEditButtonDialog(index, false)
														}
													>
														編集
													</Button>
													<Button
														variant='contained'
														color='secondary'
														onClick={
															() => deleteButton(index)
														}
														className={classes.tableBtnRight}
													>
														削除
													</Button>
												</TableCell>
											</TableRow>
										))}
									</>
									:
									<TableRow>
										<TableCell
											align='center'
											className={classes.noItemRow}
											colSpan={4}
										>
											アイテムがありません
										</TableCell>
									</TableRow>

								}
							</TableBody>
						</Table>
					</TableContainer>

				</Box>
			</Box>
		</MuiThemeProvider>
	);
};

MultiBtnsSetting.propTypes =
{
	classes    : PropTypes.object.isRequired,
	type       : PropTypes.string,
	buttons    : PropTypes.array.isRequired,
	setButtons : PropTypes.func.isRequired
};

export default withStyles(styles)(MultiBtnsSetting);
