// Ticket channel
export const ISSUED_AT_ADMIN_PAGE = 1;
export const ISSUED_AT_GUEST_PAGE = 2;
export const ISSUED_AT_SYSTEM_ADMIN_PAGE = 3;

// Ticket payment
export const PAYMENT_FREE = 0;
export const PAYMENT_MONTHLY = 1;
export const PAYMENT_CREDIT_CARD = 2;
export const PAYMENT_AMAZON_PAY = 3;

// Tiket Price List Item Show Page
export const PURCHASE_PAGE_ONLY = 1;
export const MANAGE_PAGE_ONLY = 2;
export const PURCHASE_AND_MANAGE_PAGE = 3;

// ServiceAccount
export const DEFAULT_SERVICE_ACCOUNT_KEY = 'like';

// convert service code
export const CONVERT_STRINGS = [
	'N', 'x', 'w', 'H', '@', 'q', 'T', '+', 'S',
	'F', 's', 'r', 'd', 'L', 'B', '2', 'y', 'O',
	'g', 'J', 'V', 'M', 'K', 'k', '3', 'i', 'h',
	'm', 'E', '0', '8', 'W', 'v', 'Y', '/', 'c',
	't', 'a', 'C', 'e', 'X', '7', 'n', 'l', 'D',
	'j', 'b', 'u', '4', '5', 'P', '9', 'Z', 'z',
	'-', 'A', 'p', 'G', 'U', '1', '6', 'R', 'Q',
	'o', 'f', 'I'
];

export const CODE_POS_CHANGE = [
	17, -9, 20, -3, -23, 14, 4, -15, -24, -7, 22, 18, 12,
	-24, -3, -2, -16, -14, 17, -21, 13, -4, -8
];