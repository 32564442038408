import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withRoomContext } from '../../../../RoomContext';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';

// Component
import ConnectPurchaseContent from '../../Common/ConnectPurchaseContent';

const styles = () =>
	({
		root :
		{
			zIndex : 99
		},
		dialogPaper :
		{
			width           : '95vw',
			maxWidth        : '1250px !important',
			padding         : '1% 1%',
			backgroundColor : '#FFF'
		}
	});

const ConnectPurchaseDialog = ({
	classes,
	show,
	closeMethod,
	accountId,
	paramKey,
	fileResult,
	priceListPurchase,
	buyer
}) =>
{

	const [ page, setPage ] = useState('file');

	const closeDialog = () =>
	{
		setPage('file');
		closeMethod();
	};

	return (
		<Dialog
			className={classes.root}
			open={show}
			scroll={'paper'}
			onClose={() => {}}
			classes={{
				paper : classes.dialogPaper
			}}
		>
			<ConnectPurchaseContent
				buyer={buyer}
				closeMethod={closeDialog}
				accountId={accountId}
				paramKey={paramKey}
				fileResult={fileResult}
				priceListPurchase={priceListPurchase}
				page={page}
				setPage={setPage}
			/>
		</Dialog>
	);
};

ConnectPurchaseDialog.propTypes =
{
	classes           : PropTypes.object.isRequired,
	show              : PropTypes.bool.isRequired,
	buyer             : PropTypes.string.isRequired,
	closeMethod       : PropTypes.func.isRequired,
	accountId         : PropTypes.string.isRequired,
	paramKey          : PropTypes.string.isRequired,
	fileResult        : PropTypes.array.isRequired,
	priceListPurchase : PropTypes.array.isRequired

};

const makeMapStateToProps = () =>
{
	const mapStateToProps = () =>
	{
		return {
		};
	};

	return mapStateToProps;
};

const mapDispatchToProps = () =>
{
	return {
	};
};

export default withRoomContext(connect(
	makeMapStateToProps,
	mapDispatchToProps,
	null,
	{
		areStatesEqual : () =>
		{
			return ({
			});
		}
	}
)(withStyles(styles)(ConnectPurchaseDialog)));