// React
import React, { useState, useCallback, useRef, useEffect } from 'react';

// Prop types
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { withRoomContext } from '../../../../RoomContext';

// Message
import { useIntl, FormattedMessage } from 'react-intl';

// Mui styles
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';

// Mui core
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Firebase
import { auth, firestore, functions } from '../../../../lib/firebase';

const styles = (theme) =>
	({
		root : {
			display         : 'flex',
			width           : '100%',
			height          : '100%',
			backgroundColor : '#E5E4E1',
			justifyContent  : 'center',
			alignItems      : 'center'
		},
		content : {
			width                          : '800px',
			height                         : '450px',
			backgroundColor                : '#FFF',
			display                        : 'flex',
			justifyContent                 : 'space-between',
			borderLeft                     : '5px solid #ffdc00',
			[theme.breakpoints.down('sm')] : {
				width          : '85vw',
				flexDirection  : 'column',
				justifyContent : 'center',
				alignItems     : 'center',
				borderLeft     : 'none'
			},
			[theme.breakpoints.down('xs')] : {
				width : '95vw'
			}
		},
		logoSection : {
			width                          : '250px',
			display                        : 'flex',
			justifyContent                 : 'center',
			alignItems                     : 'center',
			borderRight                    : '2px solid #DDD',
			[theme.breakpoints.down('sm')] : {
				width       : '100%',
				borderRight : 'none'
			}
		},
		loginSection : {
			display                        : 'flex',
			width                          : '550px',
			justifyContent                 : 'center',
			alignItems                     : 'center',
			padding                        : '0 20px',
			[theme.breakpoints.down('sm')] : {
				width : '100%'
			}
		},
		logo : {
			width  : 'auto',
			height : '2rem'
		},
		inputContent : {
			width          : '100%',
			display        : 'flex',
			flexDirection  : 'column',
			alignItems     : 'center',
			justifyContent : 'space-between'
		},
		inputGroup : {
			width         : '100%',
			display       : 'flex',
			flexDirection : 'column',
			alignItems    : 'flex-start'
		},
		contentTitle : {
			color        : 'var(--text-color)',
			fontSize     : '1rem',
			width        : '100%',
			textAlign    : 'center',
			marginBottom : '15px',
			fontFamily   : '"M PLUS Rounded 1c", sans-serif'
		},
		inputGroupRow : {
			width         : '100%',
			display       : 'flex',
			flexDirection : 'row',
			alignItems    : 'center',
			paddingBottom : '1.5rem'
		},
		inputGroupRowTitle : {
			width    : '40%',
			color    : 'var(--text-color)',
			fontSize : '0.8rem'
		},
		inputBg : {
			width           : '60%',
			border          : 'none',
			backgroundColor : 'var(--text-color) !important',
			borderRadius    : '0.5rem'
		},
		accountInfoEnd : {
			paddingBottom : '15px',
			marginBottom  : '15px',
			borderBottom  : '1px solid var(--text-color)'
		},
		itemTitle : {
			color        : 'var(--text-color)',
			fontSize     : '0.7rem',
			marginLeft   : '0rem',
			marginBottom : '0.1rem',
			fontFamily   : '"M PLUS Rounded 1c", sans-serif'
		},
		input : {
			width        : '100%',
			border       : 'none',
			marginBottom : '1.5rem'
		},
		passwordForgotGroup : {
			marginTop      : '25px',
			width          : '100%',
			display        : 'flex',
			justifyContent : 'end'
		},
		passwordForgotText : {
			cursor         : 'pointer',
			fontSize       : '0.8rem',
			color          : 'var(--text-color)',
			textDecoration : 'underline'
		},
		backButtonGroup : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'end',
			margin         : '5px 0 30px 0'
		},
		backButton : {
			padding         : '2px 10px',
			backgroundColor : '#FFF',
			color           : 'var(--text-color)',
			border          : '1px solid var(--text-color)',
			'&:hover'       : {
				color           : 'var(00text-color)',
				backgroundColor : '#FFF',
				border          : '1px solid var(--text-color)'
			}
		},
		codes : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'center',
			alignItems     : 'center',
			marginBottom   : '30px'
		},
		inputCode : {
			width        : '2rem',
			height       : '2.5rem',
			margin       : '0 2%',
			borderRadius : '0.5rem'
		},
		button : {
			width           : '100%',
			backgroundColor : '#ffdc00',
			marginTop       : '1rem',
			marginBottom    : '1rem',
			color           : '#292929',
			'&:hover'       : {
				color           : '#292929',
				backgroundColor : '#ffdc00',
				filter          : 'saturate(105%) brightness(105%)'
			}
		},
		notification : {
			width        : '500px',
			height       : '1.2rem',
			color        : 'var(--text-color)',
			marginBottom : '8px',
			fontSize     : '0.8rem',
			textAlign    : 'center'
		},
		errorMsg : {
			width        : '500px',
			height       : '1.2rem',
			color        : 'red',
			marginBottom : '8px',
			fontSize     : '0.8rem',
			textAlign    : 'center'
		},
		loading : {
			width           : '100vw',
			height          : '100vh',
			zIndex          : 9999999,
			position        : 'fixed',
			top             : 0,
			left            : 0,
			display         : 'flex',
			justifyContent  : 'center',
			alignItems      : 'center',
			backgroundColor : 'rgba(255, 255, 255, 0.5)'
		}
	});

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	},
	overrides : {
		MuiPaper : {
			rounded : {
				borderRadius : '0'
			}
		},
		MuiButton : {
			label : {
				marginLeft  : '0.6rem',
				marginRight : '0.6rem'
			}
		},
		MuiOutlinedInput : {
			root : {
				width : '100%'
			},
			input : {
				width           : '100%',
				padding         : '0.4rem 0.8rem',
				border          : '1px solid #CCC',
				borderRadius    : '0.3rem',
				backgroundColor : 'white',
				color           : 'var(--text-color)',
				'&:disabled'    : {
					backgroundColor : 'var(--text-color)',
					color           : '#FFF'
				}
			},
			notchedOutline : {
				border : 'none'
			}
		}
	}
});

const zenkakuToHankaku = (str) =>
{
	return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s) =>
	{
		return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
	});
};

const ConnectAdminLoginPage = ({
	classes,
	loginDialogState,
	setLoginDialogState,
	setConnectAccount,
	setIsLoading,
	setSubdomains
}) =>
{

	const intl = useIntl();

	const [ email, setEmail ] = useState('');
	const [ password, setPassword ] = useState('');
	const [ errorMsg, setErrorMsg ] = useState('');
	const [ notification, setNotification ] = useState('');

	const [ newPassword, setNewPassword ] = useState('');
	const [ newPasswordConfirm, setNewPasswordConfirm ] = useState('');

	const [ mobilePhoneNumber, setMobilePhoneNumber ] = useState('');

	const [ passChangeAccountName, setPassChangeAccountName ] = useState('');

	const [ passChangeEmail, setPassChangeEmail ] = useState('');

	const [ passChangeAccountId, setPassChangeAccountId ] = useState('');

	const [ code, setCode ] = useState([ '', '', '', '', '', '' ]);

	const inputRefs = useRef([]);

	const handleChangeEmail = (event) =>
	{
		setEmail(event.currentTarget.value);
	};

	const handleChangePassword = (event) =>
	{
		setPassword(event.currentTarget.value);
	};

	const handleChangeNewPassword = (event) =>
	{
		setNewPassword(event.currentTarget.value);
	};

	const handleChangeNewPasswordConfirm = (event) =>
	{
		setNewPasswordConfirm(event.currentTarget.value);
	};

	const handleChangeMobilePhoneNumber = (event) =>
	{
		setMobilePhoneNumber(event.currentTarget.value);
	};

	const handleChangeCode = (index, e) =>
	{
		code[index] = e.target.value;
		setCode([ ...code ]);

		if ((index + 1 !== code.length) && e.target.value)
		{
			const nextIndex = index + 1;

			inputRefs.current[nextIndex].focus();
		}
	};

	const changePassword = useCallback(async () =>
	{
		const reg = new RegExp(/^[a-zA-Z0-9]+$/);

		const accountIdToChange = passChangeAccountId;

		if (newPassword !== newPasswordConfirm)
		{
			setErrorMsg('パスワードが一致しません');
		}
		else if (reg.test(newPassword) === false)
		{
			setErrorMsg('使用可能な文字は半角英数字です。');
		}
		else if (newPassword.length < 8)
		{
			setErrorMsg('設定できるパスワードは8文字以上です。');
		}
		else if (!accountIdToChange)
		{
			setErrorMsg(intl.formatMessage({
				id             : 'error.error',
				defaultMessage : 'An error has occurred'
			}));
		}
		else
		{
			setIsLoading(true);

			setErrorMsg('');

			try
			{
				const serviceAccountPassChangeAPI = functions.httpsCallable('serviceAccountPassChange');

				const result = await serviceAccountPassChangeAPI(
					{
						type             : 'connect',
						password         : newPassword,
						connectAccountId : accountIdToChange
					});

				if (result.data.success)
				{
					setNotification('パスワードを変更しました');
					setEmail('');
					setPassword('');
					setLoginDialogState({ show: true, page: 'login' });
					setPassChangeAccountId('');
					setPassChangeEmail('');
					setPassChangeAccountName('');
					setIsLoading(false);
				}
				else
				{
					setIsLoading(false);
					setErrorMsg(intl.formatMessage({
						id             : 'error.error',
						defaultMessage : 'An error has occurred'
					}));
				}
			}
			catch (e)
			{
				setErrorMsg(intl.formatMessage({
					id             : 'error.error',
					defaultMessage : 'An error has occurred'
				}));
				setIsLoading(false);
			}
		}
	},
	[
		intl,
		newPassword,
		newPasswordConfirm,
		setLoginDialogState,
		setErrorMsg,
		passChangeAccountId,
		setIsLoading
	]);

	const handleSubmit = useCallback(() =>
	{
		setIsLoading(true);

		setErrorMsg('');
		setNotification('');

		auth.signInWithEmailAndPassword(`${email.trim()}.connectaccount.2nddoor.net`, password)
			.then((userCredential) =>
			{

				if (userCredential)
				{
					const accountId = userCredential.user.uid;

					firestore.collection('connectAccounts').doc(accountId)
						.get()
						.then((response) =>
						{
							if (!response.exists)
							{
								setErrorMsg('ログインに失敗しました。IDとパスワードを確認してください');
							}
							else
							{
								const accountData = response.data();

								if (accountData.passwordChanged)
								{
									setConnectAccount({
										id : accountId,
										...accountData
									});

									setLoginDialogState({ show: false, page: 'login' });

									const sdomains = [];

									if (accountData.buttons)
									{
										accountData.buttons.forEach((b) =>
										{
											if (b.subdomain && !sdomains.includes(b.subdomain))
											{
												sdomains.push(b.subdomain);
											}
										});
									}

									setSubdomains(sdomains);

									setIsLoading(false);
								}
								else
								{
									setPassChangeEmail(email);
									setPassChangeAccountId(accountId);
									setLoginDialogState({ show: true, page: 'passChange' });
									setSubdomains([]);
									setErrorMsg('');
									setIsLoading(false);
								}

							}
						})
						.catch(() =>
						{
							setIsLoading(false);
							setErrorMsg('ログインに失敗しました。IDとパスワードを確認してください');
						});
				}
				else
				{
					setIsLoading(false);
					setErrorMsg('ログインに失敗しました。IDとパスワードを確認してください');
				}

			})
			.catch((error) =>
			{
				setIsLoading(false);
				console.error('signInWithEmailAndPassword error:', error); // eslint-disable-line no-console
				setErrorMsg('ログインに失敗しました。IDとパスワードを確認してください');
			});
	},
	[
		email,
		password,
		setLoginDialogState,
		setErrorMsg,
		setConnectAccount,
		setIsLoading,
		setSubdomains
	]);

	const handleRequestSms = useCallback(async () =>
	{
		const mobilePhoneNumberValue = zenkakuToHankaku(mobilePhoneNumber)
			.replace('-', '')
			.replace('ー', '')
			.trim();

		const phoneNumberReg = new RegExp(/^[0-9]+$/);

		if (mobilePhoneNumberValue.length !== 11
			|| !phoneNumberReg.test(mobilePhoneNumberValue))
		{
			setErrorMsg('携帯電話番号が正しくありません');

			return;
		}

		const serviceRequestSmsAuthAPI = functions.httpsCallable('serviceRequestSmsAuth');

		setIsLoading(true);

		try
		{
			const result = await serviceRequestSmsAuthAPI({
				type              : 'connect',
				mobilePhoneNumber : mobilePhoneNumber
			});

			if (result?.data?.success)
			{
				setLoginDialogState({ show: true, page: 'passForgotCode' });
				setPassChangeAccountId(result.data.accountId);
				setPassChangeEmail(result.data.email);
				setPassChangeAccountName(result.data.accountName);
				setErrorMsg('');
			}
			else
			{

				setErrorMsg('送信できませんでした。電話番号をお確かめください。');
			}
		}
		catch
		{
			setErrorMsg(intl.formatMessage({
				id             : 'error.error',
				defaultMessage : 'An error has occurred'
			}));
			setIsLoading(false);

			return;
		}

		setIsLoading(false);

	}, [ intl, mobilePhoneNumber, setLoginDialogState, setIsLoading ]);

	const handleComfirmSms = useCallback(async () =>
	{
		if (code && code.length === 6)
		{

			setIsLoading(true);
			const serviceCheckSmsAuthAPI = functions.httpsCallable('serviceCheckSmsAuth');

			const result = await serviceCheckSmsAuthAPI({
				id   : passChangeAccountId,
				code : code.join('')
			});

			if (result?.data?.success)
			{
				setLoginDialogState({ show: true, page: 'passChange' });
				setErrorMsg('');
				setIsLoading(false);
			}
			else
			{
				setErrorMsg('認証できませんでした。コードをお確かめください。');
				setIsLoading(false);
			}
		}
		else
		{
			setErrorMsg(intl.formatMessage({
				id             : 'error.error',
				defaultMessage : 'An error has occurred'
			}));
			setIsLoading(false);
		}
	}, [ intl, code, passChangeAccountId, setLoginDialogState, setIsLoading ]);

	const loginByKeydown = useCallback((e) =>
	{

		if (e.keyCode === 13)
		{
			if (loginDialogState.page === 'login')
			{
				handleSubmit();
			}
			else if (loginDialogState.page === 'passChange')
			{
				changePassword();
			}
			else if (loginDialogState.page === 'passForgot')
			{
				handleRequestSms();
			}
			else if (loginDialogState.page === 'passForgotCode')
			{
				handleComfirmSms();
			}
		}
	}, [
		handleSubmit,
		changePassword,
		handleRequestSms,
		handleComfirmSms,
		loginDialogState.page
	]);

	useEffect(() =>
	{
		document.addEventListener('keydown', loginByKeydown);

		return () =>
		{
			// Clean up the subscription
			document.removeEventListener('keydown', loginByKeydown);
		};
	}, [ loginByKeydown ]);

	const backToLoginPage = () =>
	{
		setLoginDialogState({ show: true, page: 'login' });
		setPassChangeAccountId('');
		setPassChangeEmail('');
		setPassChangeAccountName('');
		setErrorMsg('');
		setNotification('');
		setEmail('');
		setPassword('');
		setIsLoading(false);
		setCode([ '', '', '', '', '', '' ]);

	};

	return (
		<Box className={classes.root}>
			<MuiThemeProvider theme={theme}>
				{ loginDialogState.page === 'passChange' ?
					<Box className={classes.content}>
						<Box className={classes.logoSection}>
							<img
								alt='logo'
								src='images/live_translate_logo.png'
								className={classes.logo}
							/>
						</Box>
						<Box className={classes.loginSection}>
							<Box className={classes.inputContent}>
								{ errorMsg &&
								<Typography className={classes.errorMsg}>
									{errorMsg}
								</Typography>
								}
								<Typography className={classes.contentTitle}>
									パスワード変更
								</Typography>

								{ passChangeAccountName &&
								<Typography className={classes.notification}>
									{passChangeAccountName}の管理者パスワードを変更します
								</Typography>
								}
								<Box className={classes.inputGroupRow}>
									<Typography className={classes.inputGroupRowTitle}>
										管理者ID
									</Typography>
									<TextField
										placeholder={passChangeEmail}
										value={''}
										variant='outlined'
										classes={{
											root : classes.inputBg
										}}
										inputProps={{
											classes : {
												input : classes.inputBg
											},
											style : {
												fontSize : '0.8rem',
												color    : 'white'
											}
										}}
										disabled
									/>
								</Box>
								<Box className={`${classes.inputGroupRow} ${classes.accountInfoEnd}`}>
									<Typography className={classes.inputGroupRowTitle}>
										管理者パスワード
									</Typography>
									<TextField
										placeholder='＊＊＊＊＊＊＊＊＊＊＊＊'
										variant='outlined'
										classes={{
											root : classes.inputBg
										}}
										inputProps={{
											classes : {
												input : classes.inputBg
											},
											style : {
												fontSize : '0.8rem',
												color    : 'white'
											}
										}}
										disabled
									/>
								</Box>

								<Box className={classes.inputGroup}>
									<Typography className={classes.itemTitle}>
										新しいパスワード
									</Typography>
									<TextField
										id='password1'
										placeholder={intl.formatMessage({
											id             : 'label.password',
											defaultMessage : 'Password'
										})}
										value={newPassword}
										variant='outlined'
										name='password1'
										type='password'
										classes={{
											root : classes.input
										}}
										inputProps={{
											classes : {
												input : classes.input
											},
											style : {
												fontSize : '0.75rem'
											}
										}}
										onChange={(event) => handleChangeNewPassword(event)}
										autoComplete='off'
									/>
								</Box>
								<Box className={classes.inputGroup}>
									<Typography className={classes.itemTitle}>
										新しいパスワード(確認)
									</Typography>
									<TextField
										id='password2'
										placeholder={intl.formatMessage({
											id             : 'label.password',
											defaultMessage : 'Password'
										})}
										value={newPasswordConfirm}
										variant='outlined'
										name='password2'
										type='password'
										classes={{
											root : classes.input
										}}
										inputProps={{
											classes : {
												input : classes.input
											},
											style : {
												fontSize : '0.75rem'
											}
										}}
										onChange={(event) => handleChangeNewPasswordConfirm(event)}
										autoComplete='off'
									/>
								</Box>

								<Button
									variant='contained'
									color='secondary'
									classes={{
										root : classes.button
									}}
									onClick={changePassword}
								>
									<FormattedMessage
										id='label.update'
										defaultMessage='Update'
									/>
								</Button>
							</Box>
						</Box>
					</Box>
					: loginDialogState.page === 'passForgot' ?
						<Box className={classes.content}>
							<Box className={classes.logoSection}>
								<img
									alt='logo'
									src='images/live_translate_logo.png'
									className={classes.logo}
								/>
							</Box>
							<Box className={classes.loginSection}>
								<Box className={classes.inputContent}>
									{ errorMsg &&
										<Typography className={classes.errorMsg}>
											{errorMsg}
										</Typography>
									}

									<Box className={classes.backButtonGroup}>
										<Button
											size='small'
											className={classes.backButton}
											onClick={() => backToLoginPage()}
										>
											<FormattedMessage
												id='label.back'
												defaultMessage='Back'
											/>
										</Button>
									</Box>
									<Box className={classes.inputGroup}>
										<Typography className={classes.itemTitle}>
											登録携帯電話番号
										</Typography>
										<TextField
											autoFocus
											id='mobilePhone'
											placeholder={intl.formatMessage({
												id             : 'label.mobilePhone',
												defaultMessage : 'Registered Mobile Phone'
											})}
											value={mobilePhoneNumber}
											variant='outlined'
											classes={{
												root : classes.input
											}}
											inputProps={{
												classes : {
													input : classes.input
												},
												style : {
													fontSize : '0.75rem'
												}
											}}
											onChange={(event) => handleChangeMobilePhoneNumber(event)}
											autoComplete='off'
										/>
									</Box>

									<Button
										variant='contained'
										color='secondary'
										classes={{
											root : classes.button
										}}
										onClick={handleRequestSms}
									>
										<FormattedMessage
											id='label.send'
											defaultMessage='Send'
										/>
									</Button>
								</Box>
							</Box>
						</Box>
						: loginDialogState.page === 'passForgotCode' ?
							<Box className={classes.content}>
								<Box className={classes.logoSection}>
									<img
										alt='logo'
										src='images/live_translate_logo.png'
										className={classes.logo}
									/>
								</Box>
								<Box className={classes.loginSection}>
									<Box className={classes.inputContent}>
										{ errorMsg &&
										<Typography className={classes.errorMsg}>
											{errorMsg}
										</Typography>
										}

										<Box className={classes.backButtonGroup}>
											<Button
												size='small'
												className={classes.backButton}
												onClick={() => backToLoginPage()}
											>
												<FormattedMessage
													id='label.back'
													defaultMessage='Back'
												/>
											</Button>
										</Box>
										<Box className={classes.inputGroup}>
											<Typography className={classes.contentTitle}>
												入力した電話番号に送信されたコードを入力してください
											</Typography>
											<Box className={classes.codes}>
												{ code.map((value, index) =>
												{
													return (
														<TextField
															autoComplete='off'
															key={index}
															inputRef={(el) =>
															{ inputRefs.current[index] = el; }}
															variant='outlined'
															classes={{
																root : classes.inputCode
															}}
															inputProps={{
																classes : {
																	input : classes.input
																},
																style : {
																	fontSize  : '1rem',
																	padding   : '0.5rem 0',
																	textAlign : 'center',
																	color     : 'var(--text-color)'
																},
																maxLength : 1
															}}
															value={code[index]}
															onChange={(e) => handleChangeCode(index, e)}
														/>
													);
												})}
											</Box>
										</Box>

										<Button
											variant='contained'
											color='secondary'
											classes={{
												root : classes.button
											}}
											onClick={handleComfirmSms}
										>
											<FormattedMessage
												id='label.send'
												defaultMessage='Send'
											/>
										</Button>
									</Box>
								</Box>
							</Box>
							:
							<Box className={classes.content}>
								<Box className={classes.logoSection}>
									<img
										alt='logo'
										src='images/live_translate_logo.png'
										className={classes.logo}
									/>
								</Box>
								<Box className={classes.loginSection}>
									<Box className={classes.inputContent}>
										{ errorMsg &&
											<Typography className={classes.errorMsg}>
												{errorMsg}
											</Typography>
										}
										{ notification &&
											<Typography className={classes.notification}>
												{notification}
											</Typography>
										}
										<Box className={classes.inputGroup}>
											<Typography className={classes.itemTitle}>
												メールアドレス
											</Typography>
											<TextField
												autoFocus
												id='email'
												placeholder={intl.formatMessage({
													id             : 'label.email',
													defaultMessage : 'Email'
												})}
												variant='outlined'
												classes={{
													root : classes.input
												}}
												inputProps={{
													classes : {
														input : classes.input
													},
													style : {
														fontSize : '0.75rem'
													}
												}}
												value={email}
												onChange={(event) => handleChangeEmail(event)}
												autoComplete='off'
											/>
										</Box>
										<Box className={classes.inputGroup}>
											<Typography className={classes.itemTitle}>
												パスワード
											</Typography>
											<TextField
												id='password'
												placeholder={intl.formatMessage({
													id             : 'label.password',
													defaultMessage : 'Password'
												})}
												value={password}
												variant='outlined'
												name='password'
												type='password'
												classes={{
													root : classes.input
												}}
												inputProps={{
													classes : {
														input : classes.input
													},
													style : {
														fontSize : '0.75rem'
													}
												}}
												onChange={(event) => handleChangePassword(event)}
												autoComplete='off'
											/>
										</Box>

										<Button
											variant='contained'
											color='secondary'
											classes={{
												root : classes.button
											}}
											onClick={handleSubmit}
										>
											<FormattedMessage
												id='label.login'
												defaultMessage='Login'
											/>
										</Button>

										<Box className={classes.passwordForgotGroup}>
											<Typography
												className={classes.passwordForgotText}
												onClick={() => setLoginDialogState({ show: true, page: 'passForgot' })}
											>
												ID・パスワードを忘れた場合
											</Typography>
										</Box>
									</Box>
								</Box>
							</Box>
				}
			</MuiThemeProvider>

		</Box>
	);
};

ConnectAdminLoginPage.propTypes =
{
	classes             : PropTypes.object.isRequired,
	loginDialogState    : PropTypes.object.isRequired,
	setLoginDialogState : PropTypes.func.isRequired,
	setConnectAccount   : PropTypes.func.isRequired,
	setIsLoading        : PropTypes.func.isRequired,
	setSubdomains       : PropTypes.func.isRequired
};

const mapDispatchToProps = () =>
{
	return {
	};
};

export default withRoomContext(connect(
	null,
	mapDispatchToProps
)(withStyles(styles)(ConnectAdminLoginPage)));
