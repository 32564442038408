import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { withRoomContext } from '../../../../RoomContext';
import { useAuthState } from 'react-firebase-hooks/auth';

// Router
import { useHistory, useLocation } from 'react-router-dom';

// components
import ConfirmDialog from '../../Common/ConfirmDialog';
import MyPage from './MyPage';
import PurchaseTicket from './PurchaseTicket';
import ServiceAccount from './ServiceAccount';
import PriceList from './PriceList';

// material ui
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Hidden from '@material-ui/core/Hidden';
import ListItem from '@material-ui/core/ListItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import ListAltIcon from '@material-ui/icons/ListAlt';
import PersonIcon from '@material-ui/icons/Person';
import ShopIcon from '@material-ui/icons/Shop';

// images
import LinkIcon from '../../../../images/service_navi/link_icon.png';
import TranslateLogo from '../../../../images/service_navi/translate_logo.svg';

// firestore
import { auth, firestore } from '../../../../lib/firebase';

// dayjs
import dayjs from 'dayjs';

// components
import ServiceAdminLoginPage from './ServiceAdminLoginPage';

// util
import { parseUrlParams, filterPriceListItem } from '../../../../utils';

// const
import { MANAGE_PAGE_ONLY, PURCHASE_AND_MANAGE_PAGE, PURCHASE_PAGE_ONLY } from '../../../../const';

// dayjsの タイムゾーンの設定
dayjs.extend(require('dayjs/plugin/timezone'));
dayjs.extend(require('dayjs/plugin/utc'));
dayjs.tz.setDefault('Asia/Tokyo');

const drawerWidth = 250;

const styles = (theme) =>
	({
		root :
		{
			width    : '100%',
			height   : '100%',
			position : 'relative',
			color    : 'var(--text-color)'
		},
		wrapper :
		{
			height                       : 'calc( 100% - 56px )',
			width                        : '100%',
			display                      : 'flex',
			justifyContent               : 'space-between',
			backgroundColor              : 'white',
			overflow                     : 'hidden',
			marginTop                    : '56px',
			[theme.breakpoints.up('md')] : {
				marginTop : '0',
				height    : '100%'
			}
		},
		center :
		{
			width           : `calc( 100% - ${drawerWidth}px )`,
			height          : 'calc( 100% - 40px )',
			overflow        : 'auto',
			marginTop       : '5px',
			marginBottom    : '1%',
			backgroundColor : '#4B4B4B'
		},
		drawer : {
			[theme.breakpoints.up('md')] : {
				width    : drawerWidth,
				minWidth : drawerWidth
			}
		},
		toolbar     : theme.mixins.toolbar,
		drawerPaper : {
			width           : drawerWidth,
			backgroundColor : '#4B4B4B'
		},
		drawlerList : {
			borderTop : '1px solid #707070'
		},
		drawerBox : {
			display        : 'flex',
			flexDirection  : 'column',
			flexShrink     : 0,
			height         : '100%',
			justifyContent : 'space-between'

		},
		selectedItem : {
			backgroundColor : '#343433',
			color           : '#FFF',
			borderLeft      : '6px solid #cd2c82',
			paddingLeft     : '10px !important',
			'&:hover' :
			{
				backgroundColor : '#343433',
				color           : '#FFF',
				borderLeft      : '6px solid #cd2c82'
			}
		},
		notSelectedItem : {
			color : '#FFF'
		},
		drawerLogo : {
			width          : '100%',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'flex-start',
			alignItems     : 'center',
			paddingBottom  : '20px',
			paddingTop     : '25px'
		},
		drawerLogoImg : {
			width  : '65%',
			height : 'auto'
		},
		drawerLogoText : {
			color     : '#FFF',
			fontSize  : '0.8rem',
			marginTop : '-6px',
			width     : '95%',
			textAlign : 'center'
		},
		linkToCall : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'start',
			borderTop      : '1px solid #707070',
			padding        : '15px 0 0 10px',
			cursor         : 'pointer'
		},
		linkToCallText : {
			paddingTop : '3px',
			color      : '#FFF',
			marginLeft : '10px'
		},
		linkToCallIcon : {
			width  : '25px',
			height : 'auto'
		},
		appBar : {
			backgroundColor : '#cd2c82',
			transition      : theme.transitions.create([ 'margin', 'width' ], {
				easing   : theme.transitions.easing.sharp,
				duration : theme.transitions.duration.leavingScreen
			}),
			[theme.breakpoints.up('md')] : {
				display : 'none'
			}
		},
		appBarShift : {
			width      : `calc(100% - ${drawerWidth}px)`,
			marginLeft : drawerWidth,
			transition : theme.transitions.create([ 'margin', 'width' ], {
				easing   : theme.transitions.easing.easeOut,
				duration : theme.transitions.duration.enteringScreen
			}),
			[theme.breakpoints.up('md')] : {
				display : 'none'
			}
		},
		menuButton : {
			marginRight : theme.spacing(2)
		},
		hide : {
			display : 'none'
		},
		drawerHeader : {
			display                      : 'flex',
			alignItems                   : 'center',
			padding                      : theme.spacing(0, 1),
			justifyContent               : 'flex-end',
			[theme.breakpoints.up('md')] : {
				display : 'none'
			}
		},
		drawerText : {
			fontSize : '0.9rem'
		},
		drawerItemIcon : {
			color : '#FFF'
		},
		content : {
			flexGrow                     : 1,
			height                       : '100%',
			maxWidth                     : '100%',
			backgroundColor              : '#F5F5F5',
			padding                      : '10px',
			[theme.breakpoints.up('md')] : {
				width   : `calc( 100vw - ${drawerWidth}px)`,
				padding : '20px'
			}
		},
		contentScroller : {
			width           : '100%',
			height          : '100%',
			display         : 'flex',
			flexDirection   : 'column',
			justifyContent  : 'flex-start',
			alignItems      : 'center',
			overflowX       : 'auto',
			backgroundColor : '#FFF',
			borderRadius    : '5px',
			minWidth        : '320px',
			maxWidth        : '100%',
			position        : 'relative'
		},
		drawerBottomSection : {
			backgroundColor : '#FFF'
		},
		drawerBottomItem : {
			width          : '100%',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'space-between',
			alignItems     : 'center',
			marginBottom   : '10px'
		},
		drawerLogutItem : {
			backgroundColor : '#4B4B4B',
			borderBottom    : '1px solid #707070',
			paddingBottom   : '15px'
		},
		drawerInquiryItem : {
			backgroundColor : '#FFF'
		},
		drawerLogutBtn : {
			backgroundColor : '#FFF',
			padding         : '3px 15px',
			color           : '#292929',
			'&:hover' :
			{
				backgroundColor : '#DDD'
			}
		},
		drawerBtmDevider : {
			backgroundColor : 'rgba(0, 0, 0, 0.20)',
			margin          : '10px 0 5px 0',
			width           : '100%'
		},
		policyText : {
			textDecoration : 'none',
			color          : '#292929'
		},
		loading : {
			width           : '100vw',
			height          : '100vh',
			zIndex          : 9999999,
			position        : 'fixed',
			top             : 0,
			left            : 0,
			display         : 'flex',
			justifyContent  : 'center',
			alignItems      : 'center',
			backgroundColor : 'rgba(255, 255, 255, 1)'
		}
	});

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	}
});

const ServiceAdmin = ({
	classes
}) =>
{

	const intl = useIntl();

	const location = useLocation();
	const history = useHistory();

	const [ siteAdminLogin, setSiteAdminLogin ] = useState(false);

	const [ siteAdminLoginTarget, setSiteAdminLoginTarget ] = useState(null);

	const [ drawerOpen, setDrawerOpen ] = useState(false);

	const [ page, setPage ] = useState('myPage');

	const [ isLoading, setIsLoading ] = useState(true);

	const [ serviceAdminLogin ] = useAuthState(auth);
	const [ serviceAccountId, setServiceAccountId ] = useState(null);
	const [ serviceAccount, setServiceAccount ] = useState({});
	const [ priceLists, setPriceLists ] = useState({
		priceList                       : [],
		priceListTerm                   : [],
		priceListExpireAfterUse         : [],
		priceListCustomer               : [],
		priceListTermCustomer           : [],
		priceListExpireAfterUseCustomer : []
	});

	const [ loginDialogState, setLoginDialogState ] = useState({ show: null, page: 'login' });

	const [ purchasedTickets, setPurchasedTickets ] = useState([]);

	const [ soldTickets, setSoldTickets ] = useState([]);

	const [ mypageContent, setMypageContent ] = useState('home');

	const [ notifications, setNotifications ] = useState([]);

	const [ confirmDialogState, setConfirmDialogState ] = useState(
		{
			show            : false,
			title           : '',
			message         : '',
			acceptMethod    : () => {},
			acceptMethodArg : undefined,
			closeButtonOnly : true,
			success         : false
		}
	);

	const closeConfirmDialog = () =>
	{
		setConfirmDialogState(
			{
				show            : false,
				title           : '',
				message         : '',
				acceptMethod    : () => {},
				acceptMethodArg : undefined,
				closeButtonOnly : true
			}
		);
	};

	const fetchTickets = useCallback(() =>
	{
		setIsLoading(true);

		firestore.collection('serviceTickets')
			.where('account', '==', serviceAccountId)
			.get()
			.then((docs) =>
			{
				const purchasedList = [];
				const soldList = [];

				docs.forEach((doc) =>
				{
					const docData = doc.data();

					if (docData.purchasedBy === 'admin')
					{
						purchasedList.push({
							id       : doc.id,
							selected : false,
							...docData
						});
					}
					else
					{
						soldList.push({
							id       : doc.id,
							selected : false,
							...docData
						});
					}

				});
				purchasedList.sort((a, b) =>
				{
					if (a.salesAmount < b.salesAmount) { return -1; }
					if (a.salesAmount > b.salesAmount) { return 1; }

					return 0;
				});

				purchasedList.sort((a, b) =>
				{
					if (a.cratedAt < b.createdAt) { return -1; }
					if (a.createdAt > b.createdAt) { return 1; }

					return 0;
				});

				firestore.collection('serviceTickets')
					.where('brokerAccountId', '==', serviceAccountId)
					.get()
					.then((brokerDocs) =>
					{
						if (brokerDocs)
						{
							brokerDocs.forEach((doc) =>
							{
								const docData = doc.data();

								if (docData.purchasedBy !== 'admin')
								{
									soldList.push({
										id       : doc.id,
										selected : false,
										...docData
									});
								}
							});

							soldList.sort((a, b) =>
							{
								if (a.salesAmount < b.salesAmount) { return -1; }
								if (a.salesAmount > b.salesAmount) { return 1; }

								return 0;
							});

							soldList.sort((a, b) =>
							{
								if (a.cratedAt < b.createdAt) { return -1; }
								if (a.createdAt > b.createdAt) { return 1; }

								return 0;
							});
						}

						setPurchasedTickets(purchasedList);

						setSoldTickets(soldList);

						setIsLoading(false);

					});

			});
	}, [ serviceAccountId ]);

	const fetchNotifications = useCallback(() =>
	{
		firestore.collection('serviceNotifications')
			.get()
			.then((res) =>
			{
				const notificationsAr = [];

				res.docs.forEach((item) =>
				{

					notificationsAr.push({ id: item.id, ...item.data() });

				});

				notificationsAr.sort((a, b) =>
				{
					if (a.updatedAt < b.updatedAt) { return 1; }
					if (a.updatedAt > b.updatedAt) { return -1; }

					return 0;
				});

				setNotifications([ ...notificationsAr ]);
			})
			.catch((e) =>
			{
				// eslint-disable-next-line no-console
				console.log(`error e: ${e}`);
			});
	}, []);

	const selectPage = (p) =>
	{
		if (p === 'myPage')
		{
			setMypageContent('home');
		}

		// サイト管理者モードではpurchaseページを開かせない
		if (!siteAdminLogin || p !== 'purchase')
		{
			setPage(p);
			setDrawerOpen(false);
		}
	};

	useEffect(() =>
	{
		if (serviceAccountId)
		{
			fetchTickets();
			fetchNotifications();
		}

	}, [ serviceAccountId, fetchTickets, fetchNotifications ]);

	useEffect(() =>
	{
		// index 0 is ? get strings after ?
		const paramsValue = location.search.substring(1);

		let paramsObj = {};

		if (paramsValue)
		{
			paramsObj = parseUrlParams({
				paramsValues : paramsValue,
				keys         : [ 'adminLogin', 'loginAccountId' ]
			});
		}

		// サイト管理者が他アカウントの状態を見るモード
		if (paramsObj['adminLogin'] === 'true' && paramsObj['loginAccountId'])
		{
			setSiteAdminLogin(true);
			setSiteAdminLoginTarget(paramsObj['loginAccountId']);
			setLoginDialogState({ show: true, page: 'login' });
			setIsLoading(false);
		}
		else if (serviceAdminLogin && serviceAdminLogin.email.endsWith('serviceaccount.2nddoor.net'))
		{
			setSiteAdminLogin(false);
			setSiteAdminLoginTarget(null);
			setServiceAccountId(serviceAdminLogin.uid);
		}
		else
		{
			setSiteAdminLogin(false);
			setSiteAdminLoginTarget(null);
			setLoginDialogState({ show: true, page: 'login' });

			// ログインデータが残っていても一度serviceAdminLoginがnullになり、
			// そのままloadingが終わってログイン画面が見えてしまう
			// ログイン状態確認まで少し待つ
			setTimeout(() =>
			{
				setIsLoading(false);
			}, 1500);
		}
	}, [ serviceAdminLogin, location.search ]);

	useEffect(() =>
	{
		// serviceAccountデータ未取得の場合 => ログイン時に取得するので重複を防ぐ
		if (serviceAccountId && !serviceAccount.id)
		{
			firestore.collection('serviceAccounts').doc(serviceAccountId)
				.get()
				.then((response) =>
				{
					const accountData = response.data();

					if (accountData.passwordChanged)
					{
						setLoginDialogState({ show: false, page: 'login' });

						setServiceAccount({
							id : serviceAccountId,
							...accountData
						});

						setPriceLists({
							priceList : filterPriceListItem(
								accountData.priceList,
								[ MANAGE_PAGE_ONLY, PURCHASE_AND_MANAGE_PAGE ],
								'itemId'
							),
							priceListTerm : filterPriceListItem(
								accountData.priceListTerm,
								[ MANAGE_PAGE_ONLY, PURCHASE_AND_MANAGE_PAGE ],
								'itemId'
							),
							priceListExpireAfterUse : filterPriceListItem(
								accountData.priceListExpireAfterUse,
								[ MANAGE_PAGE_ONLY, PURCHASE_AND_MANAGE_PAGE ],
								'itemId'
							),
							priceListCustomer : filterPriceListItem(
								accountData.priceList,
								[ PURCHASE_PAGE_ONLY, PURCHASE_AND_MANAGE_PAGE ],
								'itemId'
							),
							priceListTermCustomer : filterPriceListItem(
								accountData.priceListTerm,
								[ PURCHASE_PAGE_ONLY, PURCHASE_AND_MANAGE_PAGE ],
								'itemId'
							),
							priceListExpireAfterUseCustomer : filterPriceListItem(
								accountData.priceListExpireAfterUse,
								[ PURCHASE_PAGE_ONLY, PURCHASE_AND_MANAGE_PAGE ],
								'itemId'
							)
						});

						setIsLoading(false);
					}
					else
					{
						setLoginDialogState({ show: true, page: 'passChange' });

						setIsLoading(false);
					}
				});
		}

	}, [ serviceAccountId, serviceAccount.id, loginDialogState.show ]);

	const beforeunloadHandler = useCallback((e) =>
	{

		e.preventDefault();

		e.returnValue = true;

	}, []);

	useEffect(() =>
	{
		if (!loginDialogState.show)
		{
			window.addEventListener('beforeunload', beforeunloadHandler);
		}

		return () => window.removeEventListener('beforeunload', beforeunloadHandler);
	}, [ beforeunloadHandler, loginDialogState.show ]);

	const logout = () =>
	{
		setLoginDialogState({ show: true, page: 'login' });
		setServiceAccountId('');
		setServiceAccount({});
		auth.signOut();
	};

	const moveToCallPage = () =>
	{
		history.push({
			pathname : '/service',
			search   : `?id=${serviceAccount.paramKey}`
		});
	};

	let drawerItems = [ 'myPage', 'accountInfo' ];

	if (serviceAccount.selfTicketPurchase)
	{
		drawerItems = [ 'myPage', 'purchase', 'accountInfo', 'priceList' ];
	}

	const drawer = (
		<Box className={classes.drawerBox}>
			<Box>
				<div className={classes.drawerHeader}>
					<IconButton onClick={() => setDrawerOpen(false)}>
						<ChevronLeftIcon />
					</IconButton>
				</div>

				<Box className={classes.drawerLogo}>
					<img alt={'logo'} src={TranslateLogo} className={classes.drawerLogoImg}/>
					<Typography className={classes.drawerLogoText}>
						{serviceAccount.accountName}
					</Typography>
				</Box>

				<List className={classes.drawlerList}>
					{ drawerItems.map((text) => (
						<ListItem
							button
							key={text}
							onClick={() => selectPage(text)}
							className={page === text ? classes.selectedItem : classes.notSelectedItem}
						>
							<ListItemIcon >
								{text === 'myPage' ?
									<HomeIcon
										className={classes.drawerItemIcon}
									/>
									: text === 'purchase' ?
										<ShopIcon
											className={classes.drawerItemIcon}
										/>
										: text === 'accountInfo' ?
											<PersonIcon
												className={classes.drawerItemIcon}
											/>
											:
											<ListAltIcon
												className={classes.drawerItemIcon}
											/>
								}
							</ListItemIcon>
							<ListItemText
								className={classes.drawerText}
								primary={
									intl.formatMessage({
										id             : `service.${text}`,
										defaultMessage : text
									})
								}
							/>
						</ListItem>
					))}
				</List>

				<Box className={classes.linkToCall} onClick={moveToCallPage}>
					<img alt={'call page link'} src={LinkIcon} className={classes.linkToCallIcon}/>
					<Typography className={classes.linkToCallText}>LIVE通訳利用ページ</Typography>
				</Box>
			</Box>

			<Box className={classes.drawerBottomSection}>
				<Box className={`${classes.drawerBottomItem} ${classes.drawerLogutItem}`}>
					<Button
						className={classes.drawerLogutBtn}
						onClick={logout}
					>
						ログアウト
					</Button>
				</Box>
				<Box className={`${classes.drawerBottomItem} ${classes.drawerInquirySection}`}>
					<Button>
						<a
							className={classes.policyText}
							href={'https://www.tuuyaku.com/corporate-inquiries'}
							target='_blank' rel='noreferrer'
						>
							お問い合わせ
						</a>
					</Button>
				</Box>
			</Box>
		</Box>
	);

	const container = window !== undefined ? () => window.document.body : undefined;

	return (
		<MuiThemeProvider theme={theme}>
			{
				isLoading &&
					<Box className={classes.loading}>
						<CircularProgress />
					</Box>
			}

			{loginDialogState.show === null ? null
				: loginDialogState.show ?
					<ServiceAdminLoginPage
						loginDialogState={loginDialogState}
						serviceAccountId={serviceAdminLogin?.uid}
						setLoginDialogState={setLoginDialogState}
						setServiceAccountId={setServiceAccountId}
						setServiceAccount={setServiceAccount}
						siteAdminLogin={siteAdminLogin}
						siteAdminLoginTarget={siteAdminLoginTarget}
					/>
					:
					<>
						<ConfirmDialog
							show={confirmDialogState.show}
							title={confirmDialogState.title}
							message={confirmDialogState.message}
							accept={confirmDialogState.acceptMethod}
							acceptArg={confirmDialogState.acceptMethodArg}
							cancel={closeConfirmDialog}
							closeButtonOnly={confirmDialogState.closeButtonOnly}
						/>

						<Box className={classes.root}>
							<AppBar
								position='fixed'
								className={drawerOpen ? `${classes.appBar} ${classes.appBarShift}`
									: classes.appBar}
							>
								<Toolbar>
									<IconButton
										color='inherit'
										aria-label='open drawer'
										onClick={() => setDrawerOpen(true)}
										edge='start'

										className={drawerOpen ? `${classes.menuButton} ${classes.hide}`
											: classes.menuButton}
									>
										<MenuIcon />
									</IconButton>
								</Toolbar>
							</AppBar>
							<Box className={classes.wrapper}>
								<nav className={classes.drawer} aria-label='mailbox folders'>
									<Hidden mdUp implementation='css'>
										<Drawer
											container={container}
											variant='temporary'
											anchor={'left'}
											open={drawerOpen}
											classes={{
												paper : classes.drawerPaper
											}}
											ModalProps={{
												keepMounted : true // Better open performance on mobile.
											}}
										>
											{drawer}
										</Drawer>
									</Hidden>
									<Hidden smDown implementation='css'>
										<Drawer
											classes={{
												paper : classes.drawerPaper
											}}
											variant='permanent'
											open
										>
											{drawer}
										</Drawer>
									</Hidden>
								</nav>
								<Box
									className={classes.content}
								>
									{ page === 'priceList' && serviceAccount.selfTicketPurchase ?
										<Box
											className={classes.contentScroller}
										>
											<PriceList
												serviceAccount={serviceAccount}
												priceLists={priceLists}
												drawerWidth={drawerWidth}
											/>
										</Box>
										: page === 'purchase' && serviceAccount.selfTicketPurchase ?
											<Box
												className={classes.contentScroller}
											>
												<PurchaseTicket
													serviceAccount={serviceAccount}
													priceLists={priceLists}
													page={page}
													fetchTickets={fetchTickets}
													drawerWidthProp={drawerWidth}
													siteAdminLogin={siteAdminLogin}
												/>
											</Box>
											: page === 'accountInfo' ?
												<Box
													className={classes.contentScroller}
												>
													<ServiceAccount
														serviceAccount={serviceAccount}
													/>
												</Box>
												:
												<MyPage
													purchasedTickets={purchasedTickets}
													setPurchasedTickets={setPurchasedTickets}
													soldTickets={soldTickets}
													setSoldTickets={setSoldTickets}
													mypageContent={mypageContent}
													setMypageContent={setMypageContent}
													serviceAccountId={serviceAccountId}
													notifications={notifications}
													selfTicketPurchase={serviceAccount.selfTicketPurchase}
												/>
									}
								</Box>
							</Box>
						</Box>
					</>
			}
		</MuiThemeProvider>
	);

};

ServiceAdmin.propTypes =
{
	classes : PropTypes.object.isRequired
};

const mapStateToProps = () =>
{
	return {
	};
};

const mapDispatchToProps = () =>
{
	return {
	};
};

export default withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{}
)(withStyles(styles)(ServiceAdmin)));