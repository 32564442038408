import React, { useRef, useState } from 'react';
import { useYearMonthEffect, useMonthDayEffect } from './operatorCalculator';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import DatePickerMonthDay from './DatePickerMonthDay';
import DatePickerYearMonth from './DatePickerYearMonth';

import prepareData from './prepareData';
import { exportToCSV } from '../../utils/exportCSV';
import { StyledTableCell, StyledTableRow, useStyles } from './style';

export default function Operator({ calls, roomsSnapshots })
{
	const tableRef = useRef(null);
	const classes = useStyles();
	const [ dataOverallYearMonth, setDataOverallYearMonth ] = useState({
		numberOfIncomingCalls : 0,
		numberOfCallsReceived : 0,
		callReceivedRate      : 0,
		upTime                : 0,
		totalTalkTime         : 0,
		averageTalkTime       : 0
	});

	const [ dataOverallMonthDay, setDataOverallMonthDay ] = useState({
		numberOfIncomingCalls : 0,
		numberOfCallsReceived : 0,
		callReceivedRate      : 0,
		upTime                : 0,
		totalTalkTime         : 0,
		averageTalkTime       : 0
	});

	const [ dateYearMonth, setDateYearMonth ] = useState(new Date());
	const [ dateMonthDay, setDateMonthDay ] = useState(new Date());
	const [ host, setHost ] = useState({ id: '', loginId: '' });
	const [ hostRecievedCalls, setHostRecievedCalls ] = useState([]);

	const rows = prepareData(dataOverallYearMonth, dataOverallMonthDay, hostRecievedCalls);

	const uniqueHostIds = Array.from(
		new Map(
			calls
				.map((call) => ({ id: call.hostId, loginId: call.hostLoginId }))
				.map((item) => [ `${item.id }_${ item.loginId}`, item ])
		).values()
	).filter((item) => item.id); // Ensure no undefined/null ids

	const selectHost = (loginId) =>
	{
		const target = uniqueHostIds.find((h) => h.loginId === loginId);

		if (target)
		{
			setHost(target);
		}
	};

	const handleSearch = () =>
	{
		if (host?.loginId)
		{
			const filteredCalls = calls.filter((item) => item.hostLoginId === host.loginId);

			setHostRecievedCalls(filteredCalls);
		}
	};

	useYearMonthEffect(
		calls, hostRecievedCalls, dateYearMonth, setDataOverallYearMonth, roomsSnapshots, host
	);
	useMonthDayEffect(
		calls, hostRecievedCalls, dateMonthDay, setDataOverallMonthDay, roomsSnapshots, host
	);

	return (
		<TableContainer component={Paper} className={classes.operatorContainer}>
			<div className={classes.hostOperatorContainerHeader}>
				<div className={classes.hostPerformanceTextHeader}>オペレータパフォーマンス</div>
				<button
					className={classes.hostButtonCSV}
					onClick={() => exportToCSV(rows, 'FileCSV.csv', dateYearMonth, dateMonthDay)}
				>
					CSV 出力
				</button>
			</div>

			<div className={classes.hostContainerHeaderOperator}>
				<div className={classes.hostPerformanceText}>オペレータ ID</div>
				<div className={classes.hostInputOperator}>
					<FormControl variant='outlined' className={classes.hostTextInputOperator}>
						<Select
							native
							className={classes.option}
							value={host?.loginId}
							onChange={(e) => selectHost(e.target.value)}
							inputProps={{
								name : 'Host login ID',
								id   : 'outlined-age-native-simple'
							}}
						>
							<option key={'default'} value={''} disabled>
								オペレータ ID
							</option>
							{uniqueHostIds.map((h) => (
								<option
									key={h.id}
									className={classes.optionInput}
									value={h.loginId}
								>
									{h.loginId}
								</option>
							))}
						</Select>
					</FormControl>
				</div>
				<div>
					<button className={classes.hostButtonSetting} onClick={handleSearch}>
						設定
					</button>
				</div>
			</div>
			<Table className={classes.tableOperator} aria-label='customized table' ref={tableRef}>
				<TableHead className={classes.hostCustomDatePicker}>
					<TableRow className={classes.hostTableRow}>
						<StyledTableCell className={classes.cellHead}>項目</StyledTableCell>
						<StyledTableCell className={classes.cellHead} align='right'>
							<div>
								<DatePickerYearMonth
									dateYearMonth={dateYearMonth}
									setDateYearMonth={setDateYearMonth}
								/>
							</div>
						</StyledTableCell>
						<StyledTableCell align='center'>
							<div>
								<DatePickerMonthDay
									dateMonthDay={dateMonthDay}
									setDateMonthDay={setDateMonthDay}
								/>
							</div>
						</StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody className={classes.hostGlobalText}>
					{rows.map((group) =>
						group.map((data, dataIndex) =>
						{
							const isLastRow = dataIndex === group.length - 1;

							return (
								<StyledTableRow key={data.name} className={isLastRow ? classes.lastRow : ''}>
									<StyledTableCell
										component='th'
										scope='row'
										className={isLastRow ? classes.categoryCell : ''}
									>
										{data.name}
									</StyledTableCell>
									<StyledTableCell align='right'>{data.metric1}</StyledTableCell>
									<StyledTableCell align='right'>{data.metric2}</StyledTableCell>
								</StyledTableRow>
							);
						})
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
