import {
	CALL_STATUS_CATCH,
	CALL_STATUS_FINISHED,
	CALL_STATUS_NO_ONE_AVAILABLE,
	CALL_STATUS_NO_REPLY,
	CALL_STATUS_STOP,
	ROOM_ONLINE,
	WAITING_GUESTS_STATUS_CONNECTED,
	WAITING_GUESTS_STATUS_DISCONNECT,
	YEAR_MONTH
} from '../../../../../../const';
import { roundToDecimalPlaces } from '../../utils//roundDecimal';

import { calcSecondsFromTimestampForDay, calcSecondsFromTimestampForMonth } from '../../utils/SecondsFromTime';

/**
 * @param {Function} funcGetDate
 * @param {Date} dateTime
 * @returns {number}
 */

export const countNumberOfIncomingCalls = (funcGetDate, dateTime, calls) =>
{
	if (!calls)
	{
		return [];
	}

	const numberOfIncomingCalls = calls.reduce((accumulator, item) =>
	{
		const date = funcGetDate(item.timestamp);

		if (funcGetDate(dateTime) === date)
		{
			accumulator++;
		}

		return accumulator;

	}, 0);

	return numberOfIncomingCalls;

};

/**
 * @param {Function} funcGetDate
 * @param {Date} dateTime
 * @returns {number}
 */
export const countNumberOfCallsReceived = (funcGetDate, dateTime, calls) =>
{
	if (!calls)
	{
		return 0;
	}

	const numberOfCallsReceived = calls.reduce((accumulator, item) =>
	{
		const date = funcGetDate(item.timestamp);

		if (funcGetDate(dateTime) === date)
		{
			if (item.status === CALL_STATUS_CATCH || item.status === CALL_STATUS_FINISHED)
			{
				accumulator++;
			}
		}

		return accumulator;
	}, 0);

	return numberOfCallsReceived;
};

/**
 * @param {Function} funcGetDate
 * @param {Date} dateTime
 * @returns {number} - seconds
 */
export const countTimeWaiting = (funcGetDate, dateTime, roomsSnapshots) =>
{
	if (!roomsSnapshots)
	{
		return 0;
	}

	const totalTimeWaiting = roomsSnapshots.reduce((accumulator, item) =>
	{
		if (item.rooms && item.rooms.length > 0)
		{
			const date = funcGetDate(item.timestamp);

			if (funcGetDate(dateTime) === date)
			{
			// const timeWaiting
			// 	= item.newValue.timestamp.seconds - item.prevValue.timestamp.seconds;

				// accumulator += timeWaiting;

				let roomFound = false;

				for (const rm of item.rooms)
				{
					if (rm.status === ROOM_ONLINE)
					{
						roomFound = true;
						break;
					}
				}

				if (roomFound)
				{
				// 基本5分(300秒)ごと
					accumulator += item.intervalMinute ? item.intervalMinute * 60 : 300;
				}
			}
		}

		return accumulator;
	}, 0);

	return totalTimeWaiting;
};

/**
 * @param {Function} funcGetDate
 * @param {Date} dateTime
 * @returns {number} - seconds
 */
export const getTotalTalkTime = (funcGetDate, dateTime, calls) =>
{
	if (!calls)
	{
		return 0;
	}

	const totalTalkTime = calls.reduce((accumulator, item) =>
	{
		const date = funcGetDate(item.timestamp);

		if (funcGetDate(dateTime) === date)
		{
			accumulator = accumulator + item.duration;
		}

		return accumulator;
	}, 0);

	return totalTalkTime;
};

/**
 * @param {Function} funcGetDate
 * @param {Date} dateTime
 * @returns {number}
 */
export const countNumberOfMissedCalls = (funcGetDate, dateTime, calls) =>
{
	if (!calls)
	{
		return [];
	}

	const numberOfMissedCalls = calls.reduce((accumulator, item) =>
	{
		const date = funcGetDate(item.timestamp);

		if (funcGetDate(dateTime) === date)
		{
			if (
				item.status === CALL_STATUS_NO_REPLY
					|| item.status === CALL_STATUS_NO_ONE_AVAILABLE
			)
			{
				accumulator++;
			}
		}

		return accumulator;
	}, 0);

	return numberOfMissedCalls;
};

/**
 * @param {Function} funcGetDate
 * @param {Date} dateTime
 * @returns {number}
 */
export const countNumberOfBreaks = (funcGetDate, dateTime, calls) =>
{
	if (!calls)
	{
		return [];
	}
	const numberOfBreaks = calls.reduce((accumulator, item) =>
	{
		const date = funcGetDate(item.timestamp);

		if (funcGetDate(dateTime) === date)
		{
			if (
				item.status === CALL_STATUS_STOP
			)
			{
				accumulator++;
			}
		}

		return accumulator;
	}, 0);

	return numberOfBreaks;
};

/**
 * @param {Function} funcGetDate
 * @param {Date} dateTime
 * @returns {number} - milliseconds
 */

const funcCountNumberOfCallsWaiting = (funcGetDate, dateTime, queueResults) =>
{
	if (!queueResults)
	{
		return [];
	}

	const callsWaitingNumber =
			queueResults.reduce((accumulator, item) =>
			{
				const date = funcGetDate(item.start);

				if (funcGetDate(dateTime) === date)
				{
					if (item.status === WAITING_GUESTS_STATUS_CONNECTED
						|| item.end - item.start >= 10000) accumulator++;
				}

				return accumulator;
			}, 0);

	return callsWaitingNumber;
};

/**
 * @param {Function} funcGetDate
 * @param {Date} dateTime
 * @returns {number} - milliseconds
 */
export const getCallWaitingAverageWaitingTime = (funcGetDate, dateTime, queueResults) =>
{
	if (!queueResults)
	{
		return [];
	}

	let numberWaitingGuests = 0;
	const callWaitingTotalWaitingTime = queueResults.reduce((accumulator, item) =>
	{
		const date = funcGetDate(item.start);

		if (funcGetDate(dateTime) === date)
		{
			numberWaitingGuests++;
			const timeWait = item.end - item.start;

			accumulator += timeWait;
		}

		return accumulator;
	}, 0);

	return callWaitingTotalWaitingTime / numberWaitingGuests;
};

/**
 * @param {Function} funcGetDate
 * @param {Date} dateTime
 * @returns {number}
 */
export const countCallWaitingNumberOfSuccessfulConnections
	= (funcGetDate, dateTime, queueResults) =>
	{

		if (!queueResults)
		{
			return [];
		}

		const callWaitingNumberOfSuccessfulConnections =
			queueResults.reduce((accumulator, item) =>
			{
				const date = funcGetDate(item.start);

				if (funcGetDate(dateTime) === date)
				{
					if (item.status === WAITING_GUESTS_STATUS_CONNECTED) accumulator++;
				}

				return accumulator;
			}, 0);

		return callWaitingNumberOfSuccessfulConnections;
	};

/**
 * @param {Function} funcGetDate
 * @param {Date} dateTime
 * @returns {number}
 */
export const countCallWaitingNumberOfExits = (funcGetDate, dateTime, queueResults) =>
{
	if (!queueResults)
	{
		return [];
	}

	const callWaitingNumberOfExits = queueResults.reduce((accumulator, item) =>
	{
		const date = funcGetDate(item.start);

		if (funcGetDate(dateTime) === date)
		{
			if (item.status === WAITING_GUESTS_STATUS_DISCONNECT) accumulator++;
		}

		return accumulator;
	}, 0);

	return callWaitingNumberOfExits;
};

/**
 * @param {Function} funcGetDate
 * @param {Date} dateTime
 * @param {number} checkDate
 * @returns {object} - dataOverallYearMonth or dataOverallMonthDay
 */
export const getDataOverall = (
	subdomains,
	funcGetDate,
	dateTime,
	checkDate,
	calls,
	roomsSnapshots,
	queueResults
) =>
{
	const numberOfIncomingCalls = countNumberOfIncomingCalls(funcGetDate, dateTime, calls);

	const numberOfCallsReceived = countNumberOfCallsReceived(funcGetDate, dateTime, calls);

	const callReceivedRate = numberOfIncomingCalls ?
		(numberOfCallsReceived / numberOfIncomingCalls) * 100
		: 0;

	const timeWaiting = countTimeWaiting(
		funcGetDate, dateTime, roomsSnapshots); // seconds

	const totalTalkTime = getTotalTalkTime(funcGetDate, dateTime, calls); // seconds

	const upTime = totalTalkTime + timeWaiting; // seconds

	let numberOfActiveSeatsAverage = 0;

	// 月ごと
	if (checkDate === YEAR_MONTH)
	{
		const workingSeconds = calcSecondsFromTimestampForMonth(funcGetDate(dateTime))
								* subdomains.length;

		numberOfActiveSeatsAverage = upTime / workingSeconds;
	}
	// 日ごと
	else
	{
		const workingSeconds = calcSecondsFromTimestampForDay(funcGetDate(dateTime));

		numberOfActiveSeatsAverage = upTime / workingSeconds;
	}

	const averageTalkTime = totalTalkTime / numberOfCallsReceived; // seconds

	const numberOfMissedCalls = countNumberOfMissedCalls(funcGetDate, dateTime, calls);

	const numberOfBreaks = countNumberOfBreaks(funcGetDate, dateTime, calls);

	const numberOfCallsWaiting
		= funcCountNumberOfCallsWaiting(funcGetDate, dateTime, queueResults);

	const callWaitingAverageWaitingTime =
		getCallWaitingAverageWaitingTime(funcGetDate, dateTime, queueResults); // milliseconds

	const callWaitingNumberOfSuccessfulConnections =
		countCallWaitingNumberOfSuccessfulConnections(
			funcGetDate,
			dateTime,
			queueResults
		);

	const callWaitingNumberOfExits = countCallWaitingNumberOfExits(
		funcGetDate,
		dateTime,
		queueResults
	);

	const callWaitingRate =
    (numberOfCallsWaiting
		/ (numberOfIncomingCalls - numberOfMissedCalls - numberOfBreaks)
    ) * 100;

	return {
		numberOfIncomingCalls,
		numberOfCallsReceived,
		callReceivedRate              : roundToDecimalPlaces(callReceivedRate, 2),
		numberOfActiveSeats           : roundToDecimalPlaces(numberOfActiveSeatsAverage, 3),
		upTime                        : roundToDecimalPlaces(upTime / 60, 2),
		totalTalkTime                 : roundToDecimalPlaces(totalTalkTime / 60, 2),
		averageTalkTime               : roundToDecimalPlaces(averageTalkTime / 60, 2),
		numberOfMissedCalls,
		numberOfBreaks,
		numberOfCallsWaiting,
		callWaitingRate               : roundToDecimalPlaces(callWaitingRate, 2),
		callWaitingAverageWaitingTime : roundToDecimalPlaces(
			callWaitingAverageWaitingTime / (60 * 1000),
			2
		),
		callWaitingNumberOfSuccessfulConnections,
		callWaitingNumberOfExits
	};
};

/**
 * @param {Date} dataOverallYearMonth
 * @param {Date} dataOverallMonthDay
 * @returns {Array}
 */
export const createRows = (dataOverallYearMonth, dataOverallMonthDay) =>
{
	const rows = [
		[
			{
				name    : '入電数',
				metric1 : `${dataOverallYearMonth.numberOfIncomingCalls} 件`,
				metric2 : `${dataOverallMonthDay.numberOfIncomingCalls} 件`
			},
			{
				name    : '受電数',
				metric1 : `${dataOverallYearMonth.numberOfCallsReceived} 件`,
				metric2 : `${dataOverallMonthDay.numberOfCallsReceived} 件`
			},
			{
				name    : '受電率',
				metric1 : `${dataOverallYearMonth.callReceivedRate}%`,
				metric2 : `${dataOverallMonthDay.callReceivedRate}%`
			}
		],
		[
			{
				name    : '稼働席数',
				metric1 : `${dataOverallYearMonth.numberOfActiveSeats} 席`,
				metric2 : `${dataOverallMonthDay.numberOfActiveSeats} 席`
			},
			{
				name    : '稼働時間',
				metric1 : `${dataOverallYearMonth.upTime} 分`,
				metric2 : `${dataOverallMonthDay.upTime} 分`
			},
			{
				name    : '合計通話時間',
				metric1 : `${dataOverallYearMonth.totalTalkTime} 分`,
				metric2 : `${dataOverallMonthDay.totalTalkTime} 分`
			},
			{
				name    : '平均通話時間',
				metric1 : `${dataOverallYearMonth.averageTalkTime} 分`,
				metric2 : `${dataOverallMonthDay.averageTalkTime} 分`
			}
		],
		[
			{
				name    : '不在数',
				metric1 : `${dataOverallYearMonth.numberOfMissedCalls} 件`,
				metric2 : `${dataOverallMonthDay.numberOfMissedCalls} 件`
			},
			{
				name    : '切断数',
				metric1 : `${dataOverallYearMonth.numberOfBreaks} 件`,
				metric2 : `${dataOverallMonthDay.numberOfBreaks} 件`
			}
		],
		[
			{
				name    : '待ち呼数',
				metric1 : `${dataOverallYearMonth.numberOfCallsWaiting} 件`,
				metric2 : `${dataOverallMonthDay.numberOfCallsWaiting} 件`
			},
			{
				name    : '待ち呼率',
				metric1 : `${dataOverallYearMonth.callWaitingRate}%`,
				metric2 : `${dataOverallMonthDay.callWaitingRate}%`
			},
			{
				name    : '待ち呼　平均待機時間',
				metric1 : `${dataOverallYearMonth.callWaitingAverageWaitingTime} 分`,
				metric2 : `${dataOverallMonthDay.callWaitingAverageWaitingTime} 分`
			},
			{
				name    : '待ち呼　接続成功数',
				metric1 : `${dataOverallYearMonth.callWaitingNumberOfSuccessfulConnections} 件`,
				metric2 : `${dataOverallMonthDay.callWaitingNumberOfSuccessfulConnections} 件`
			},
			{
				name    : '待ち呼　離脱数',
				metric1 : `${dataOverallYearMonth.callWaitingNumberOfExits} 件`,
				metric2 : `${dataOverallMonthDay.callWaitingNumberOfExits} 件`
			}
		]
	];

	return rows;
};
