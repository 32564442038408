import { useEffect } from 'react';
import { getYearMonthDay, getYearMonth } from '../../utils/formatDate';
import { CALL_STATUS_CATCH, CALL_STATUS_FINISHED, ROOM_ONLINE } from '../../../../../../const';

// filter data operator by Year month
export function useYearMonthEffect(
	calls,
	hostRecievedCalls,
	dateYearMonth,
	setDataOverallYearMonth,
	roomsSnapshots,
	host
)
{
	useEffect(() =>
	{
		// number call coming
		const numberOfIncomingCalls = calls.reduce((accumulator, item) =>
		{
			const date = getYearMonth(item.timestamp);

			if (getYearMonth(dateYearMonth) === date)
			{
				if (item.calls)
				{
					for (const c of item.calls)
					{
						if (c.hostId === host.id)
						{
							accumulator++;

							break;
						}
					}
				}
			}

			return accumulator;
		}, 0);

		// number call received
		const numberOfCallsReceived = hostRecievedCalls.reduce((accumulator, item) =>
		{
			const date = getYearMonth(item.timestamp);

			if (getYearMonth(dateYearMonth) === date)
			{
				if (item.status === CALL_STATUS_CATCH || item.status === CALL_STATUS_FINISHED)
				{
					accumulator++;
				}
			}

			return accumulator;
		}, 0);

		// total talk time
		const totalTalkSeconds = hostRecievedCalls.reduce((accumulator, item) =>
		{
			const date = getYearMonth(item.timestamp);

			if (getYearMonth(dateYearMonth) === date)
			{
				accumulator += item.duration;
			}

			return accumulator;
		}, 0);

		const totalTalkTime = Math.ceil((totalTalkSeconds/60)*10)/10;

		// average talk time
		const averageTalkSeconds = numberOfCallsReceived > 0
			? Math.ceil((totalTalkSeconds / numberOfCallsReceived) * 10) / 10 : 0;

		const averageTalkTime = Math.ceil((averageTalkSeconds/60)*10)/10;

		// call received rate
		let callReceivedRate = 0;

		if (numberOfIncomingCalls > 0)
		{
			const resultRate = (numberOfCallsReceived / numberOfIncomingCalls) * 100;
			const roundedResultRate = resultRate.toFixed(2);

			if (roundedResultRate.indexOf('.') !== -1 && parseFloat(roundedResultRate) % 1 === 0)
			{
				callReceivedRate = parseInt(roundedResultRate, 10);
			}
			else
			{
				callReceivedRate = parseFloat(roundedResultRate);
			}
		}

		const filteredRoomSnapshots = roomsSnapshots.filter((item) =>
		{
			let activeOperatorRoomFound = false;

			if (getYearMonth(dateYearMonth) === getYearMonth(item.timestamp) && item.rooms)
			{
				for (const rm of item.rooms)
				{
					if (rm.status === ROOM_ONLINE && rm.hostId === host.id)
					{
						activeOperatorRoomFound = true;
						break;
					}
				}
			}

			return activeOperatorRoomFound;
		});

		const timeWaiting = filteredRoomSnapshots.reduce((total, item) =>
		{

			total += item.intervalMinute ? item.intervalMinute : 5;

			return total;

		}, 0);

		// total operating time
		const upTime = Math.ceil((totalTalkTime + timeWaiting) * 10) / 10;

		setDataOverallYearMonth((prev) => ({
			...prev,
			numberOfIncomingCalls,
			numberOfCallsReceived,
			callReceivedRate,
			totalTalkTime,
			averageTalkTime,
			upTime
		}));
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		calls,
		hostRecievedCalls,
		dateYearMonth,
		setDataOverallYearMonth,
		roomsSnapshots
	]);
}

// filter data operator by day month
export function useMonthDayEffect(
	calls,
	hostRecievedCalls,
	dateMonthDay,
	setDataOverallMonthDay,
	roomsSnapshots,
	host
)
{
	useEffect(() =>
	{
		// number call coming
		const numberOfIncomingCalls = calls.reduce((accumulator, item) =>
		{
			const date = getYearMonthDay(item.timestamp);

			if (getYearMonthDay(dateMonthDay) === date)
			{
				// accumulator = accumulator + item.calls.length;

				let calledTimes = 0;

				if (item.calls)
				{
					item.calls.forEach((c) =>
					{
						if (c.hostId === host.id) calledTimes++;
					});
				}

				accumulator = accumulator + calledTimes;
			}

			return accumulator;
		}, 0);

		// number call received
		const numberOfCallsReceived = hostRecievedCalls.reduce((accumulator, item) =>
		{
			const date = getYearMonthDay(item.timestamp);

			if (getYearMonthDay(dateMonthDay) === date)
			{
				if (item.status === CALL_STATUS_CATCH || item.status === CALL_STATUS_FINISHED)
				{
					accumulator++;
				}
			}

			return accumulator;
		}, 0);

		// call received date
		let callReceivedRate = 0;

		if (numberOfIncomingCalls > 0)
		{
			const resultRate = (numberOfCallsReceived / numberOfIncomingCalls) * 100;
			const roundedResultRate = resultRate.toFixed(2);

			if (roundedResultRate.indexOf('.') !== -1 && parseFloat(roundedResultRate) % 1 === 0)
			{
				callReceivedRate = parseInt(roundedResultRate, 10);
			}
			else
			{
				callReceivedRate = parseFloat(roundedResultRate);
			}
		}

		// total talk time
		const totalTalkSeconds = hostRecievedCalls.reduce((accumulator, item) =>
		{
			const date = getYearMonthDay(item.timestamp);

			if (getYearMonthDay(dateMonthDay) === date)
			{
				accumulator = accumulator + item.duration;
			}

			return accumulator;
		}, 0);

		const totalTalkTime = Math.ceil((totalTalkSeconds/60)*10)/10;

		// average talk time
		const averageTalkSeconds = numberOfCallsReceived > 0
			? Math.ceil((totalTalkSeconds / numberOfCallsReceived) * 10) / 10 : 0;

		const averageTalkTime = Math.ceil((averageTalkSeconds/60)*10)/10;

		const filteredRoomSnapshots = roomsSnapshots.filter((item) =>
		{
			let activeOperatorRoomFound = false;

			if (getYearMonthDay(dateMonthDay) === getYearMonthDay(item.timestamp) && item.rooms)
			{
				for (const rm of item.rooms)
				{
					if (rm.status === ROOM_ONLINE && rm.hostId === host.id)
					{
						activeOperatorRoomFound = true;
						break;
					}
				}
			}

			return activeOperatorRoomFound;
		});

		const timeWaiting = filteredRoomSnapshots.reduce((total, item) =>
		{

			total += item.intervalMinute ? item.intervalMinute : 5;

			return total;

		}, 0);

		// total operating time
		const upTime = Math.ceil((totalTalkTime + timeWaiting) * 10) / 10;

		setDataOverallMonthDay((prev) => ({
			...prev,
			numberOfIncomingCalls,
			numberOfCallsReceived,
			callReceivedRate,
			totalTalkTime,
			averageTalkTime,
			upTime
		}));
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		calls,
		hostRecievedCalls,
		dateMonthDay,
		setDataOverallMonthDay,
		roomsSnapshots
	]);
}
