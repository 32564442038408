// React
import React, { useEffect, useState, useCallback } from 'react';

// Redux
import { connect } from 'react-redux';
import { withRoomContext } from '../../../RoomContext';
import * as roomActions from '../../../actions/roomActions';

// Router
import { useHistory, Link } from 'react-router-dom';

// Prop types
import PropTypes from 'prop-types';

// Message
import { FormattedMessage, useIntl } from 'react-intl';

// Mui styles
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';

// Mui core
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

// Icon
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

// 
import AccessRequestImage from '../../../images/request_access.svg';

// Firebase
import { firestore } from '../../../lib/firebase';

// url
import { directJoinUrl } from '../../../urlFactory';

// util
import { getMilliSecondsNow } from '../../../utils';

const styles = (theme) =>
	({
		root : {
			zIndex : 99,
			width  : '100%',
			height : '100%'
		},
		dialogPaper : {
			padding                        : '1% 2%',
			width                          : '40vw',
			[theme.breakpoints.down('lg')] : {
				width : '40vw'
			},
			[theme.breakpoints.down('md')] : {
				width : '50vw'
			},
			[theme.breakpoints.down('sm')] : {
				width : '70vw'
			},
			[theme.breakpoints.down('xs')] : {
				width : '90vw'
			}
		},
		titleIcon : {
			width        : '3rem',
			height       : '3rem',
			marginBottom : '6%'
		},
		titleText : {
			color      : 'var(--text-color)',
			fontSize   : '0.8rem',
			fontWeight : 'bold',
			whiteSpace : 'break-spaces',
			textAlign  : 'center'
		},
		content : {
			color      : 'var(--text-color)',
			fontSize   : '0.8rem',
			whiteSpace : 'break-spaces',
			margin     : '2.5rem 0'
		},
		contentText : {
			marginBottom : '1.2rem'
		},
		permission : {
			border       : '1px solid var(--text-color)',
			borderRadius : '0.4rem',
			color        : '#0071bc',
			padding      : '0.1rem 0.8rem'
		},
		confirmBtn : {
			borderRadius    : '0.6rem',
			boxShadow       : '2px 2px 4px #aaa',
			backgroundColor : '#878686',
			color           : 'white',
			'&:hover'       : {
				backgroundColor : '#878686'
			}
		},
		contentSection : {
			width         : '100%',
			display       : 'flex',
			flexDirection : 'column',
			alignItems    : 'center'
		},
		requestAccessImageWrapper :
		{
			margin : '20px 0',
			width  : '100%'
		},
		requestAccessImage :
		{
			width    : '100%',
			maxWidth : '777px'
		},
		separateLineWrapper :
		{
			width        : '100%',
			marginBottom : '15px'
		},
		separateLine :
		{
			border    : 'none',
			borderTop : '1px dashed var(--text-color)'
		},
		termsText :
		{
			color        : 'var(--text-color)',
			fontSize     : '0.9rem',
			marginBottom : '15px'
		},
		termsLink :
		{
			color : 'var(--text-color)'
		}
	});

const theme = createTheme({
	overrides : {
		MuiDialogTitle : {
			root : {
				backgroundColor : 'transparent',
				color           : 'var(--text-color)',
				width           : '100%'
			}
		},
		MuiDialogContent : {
			root : {
				justifyContent : 'center',
				display        : 'flex',
				flexDirection  : 'column',
				alignItems     : 'center',
				overflowY      : 'none'
			}
		},
		MuiDialogActions : {
			root : {
				justifyContent : 'space-around'
			}
		},
		MuiButton : {
			label : {
				margin : '0 0.8rem'
			}
		},
		MuiPaper : {
			rounded : {
				borderRadius : '0.5rem'
			}
		}
	}
});

let transferUrl = null;

// delete connection data from firestore 1分(テスト用)
const connectionExpiremilliSeconds = 60000;
// delete connection data from firestore 1時間
// const connectionExpiremilliSeconds = 3600000;

let closeDialogTimer = null;

const JoinRoomNoticeDialog = ({
	joinRoomNoticeDialogOpen,
	setJoinRoomNoticeDialogOpen,
	setJoiningByDirectMode,
	classes,
	type,
	portalConnectionData,
	serviceConnectionData,
	connectPageData,
	directMode,
	targetOperator,
	targetOperatorName,
	invalidOperatorUrl,
	kokyakuId,
	skillId,
	homeJoinMethod,
	homeJoinMethodArg
}) =>
{
	const history = useHistory();

	const intl = useIntl();

	const [ uploadingConnectionData, setUploadingConnectionData ] = useState(false);

	const handleClosePopup = useCallback(() =>
	{
		setJoinRoomNoticeDialogOpen(false);

	}, [ setJoinRoomNoticeDialogOpen ]);

	const submit = () =>
	{
		setJoinRoomNoticeDialogOpen(false);

		if (type==='home')
		{
			homeJoinMethod(homeJoinMethodArg);
		}
		else if (type === 'portal')
		{
			if (transferUrl)
			{
				window.location.replace(transferUrl);
			}
		}
		else if (type === 'service')
		{
			if (transferUrl)
			{
				window.location.replace(transferUrl);
			}
		}
		else if (type === 'connect')
		{
			if (transferUrl)
			{
				window.location.replace(transferUrl);
			}
		}
		else
			if (directMode)
			{
				setJoiningByDirectMode(true);
				history.push({
					pathname : '/',
					state    : {
						directMode         : true,
						skillId            : skillId,
						targetOperator     : invalidOperatorUrl ? '' : targetOperator,
						targetOperatorName : invalidOperatorUrl ? '' : targetOperatorName,
						invalidOperatorUrl : invalidOperatorUrl,
						kokyakuId          : kokyakuId
					}
				});
			}
			else
			{
				history.push({
					pathname : '/',
					state    : {
						directMode         : false,
						skillId            : skillId,
						targetOperator     : invalidOperatorUrl ? '' : targetOperator,
						targetOperatorName : invalidOperatorUrl ? '' : targetOperatorName,
						invalidOperatorUrl : invalidOperatorUrl,
						kokyakuId          : kokyakuId
					}
				});
			}
	};

	useEffect(() =>
	{
		if (joinRoomNoticeDialogOpen && type === 'portal')
		{

			setUploadingConnectionData(true);

			transferUrl = null;

			const milliSecondsNow = getMilliSecondsNow();

			firestore.collection('portalConnections')
				.add({
					...portalConnectionData,
					timestamp : milliSecondsNow,
					// now + expire time + spare time(10min)
					expireAt  : new Date(milliSecondsNow+connectionExpiremilliSeconds+600000)
				})
				.then((doc) =>
				{
					setUploadingConnectionData(false);

					transferUrl = directJoinUrl({
						subdomain          : portalConnectionData.subdomain,
						portalId           : portalConnectionData.portalId,
						portalKey          : portalConnectionData.portalKey ? portalConnectionData.portalKey : '',
						portalConnectionId : doc.id
					});

					// portalConnections.expireAtの時間になったらダイアログを閉じる
					closeDialogTimer = setTimeout(() =>
					{
						handleClosePopup();
					}, connectionExpiremilliSeconds);
				})
				.catch(() =>
				{
					// eslint-disable-next-line no-alert
					window.alert('エラーが発生しました。もう一度お試しください。');
					setUploadingConnectionData(false);
					setJoinRoomNoticeDialogOpen(false);
				});
		}
		else if (joinRoomNoticeDialogOpen && type === 'service')
		{
			if (serviceConnectionData.callDuration)
			{
				// eslint-disable-next-line no-alert
				window.alert('通話時間がありません');
			}
			else
			{
				setUploadingConnectionData(true);

				transferUrl = null;

				const milliSecondsNow = getMilliSecondsNow();

				firestore.collection('serviceConnections')
					.add({
						...serviceConnectionData,
						timestamp : milliSecondsNow,
						expireAt  : new Date(milliSecondsNow+10800000) // 3時間
						// expireAt : new Date(milliSecondsNow+604900000) // 1週間後 
					})
					.then(function(doc)
					{
						setUploadingConnectionData(false);

						transferUrl = directJoinUrl({
							subdomain           : serviceConnectionData.subdomain,
							serviceParamKey     : serviceConnectionData.serviceParamKey,
							serviceConnectionId : doc.id
						});

						// 通話後に再ログインさせる
						localStorage.setItem('serviceConnectionId', doc.id);
					})
					.catch(function(error)
					{
						// eslint-disable-next-line no-console
						console.log('add portalConnections err:', error);
						// eslint-disable-next-line no-alert
						window.alert('エラーが発生しました。もう一度お試しください。');
						setUploadingConnectionData(false);
						setJoinRoomNoticeDialogOpen(false);
					});
			}
		}
		else if (joinRoomNoticeDialogOpen && type === 'connect')
		{
			transferUrl = null;

			transferUrl = directJoinUrl({
				subdomain    : connectPageData.subdomain,
				btnKey       : connectPageData.btnKey,
				connectionId : connectPageData.connectionId,
				locale       : connectPageData.locale
			});
		}
		else
		{
			setUploadingConnectionData(false);
			transferUrl = null;
		}

		// closeTimerをリセット
		return () =>
		{
			if (closeDialogTimer)
			{
				try
				{
					clearTimeout(closeDialogTimer);
					closeDialogTimer = null;
				}
				catch
				{
					// do nothing
				}
			}
		};
	},
	// eslint-disable-next-line react-hooks/exhaustive-deps
	[ joinRoomNoticeDialogOpen, type, handleClosePopup ]);

	return (
		<MuiThemeProvider theme={theme}>
			<Dialog
				className={classes.root}
				open={joinRoomNoticeDialogOpen}
				onClose={handleClosePopup}
				classes={{
					paper : classes.dialogPaper
				}}
			>
				<DialogTitle id='form-dialog-title'>
					<Box
						display='flex'
						alignItems='center'
						flexDirection='column'
					>
						<ErrorOutlineIcon className={classes.titleIcon} />
						<Typography className={classes.titleText}>
							<FormattedMessage
								id='service1.authoritation'
								defaultMessage='please allow access to the camera/microphone.'
							/>
						</Typography>
					</Box>
				</DialogTitle>
				<DialogContent>
					{/* <ul type='disc' className={classes.content}>
						<li className={classes.contentText}>
							<p>マイク利用は必ず<span className={classes.permission}>許可</span>してください</p>
						</li>
						<li className={classes.contentText}>
							<p>
								{'カメラ利用は「キャンセル」を選択すると\n通話途中でカメラONにはできないのでご注意ください。'}
							</p>
						</li>
					</ul> */}
					<Box className={classes.contentSection}>
						<Box className={classes.requestAccessImageWrapper}>
							<img
								alt='access request example'
								src={AccessRequestImage}
								className={classes.requestAccessImage}
							/>
						</Box>
						<Box className={classes.separateLineWrapper}>
							<hr className={classes.separateLine}/>
						</Box>
						<Box>
							<p className={classes.termsText}>
								{ intl.formatMessage({ id: 'service1.terms-1' }) !== '_' &&
									<FormattedMessage
										id='service1.terms-1'
										defaultMessage='Please check the '
									/>
								}
								<Link
									to={{ pathname :
										(serviceConnectionData?.termsPage === 'liveTranslate' || portalConnectionData?.termsPage === 'liveTranslate')
											? 'https://www.tuuyaku.com/terms'
											:'https://www.2nddoorsupport.com/termsofservice'
									}}
									target='_blank'
									className={classes.termsLink}
								>
									<FormattedMessage
										id='service1.termsLink'
										defaultMessage='terms of service'
									/>
								</Link>
								{ intl.formatMessage({ id: 'service1.terms-2' }) !== '_' &&
									<FormattedMessage
										id='service1.terms-2'
										defaultMessage=''
									/>
								}
							</p>
						</Box>
					</Box>
				</DialogContent>
				<DialogActions>

					{ uploadingConnectionData ?
						<Button
							className={classes.confirmBtn}
							disabled
						>
							<FormattedMessage
								id='label.confirmed'
								defaultMessage='Confirmed'
							/>
						</Button>
						:
						<Button
							className={classes.confirmBtn}
							color='primary'
							onClick={() => submit()}
						>
							<FormattedMessage
								id='label.confirmed'
								defaultMessage='Confirmed'
							/>
						</Button>
					}
				</DialogActions>
			</Dialog>
		</MuiThemeProvider>
	);
};

JoinRoomNoticeDialog.propTypes =
{
	joinRoomNoticeDialogOpen    : PropTypes.bool.isRequired,
	setJoinRoomNoticeDialogOpen : PropTypes.func.isRequired,
	setJoiningByDirectMode      : PropTypes.func.isRequired,
	classes                     : PropTypes.object.isRequired,
	type                        : PropTypes.string,
	portalConnectionData        : PropTypes.object,
	serviceConnectionData       : PropTypes.object,
	connectPageData             : PropTypes.object,
	directMode                  : PropTypes.bool,
	skillId                     : PropTypes.string,
	targetOperator              : PropTypes.string,
	targetOperatorName          : PropTypes.string,
	invalidOperatorUrl          : PropTypes.bool,
	kokyakuId                   : PropTypes.string,
	homeJoinMethod              : PropTypes.func,
	homeJoinMethodArg           : PropTypes.object
};

const makeMapStateToProps = () =>
{
	const mapStateToProps = (state) =>
	{
		return {
			joinRoomNoticeDialogOpen : state.room.joinRoomNoticeDialogOpen
		};
	};

	return mapStateToProps;
};

const mapDispatchToProps = (dispatch) =>
{
	return {
		setJoinRoomNoticeDialogOpen : (joinRoomNoticeDialogOpen) =>
		{
			dispatch(roomActions.setJoinRoomNoticeDialogOpen(joinRoomNoticeDialogOpen));
		},
		setJoiningByDirectMode : (joiningByDirectMode) =>
		{
			dispatch(roomActions.setJoiningByDirectMode(joiningByDirectMode));
		}
	};
};

export default withRoomContext(connect(
	makeMapStateToProps,
	mapDispatchToProps,
	null,
	{
		areStatesEqual : (next, prev) =>
		{
			return (
				prev.room.joinRoomNoticeDialogOpen === next.room.joinRoomNoticeDialogOpen
			);
		}
	}
)(withStyles(styles)(JoinRoomNoticeDialog)));