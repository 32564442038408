import {
	CALL_STATUS_CATCH,
	CALL_STATUS_NO_ONE_AVAILABLE,
	CALL_STATUS_NO_REPLY,
	CALL_STATUS_STOP,
	ROOM_ONLINE,
	WAITING_GUESTS_STATUS_CONNECTED,
	WAITING_GUESTS_STATUS_DISCONNECT,
	CALL_STATUS_FINISHED
} from '../../../../../../const';

import { getYearMonthDay } from '../../utils/formatDate';
import { roundToDecimalPlaces } from '../../utils/roundDecimal';

import { calcSecondsFromTimestampForSkill } from '../../utils/SecondsFromTime';

export const formatDataForButtons = (
	fromDateTime,
	toDateTime,
	calls,
	roomsSnapshots,
	queueResults,
	buttons
) =>
{
	// Filter calls based on the date range
	const filteredCalls = calls.filter((item) =>
	{
		const itemDate = getYearMonthDay(item.timestamp);
		const fromDate = getYearMonthDay(fromDateTime);
		const toDate = getYearMonthDay(toDateTime);

		return itemDate >= fromDate && itemDate <= toDate;
	});

	const filteredRoomSnapshots = roomsSnapshots.filter((item) =>
	{
		if (item.timestamp)
		{
			const date = getYearMonthDay(item.timestamp);
			const fromDate = getYearMonthDay(fromDateTime);
			const toDate = getYearMonthDay(toDateTime);

			return date >= fromDate && date <= toDate;
		}
		else
		{
			return false;
		}
	});

	const filteredQueueResults = queueResults.filter((item) =>
	{
		const date = getYearMonthDay(item.start);
		const fromDate = getYearMonthDay(fromDateTime);
		const toDate = getYearMonthDay(toDateTime);

		return date >= fromDate && date <= toDate;
	});

	// Function to calculate the number of received calls for a language
	const funcNumberOfCallsReceived = (callsForBtn) =>
	{
		const numberOfCallsReceived = callsForBtn.reduce((accumulator, item) =>
		{
			if (item.status === CALL_STATUS_CATCH || item.status === CALL_STATUS_FINISHED)
			{
				accumulator++;
			}

			return accumulator;
		}, 0);

		return numberOfCallsReceived;
	};

	const funcCountNumberOfActiveSeats = (uptime, totalSeconds) =>
	{
		return uptime/totalSeconds;
	};

	/**
   * @param {Function} funcGetDate
   * @param {Date} dateTime
   * @returns {number} - seconds
   */
	const funcCountTimeWaiting = (snapshot, btn) =>
	{
		const totalTimeWaiting = snapshot.reduce((accumulator, item) =>
		{
			if (item.subdomain === btn.subdomain)
			{

				let roomFound = false;

				// skillIdあり
				if (btn.skillId && btn.skillId !== '_')
				{

					for (const rm of item.rooms)
					{
						if (rm.status === ROOM_ONLINE && rm.skills && rm.skills.includes(btn.skillId))
						{
							roomFound = true;
							break;
						}
					}
				}
				// skillIdなし
				else
				{
					for (const rm of item.rooms)
					{
						if (rm.status === ROOM_ONLINE)
						{
							roomFound = true;
							break;
						}
					}
				}

				if (roomFound)
				{
					// 基本5分(300秒)ごと
					accumulator += item.intervalMinute ? item.intervalMinute * 60 : 300;
				}
			}

			return accumulator;
		}, 0);

		return totalTimeWaiting;
	};

	/**
   * @param {Function} funcGetDate
   * @param {Date} dateTime
   * @returns {number} - seconds
   */
	const funcGetTotalTalkTime = (callsForBtn) =>
	{
		const totalTalkTime = callsForBtn.reduce((accumulator, item) =>
		{

			accumulator += item.duration;

			return accumulator;
		}, 0);

		return totalTalkTime;
	};

	/**
   * @param {Function} funcGetDate
   * @param {Date} dateTime
   * @returns {number}
   */
	const funcCountNumberOfMissedCalls = (callsForBtn) =>
	{
		const numberOfMissedCalls = callsForBtn.reduce((accumulator, item) =>
		{

			if (item.status === CALL_STATUS_NO_REPLY
						|| item.status === CALL_STATUS_NO_ONE_AVAILABLE)
			{
				accumulator++;
			}

			return accumulator;
		}, 0);

		return numberOfMissedCalls;
	};

	/**
   * @param {Function} funcGetDate
   * @param {Date} dateTime
   * @returns {number}
   */
	const funcCountNumberOfBreaks = (callsForBtn) =>
	{
		const numberOfBreaks = callsForBtn.reduce((accumulator, item) =>
		{
			if (item.status === CALL_STATUS_STOP)
			{
				accumulator++;
			}

			return accumulator;
		}, 0);

		return numberOfBreaks;
	};

	const funcCountNumberOfCallsWaiting = (queueResultsForBtn) =>
	{
		const callsWaitingNumber
			= queueResultsForBtn.reduce((accumulator, item) =>
			{

				if (item.status === WAITING_GUESTS_STATUS_CONNECTED
						|| item.end - item.start >= 10000) accumulator++;

				return accumulator;

			}, 0);

		return callsWaitingNumber;
	};

	const getCallWaitingAverageWaitingTime = (queueResultsForBtn) =>
	{
		let numberQueueResults = 0;

		const callWaitingTotalWaitingTime
			= queueResultsForBtn.reduce((accumulator, item) =>
			{

				numberQueueResults++;
				const timeWait = item.end - item.start;

				accumulator += timeWait;

				return accumulator;
			}, 0);

		return numberQueueResults > 0 ? callWaitingTotalWaitingTime / numberQueueResults : 0;
	};

	const funcCountCallWaitingNumberOfSuccessfulConnections = (
		queueResultsForBtn
	) =>
	{
		const callWaitingNumberOfSuccessfulConnections = queueResultsForBtn.reduce(
			(accumulator, item) =>
			{
				if (item.status === WAITING_GUESTS_STATUS_CONNECTED) accumulator++;

				return accumulator;
			},
			0
		);

		return callWaitingNumberOfSuccessfulConnections;
	};

	const funcCountCallWaitingNumberOfExits = (queueResultsForBtn) =>
	{
		const callWaitingNumberOfExits
			= queueResultsForBtn.reduce((accumulator, item) =>
			{

				if (item.status === WAITING_GUESTS_STATUS_DISCONNECT) accumulator++;

				return accumulator;

			}, 0);

		return callWaitingNumberOfExits;
	};

	// Function to calculate call received rate
	const funcCallReceivedRate = (numberOfIncomingCalls, numberOfCallsReceived) =>
	{
		if (numberOfIncomingCalls > 0)
		{
			const resultRate = (numberOfCallsReceived / numberOfIncomingCalls) * 100;

			return parseFloat(resultRate.toFixed(2));
		}

		return 0;
	};

	const calculateDataForCond = (btn) =>
	{
		const callsForBtn = filteredCalls.filter(
			(c) => c.skillId === btn.skillId && c.subdomain === btn.subdomain
		);

		const queueResultsForBtn = filteredQueueResults.filter(
			(q) => q.skillId === btn.skillId && q.subdomain === btn.subdomain
		);

		// const roomsForBtn = filteredRoomSnapshots.filter(
		// 	(r) => r.skills && r.skills.includes(btn.skillId) && r.subdomain === btn.subdomain
		// );

		const numberOfIncomingCalls = callsForBtn.reduce(
			(acc) => acc + 1,
			0
		);

		const numberOfCallsReceived = funcNumberOfCallsReceived(
			callsForBtn
		);

		const callReceivedRate = funcCallReceivedRate(
			numberOfIncomingCalls,
			numberOfCallsReceived
		);

		const totalTalkTime = funcGetTotalTalkTime(
			callsForBtn
		);

		const countTimeWaiting = funcCountTimeWaiting(
			filteredRoomSnapshots,
			btn
		);

		const workingSeconds = calcSecondsFromTimestampForSkill({
			from : fromDateTime,
			to   : toDateTime,
			btn  : btn
		});

		const countNumberOfActiveSeats = funcCountNumberOfActiveSeats(
			totalTalkTime+countTimeWaiting, workingSeconds
		);

		const countNumberOfMissedCalls = funcCountNumberOfMissedCalls(
			callsForBtn
		);

		const countNumberOfBreaks = funcCountNumberOfBreaks(
			callsForBtn
		);

		const numberOfCallsWaiting=funcCountNumberOfCallsWaiting(queueResultsForBtn);

		const callWaitingAverageWaitingTime = getCallWaitingAverageWaitingTime(
			queueResultsForBtn
		);

		const callWaitingNumberOfSuccessFulConnections =
			funcCountCallWaitingNumberOfSuccessfulConnections(
				queueResultsForBtn
			);

		const callWaitingNumberOfExits = funcCountCallWaitingNumberOfExits(
			queueResultsForBtn
		);

		const upTime = totalTalkTime + countTimeWaiting; // seconds
		const averageTalkTime = totalTalkTime / numberOfCallsReceived;

		const callWaitingRate =
      (numberOfCallsWaiting /
        (numberOfIncomingCalls - countNumberOfMissedCalls - countNumberOfBreaks)) *
      100;

		return {
			入電数          : `${numberOfIncomingCalls} 件`,
			受電数          : `${numberOfCallsReceived} 件`,
			受電率          : `${roundToDecimalPlaces(callReceivedRate, 2)}%`,
			稼働席数         : `${roundToDecimalPlaces(countNumberOfActiveSeats, 3)} 席`,
			稼働時間         : `${roundToDecimalPlaces(upTime / 60, 2)} 分`,
			合計通話時間       : `${roundToDecimalPlaces(totalTalkTime / 60, 2)} 分`,
			平均通話時間       : `${roundToDecimalPlaces(averageTalkTime / 60, 2)} 分`,
			不在数          : `${countNumberOfMissedCalls} 件`,
			切断数          : `${countNumberOfBreaks} 件`,
			待ち呼数         : `${numberOfCallsWaiting} 件`,
			待ち呼率         : `${roundToDecimalPlaces(callWaitingRate, 2)}%`,
			'待ち呼　平均待機時間' : `${roundToDecimalPlaces(
				callWaitingAverageWaitingTime / (60 * 1000),
				2
			)} 分`,
			'待ち呼　接続成功数' : `${callWaitingNumberOfSuccessFulConnections} 件`,
			'待ち呼　離脱数'   : `${callWaitingNumberOfExits} 件`
		};
	};

	const results = [];

	buttons.forEach((btn) =>
	{
		results.push(calculateDataForCond({
			...btn,
			skillId : btn.skillId || '_'
		}));
	});

	return results;
};
