import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { withRoomContext } from '../../../../RoomContext';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

// util
import { showDateTime } from '../../../../utils';

// const
import { PAYMENT_CREDIT_CARD, PAYMENT_MONTHLY, PAYMENT_FREE, PAYMENT_AMAZON_PAY } from '../../../../const';

// firestore
import { firestore, functions } from '../../../../lib/firebase';

// dayjs
import dayjs from 'dayjs';

const styles = () =>
	({
		root :
		{
			zIndex : 99,
			width  : '100%',
			height : '100%'
		},
		dialogPaper :
		{
			width           : '90vw',
			padding         : '1% 1%',
			backgroundColor : '#FFF'
		},
		titleSection :
		{
			width    : '100%',
			position : 'relative'
		},
		title :
		{
			width         : '100%',
			display       : 'flex',
			flexDirection : 'column',
			alignItems    : 'center'
		},
		titleText :
		{
			color    : 'var(--text-color)',
			fontSize : '1.1rem'
		},
		topData : {
			width          : '100%',
			height         : '1.75rem',
			display        : 'flex',
			margin         : '20px 0',
			justifyContent : 'space-between',
			lineHeight     : 1
		},
		dataLabel : {
			width         : '50%',
			fontSize      : '0.8rem',
			paddingTop    : '10px',
			// whiteSpace    : 'nowrap',
			display       : 'flex',
			flexDirection : 'column',
			alignItems    : 'flex-start'
		},
		label : {
			color        : 'var(--text-color)',
			fontSize     : '0.9rem',
			overflowWrap : 'break-word'
		},
		dataValueText : {
			fontSize : '0.9rem',
			color    : 'var(--text-color)'
		},
		callLine : {
			width          : '100%',
			padding        : '0 5px',
			display        : 'flex',
			justifyContent : 'center'
		},
		indexCol : {
			paddingTop  : '4px',
			width       : '8%',
			borderRight : '1px solid #CCC',
			textAlign   : 'center'
		},
		timeCol : {
			paddingTop  : '4px',
			width       : '30%',
			borderRight : '1px solid #CCC',
			textAlign   : 'center'
		},
		serviceCol : {
			paddingTop  : '4px',
			width       : '20%',
			borderRight : '1px solid #CCC',
			textAlign   : 'center'
		},
		durationCol : {
			paddingTop  : '4px',
			width       : '20%',
			borderRight : '1px solid #CCC',
			textAlign   : 'center'
		},
		hostIdCol : {
			paddingTop : '4px',
			width      : '27%',
			textAlign  : 'center'
		},
		talkTimeBox : {
			width           : '95%',
			margin          : '10px auto',
			padding         : '10px 20px',
			backgroundColor : 'rgba(0,0,0,0.6)',
			borderRadius    : '15px'
		},
		talkTimeLine : {
			margin         : '5px 0',
			display        : 'flex',
			justifyContent : 'space-between'
		},
		statusText :
		{
			color    : '#FFF',
			fontSize : '0.9rem'
		},
		remainingMinutesText :
		{
			color    : '#FFF',
			fontSize : '1rem'
		},
		disableButton :
		{
			fontSize        : '0.8rem',
			padding         : '1px 6px',
			backgroundColor : '#e26b04',
			color           : '#FFF',
			'&:hover' :
			{
				backgroundColor : '#e26b04',
				filter          : 'saturate(105%) brightness(105%)'
			}
		},
		callHistorySection : {
			marginBottom : '30px'
		},
		messageSection : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'center',
			marginBottom   : '10px'
		},
		message : {
			fontSize : '0.8rem',
			color    : 'var(--text-color)'
		},
		setDateSection : {
			marginTop      : '20px',
			width          : '100%',
			display        : 'flex',
			justifyContent : 'center'
		},
		inputDate : {
			width    : '230px',
			fontSize : '0.9rem'
		},
		cancelMessageSection : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'center'
		},
		cancelMessage : {
			fontSize : '0.9rem',
			color    : 'var(--text-color)'
		},
		cancelConfirmBtnSection : {
			marginTop      : '30px',
			width          : '100%',
			display        : 'flex',
			justifyContent : 'space-around'
		},
		cancelYesBtn : {
			fontSize        : '1rem',
			padding         : '3px 24px',
			backgroundColor : '#e26b04',
			color           : '#FFF',
			'&:hover' :
			{
				backgroundColor : '#e26b04',
				filter          : 'saturate(105%) brightness(105%)'
			}
		},
		cancelNoBtn : {
			fontSize        : '1rem',
			padding         : '3px 24px',
			backgroundColor : 'var(--text-color)',
			color           : '#FFF',
			'&:hover' :
			{
				backgroundColor : 'var(--text-color)',
				filter          : 'saturate(105%) brightness(105%)'
			}
		},
		dialogButtons :
		{
			width          : '100%',
			display        : 'flex',
			justifyContent : 'space-around'
		},
		orderCancelBtn : {
			width           : '28%',
			fontSize        : '0.9rem',
			border          : '1px solid red',
			backgroundColor : 'transparent',
			color           : 'red',
			'&:hover' :
			{
				filter : 'saturate(90%) brightness(90%)'
			}
		},
		orderCancelBtnDisabled : {
			backgroundColor : '#AAA !important',
			color           : '#FFF !important',
			border          : 'none !important',
			'&:hover' :
			{
				backgroundColor : '#AAA !important',
				filter          : 'saturate(90%) brightness(90%)'
			}
		},
		cancelBtn :
		{
			width           : '28%',
			fontSize        : '0.9rem',
			border          : '1px solid var(--text-color)',
			backgroundColor : 'transparent',
			color           : 'var(--text-color)'
		},
		errMsg :
		{
			color        : 'red',
			fontSize     : '0.9rem',
			lineHeight   : '0.9rem',
			marginBottom : '3px'
		},
		errorBox :
		{
			marginTop : '8px'
		},
		loading :
		{
			width           : '100%',
			height          : '100%',
			minWidth        : '25vw',
			minHeight       : '40vh',
			zIndex          : 9999999,
			display         : 'flex',
			justifyContent  : 'center',
			alignItems      : 'center',
			backgroundColor : 'rgba(255, 255, 255, 0)'
		},
		statusChanging : {
			width           : '100%',
			height          : '63px',
			display         : 'flex',
			justifyContent  : 'center',
			alignItems      : 'center',
			backgroundColor : 'rgba(255, 255, 255, 0)'
		}
	});

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	},
	overrides : {
		MuiInput : {
			underline : {
				'&:before' : {
					borderBottom : 'none'
				},
				'&:after' : {
					borderBottom : 'none'
				},
				'&:not(.Mui-disabled):hover::before' : {
					borderBottom : 'none'
				},
				'&.Mui-disabled:before' : {
					borderBottomStyle : 'none'
				},
				'&.Mui-disabled' : {
					backgroundColor : '#F6F6F6'
				}
			}
		},
		MuiTypography : {
			root : {
				color    : 'var(--text-color)',
				fontSize : '0.8rem'
			},
			body1 : {
				color    : 'var(--text-color)',
				fontSize : '0.8rem'
			}
		}
	}
});

const showPaymentMethod = (value) =>
{
	if (value === PAYMENT_CREDIT_CARD)
	{
		return 'クレジットカード';
	}
	else if (value === PAYMENT_AMAZON_PAY)
	{
		return 'Amazon Pay';
	}
	else if (value === PAYMENT_MONTHLY)
	{
		return '月次決済';
	}
	else if (value === PAYMENT_FREE)
	{
		return '無料';
	}
	else
	{
		return '';
	}

};

const getDateString = (timestamp) =>
{
	const date = timestamp ? new Date(timestamp) : new Date();
	const month = (`0${date.getMonth() + 1}`).slice(-2);
	const day = (`0${date.getDate()}`).slice(-2);
	const hour = (`0${date.getHours()}`).slice(-2);
	const min = (`0${date.getMinutes()}`).slice(-2);

	return `${date.getFullYear()}/${month}/${day} ${hour}:${min}`;
};

const getTalkTimeString = (duration) =>
{
	const seconds = parseInt(duration);
	const min = (`${parseInt(seconds/60)}`);
	const sec = (`0${parseInt(seconds%60)}`).slice(-2);

	return `${min}分${sec}秒`;
};

const showExpiryTime = (expiryTimestamp, expiryTimestampForUse) =>
{
	let timestamp = expiryTimestamp;

	if (!timestamp)
	{
		timestamp = expiryTimestampForUse;
	}

	if (timestamp)
	{
		const dayjsTime = dayjs(timestamp);

		return dayjsTime.format('YYYY/MM/DD H:mm');
	}
	else
	{
		return '　-　';
	}
};

const TicketDetailDialog = ({
	classes,
	show,
	closeMethod,
	ticket,
	changeDisableStatus
}) =>
{

	const [ page, setPage ] = useState('home');

	const [ loading, setLoading ] = useState(false);

	const [ cancelComplete, setCancelComplete ] = useState(false);

	const [ statusChanging, setStatusChanging ] = useState(false);

	const [ ticketNumber, setTicketNumber ] = useState('');

	const [ ticketCalls, setTicketCalls ] = useState([]);

	const [ message, setMessage ] = useState('');

	const closeDialog = () =>
	{
		setPage('home');
		setLoading(false);
		setCancelComplete(false);
		setStatusChanging(false);
		setTicketNumber('');
		setTicketCalls([]);
		setMessage('');
		closeMethod();
	};

	const getTicketData = useCallback(async () =>
	{

		setLoading(true);

		firestore.collection('calls')
			.where('serviceTicket', '==', ticket.id)
			.get()
			.then((tickets) =>
			{

				if (!tickets.docs || tickets.docs.length === 0)
				{
					setTicketCalls([]);
				}
				else
				{
					const callsList = [];

					tickets.forEach((item) =>
					{
						callsList.push({ id: item.id, ...item.data() });
					});

					callsList.sort((a, b) =>
					{
						if (a.timestamp < b.timestamp) { return 1; }
						if (a.timestamp > b.timestamp) { return -1; }

						return 0;
					});

					setTicketCalls(callsList);
				}
			})
			.catch((e) =>
			{
				// eslint-disable-next-line no-console
				console.log(`error ${e}`);
			});

		if (!ticket.orderCanceled)
		{
			const serviceTicketNumberDecryptAPI = functions.httpsCallable('serviceTicketNumberDecrypt');

			serviceTicketNumberDecryptAPI({
				ticketNumber : ticket.encryptedNumber
			}).then((response) =>
			{
				if (response.data?.success)
				{
					setTicketNumber(`${ticket.accountParamKey}${response.data.ticketNumber}`);

					setLoading(false);
				}
			});
		}
		else
		{
			setLoading(false);
		}

	}, [
		ticket.id,
		ticket.encryptedNumber,
		ticket.accountParamKey,
		ticket.orderCanceled
	]);

	useEffect(() =>
	{
		if (show)
		{
			getTicketData();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ show ]);

	const disableTicket = (value) =>
	{
		setStatusChanging(true);

		firestore.collection('serviceTickets')
			.doc(ticket.id)
			.update({
				disabled : value
			})
			.then(() =>
			{
				changeDisableStatus(value);

				setStatusChanging(false);
			})
			.catch((e) =>
			{
				// eslint-disable-next-line no-console
				console.log('error:', e);

				setStatusChanging(false);
			});
	};

	const showRemainingMinutes = (duration, consumedSeconds) =>
	{
		if (duration)
		{
			const consumedValue
				= consumedSeconds ? consumedSeconds : 0;

			const remainingSeconds
				= duration - consumedValue;

			if (remainingSeconds > 0)
			{
				return Math.ceil(remainingSeconds/60);
			}
			else
			{
				return 0;
			}
		}
		else
		{
			return 0;
		}
	};

	const showDurationMinutes = (duration) =>
	{
		if (duration === -1)
		{
			return '使い放題';
		}
		else if (duration > 0)
		{
			return `${Math.ceil(duration/60)}分`;
		}
		else
		{
			return '0分';
		}
	};

	const cancelTicketOrder = () =>
	{
		setLoading(true);

		const serviceTicketCancelAPI = functions.httpsCallable('serviceTicketCancel');

		serviceTicketCancelAPI({
			orderId       : ticket.id,
			accessId      : ticket.gmoAccessId ? ticket.gmoAccessId : '',
			accessPass    : ticket.gmoAccessPass ? ticket.gmoAccessPass : '',
			paymentMethod : ticket.paymentMethod,
			salesAmount   : ticket.salesAmount
		}).then((response) =>
		{
			if (response.data?.success)
			{

				setTicketNumber(`${ticket.accountParamKey}${response.data.ticketNumber}`);

				setPage('home');

				setMessage('キャンセルが完了しました');

				setCancelComplete(true);

				setLoading(false);
			}
			else
			{
				setTicketNumber(`${ticket.accountParamKey}${response.data.ticketNumber}`);

				setPage('home');

				setMessage('キャンセルが失敗しました');

				setLoading(false);
			}
		})
			.catch(() =>
			{
				setPage('home');
				setMessage('キャンセルが失敗しました');
				setLoading(false);
			});

	};

	return (
		<MuiThemeProvider theme={theme}>
			<Dialog
				className={classes.root}
				open={show}
				onClose={loading ? () => {} : closeDialog}
				classes={{
					paper : classes.dialogPaper
				}}
			>
				{ page === 'home' ?
					<>
						<DialogTitle id='form-dialog-title'>
							<div className={classes.titleSection}>
								<div className={classes.title}>
									<div className={classes.titleText}>
										チケット情報
									</div>
								</div>
							</div>
						</DialogTitle>
						<DialogContent>
							{ loading ?
								<Box className={classes.loading}>
									<CircularProgress />
								</Box>
								:
								<>
									{ message &&
										<Box className={classes.messageSection}>
											<Typography className={classes.message}>
												{message}
											</Typography>
										</Box>
									}

									<Box className={classes.topData}>
										<Box className={classes.dataLabel}>
											<Typography className={classes.label}>オーダーID</Typography>
										</Box>
										<Box className={classes.dataValue}>
											<Typography className={classes.dataValueText}>
												{ticket.id}
											</Typography>
										</Box>
									</Box>
									<Box className={classes.topData}>
										<Box className={classes.dataLabel}>
											<Typography className={classes.label}>購入日時</Typography>
										</Box>
										<Box className={classes.dataValue}>
											<Typography className={classes.dataValueText}>
												{showDateTime(ticket.createdAt)}
											</Typography>
										</Box>
									</Box>

									<Box className={`${classes.topData}`}>
										<Box className={classes.dataLabel}>
											<Typography className={classes.label}>企業名</Typography>
										</Box>
										<Box className={classes.dataValue}>
											<Typography className={classes.dataValueText}>
												{ticket.accountName}
											</Typography>
										</Box>
									</Box>

									<Box className={`${classes.topData}`}>
										<Box className={classes.dataLabel}>
											<Typography className={classes.label}>チケットコード</Typography>
										</Box>
										<Box className={classes.dataValue}>
											<Typography className={classes.dataValueText}>
												{ticket.orderCanceled || cancelComplete ? '購入キャンセル' : ticketNumber}
											</Typography>
										</Box>
									</Box>
									<Box className={classes.topData}>
										<Box className={classes.dataLabel}>
											<Typography className={classes.label}>購入者メールアドレス</Typography>
										</Box>
										<Box className={classes.dataValue}>
											<Typography className={classes.dataValueText}>
												{ticket.email}
											</Typography>
										</Box>
									</Box>
									<Box className={classes.topData}>
										<Box className={classes.dataLabel}>
											<Typography className={classes.label}>購入者登録電話番号</Typography>
										</Box>
										<Box className={classes.dataValue}>
											<Typography className={classes.dataValueText}>
												{ticket.phoneNumber}
											</Typography>
										</Box>
									</Box>
									<Box className={classes.topData}>
										<Box className={classes.dataLabel}>
											<Typography className={classes.label}>決済方法</Typography>
										</Box>
										<Box className={classes.dataValue}>
											<Typography className={classes.dataValueText}>
												{showPaymentMethod(ticket.paymentMethod)}
											</Typography>
										</Box>
									</Box>

									<Box className={classes.topData}>
										<Box className={classes.dataLabel}>
											<Typography className={classes.label}>利用分数</Typography>
										</Box>
										<Box className={classes.dataValue}>
											<Typography className={classes.dataValueText}>
												{
													showDurationMinutes(
														ticket.duration
													)
												}
											</Typography>
										</Box>
									</Box>

									{ statusChanging ?
										<Box className={classes.talkTimeBox}>
											<Box className={classes.statusChanging}>
												<CircularProgress />
											</Box>
										</Box>
										:
										<Box className={classes.talkTimeBox}>
											<Box className={classes.talkTimeLine}>
												<Typography className={classes.statusText}>
													{`有効期限 ${showExpiryTime(
														ticket.expiryTimestamp,
														ticket.expiryTimestampForUse
													)}`}
												</Typography>

												{ ticket.disabled ?
													<Button
														className={classes.disableButton}
														onClick={() => disableTicket(false)}
														color='primary'
													>
														利用停止中
													</Button>
													:
													<Button
														className={classes.disableButton}
														onClick={() => disableTicket(true)}
														color='primary'
													>
														強制利用停止
													</Button>
												}
											</Box>
											<Box className={classes.talkTimeLine}>
												<Typography className={classes.remainingMinutesText}>
													現在利用可能 残り分数
												</Typography>
												<Typography className={classes.remainingMinutesText}>
													{	ticket.duration === -1 ?
														'使い放題'
														: `${showRemainingMinutes(
															ticket.duration, ticket.consumedSeconds
														)}分`
													}
												</Typography>
											</Box>
										</Box>
									}

									<Box className={classes.callHistorySection}>
										<Box className={classes.callLine}>
											<Typography className={classes.indexCol}> </Typography>
											<Typography className={classes.timeCol}>通話時刻</Typography>
											<Typography className={classes.serviceCol}>利用サービス</Typography>
											<Typography className={classes.durationCol}>通話時間</Typography>
											<Typography className={classes.hostIdCol}>対応者</Typography>
										</Box>
										{ticketCalls.map((item, index) => (
											<Box className={classes.callLine} key={item.id}>
												<Typography className={classes.indexCol}>{index+1}</Typography>
												<Typography className={classes.timeCol}>
													{getDateString(item.timestamp)}
												</Typography>
												<Typography className={classes.serviceCol}>
													{item.subdomain}
												</Typography>
												<Typography className={classes.durationCol}>
													{getTalkTimeString(item.duration)}
												</Typography>
												<Typography className={classes.hostIdCol}>
													{item.hostLoginId}
												</Typography>
											</Box>
										))}
									</Box>
								</>
							}
						</DialogContent>
						<DialogActions>
							<Box className={classes.dialogButtons}>
								{ ticket.orderCanceled || cancelComplete ?
									<Button
										className={`${classes.orderCancelBtn} ${classes.orderCancelBtnDisabled}`}
										onClick={() => {}}
										color='primary'
										disabled
									>
										キャンセル済
									</Button>
									:
									<Button
										className={classes.orderCancelBtn}
										onClick={() => setPage('cancelConfirm')}
										color='primary'
									>
										購入キャンセル
									</Button>
								}

								<Button
									className={classes.cancelBtn}
									onClick={closeDialog}
									color='primary'
								>
									<FormattedMessage
										id='label.close'
										defaultMessage='Close'
									/>
								</Button>
							</Box>
						</DialogActions>
					</>
					:
					<DialogContent>
						{ loading ?
							<Box className={classes.loading}>
								<CircularProgress />
							</Box>
							:
							<>
								<DialogTitle id='form-dialog-title'>
									<div className={classes.titleSection}>
										<div className={classes.title}>
											<div className={classes.titleText}>
												キャンセル実行
											</div>
										</div>
									</div>
								</DialogTitle>
								<Box className={classes.cancelMessageSection}>
									<Typography>
										オーダーのキャンセルを実行しますか？この操作を実行すると元に戻せなくなります。
									</Typography>
								</Box>
								<Box className={classes.cancelConfirmBtnSection}>
									<Button
										className={classes.cancelYesBtn}
										onClick={cancelTicketOrder}
										color='primary'
									>
										実行
									</Button>

									<Button
										className={classes.cancelNoBtn}
										onClick={() => setPage('home')}
										color='primary'
									>
										中止
									</Button>
								</Box>
							</>
						}
					</DialogContent>
				}
			</Dialog>
		</MuiThemeProvider>
	);
};

TicketDetailDialog.propTypes =
{
	classes             : PropTypes.object.isRequired,
	show                : PropTypes.bool.isRequired,
	ticket              : PropTypes.object,
	closeMethod         : PropTypes.func.isRequired,
	changeDisableStatus : PropTypes.func.isRequired
};

const makeMapStateToProps = () =>
{
	const mapStateToProps = () =>
	{
		return {
		};
	};

	return mapStateToProps;
};

const mapDispatchToProps = () =>
{
	return {
	};
};

export default withRoomContext(connect(
	makeMapStateToProps,
	mapDispatchToProps,
	null,
	{
		areStatesEqual : () =>
		{
			return ({
			});
		}
	}
)(withStyles(styles)(TicketDetailDialog)));