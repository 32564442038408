import React, { useState } from 'react';
import PropTypes from 'prop-types';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

const useStyles = makeStyles(() => ({
	root : {
		'& .MuiFormControl-marginNormal' : {
			display    : 'flex',
			alignItems : 'center'
		},
		'& .MuiInputBase-root' : {
			color           : '#FFF',
			fontSize        : '14px',
			borderRadius    : '4px',
			border          : '1px solid #CCC',
			padding         : '8px 10px',
			backgroundColor : 'transparent',
			width           : '100%'
		},
		'& .MuiInputLabel-root' : {
			color           : '#FFF',
			fontSize        : '14px',
			marginTop       : '-17px',
			transform       : 'translate(14px, 10px) scale(1)',
			backgroundColor : '#929291',
			padding         : '0px 4px',
			zIndex          : 1,
			pointerEvents   : 'none'
		},
		'& .Mui-focused' : {
			color : '#FFF'
		},
		'& .MuiInputAdornment-root .MuiButtonBase-root' : {
			marginLeft : '-12px'
		},
		'& .MuiInputBase-inputAdornedEnd' : {
			width : '50%'
		},
		'& .MuiInputBase-adornedEnd' : {
			justifyContent : 'center'
		}
	},
	toolbar : {
		'& .MuiTypography-h4' : {
			display : 'none'
		},
		'& .MuiPickersToolbar-toolbar' : {
			height : '60px'
		}
	}
}));

const CustomKeyboardDatePicker = withStyles({
	root : {
		'& .MuiInputBase-input' : {
			color : '#FFF'
		},
		'& .MuiInput-underline:before' : {
			borderBottom : 'none'
		},
		'& .MuiInput-underline:after' : {
			borderBottom : 'none'
		},
		'& .MuiIconButton-root' : {
			padding      : 0,
			borderBottom : 'none'
		},
		'& .MuiButtonBase-root' : {
			color : '#FFF'
		},
		'&:hover .MuiInput-underline:before' : {
			borderBottom : 'none'
		}
	}
})(KeyboardDatePicker);

export default function DatePickerYearMonth({ dateYearMonth, setDateYearMonth })
{
	const classes = useStyles();
	const [ isOpen, setIsOpen ] = useState(false);

	const handleOpen = () =>
	{
		setIsOpen(true);
	};

	const handleClose = () =>
	{
		setIsOpen(false);
	};

	const handleDateChange = (date) =>
	{
		setDateYearMonth(date);
	};

	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<Grid container>
				<CustomKeyboardDatePicker
					className={classes.root}
					views={[ 'month' ]}
					variant='inline'
					format='yyyy/MM'
					margin='normal'
					id='date-picker-inline'
					label='年 / 月'
					value={dateYearMonth}
					onChange={handleDateChange}
					onOpen={handleOpen}
					onClose={handleClose}
					open={isOpen}
					PopoverProps={{
						className : classes.toolbar
					}}
					KeyboardButtonProps={{
						'aria-label' : 'change date'
					}}
					keyboardIcon={<ArrowDropDownIcon />}
				/>
			</Grid>
		</MuiPickersUtilsProvider>
	);
}

DatePickerYearMonth.propTypes = {
	dateYearMonth    : PropTypes.instanceOf(Date).isRequired,
	setDateYearMonth : PropTypes.func.isRequired
};
