// React
import React, { useState, useEffect } from 'react';

// Redux
import { connect } from 'react-redux';
import { withRoomContext } from '../../../../RoomContext';

// Router
import { useLocation, useHistory } from 'react-router-dom';

// Prop types
import PropTypes from 'prop-types';

// Message
import { FormattedMessage, useIntl } from 'react-intl';

// Mui styles
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';

// Mui core
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

// Firestore
import { functions, firestore } from '../../../../lib/firebase';

// util
import { getDomainWithoutSubdomain, parseUrlParams } from '../../../../utils';

// const
import { DEFAULT_SERVICE_ACCOUNT_KEY, ISSUED_AT_GUEST_PAGE, PAYMENT_AMAZON_PAY } from '../../../../const';

// Components
import PurchaseComplete from '../Parts/PurchaseComplete';
import ServiceAppBar from '../Parts/ServiceAppBar';

const styles = (theme) =>
	({
		root : {
			zIndex          : 99,
			width           : '100%',
			height          : '100%',
			overflow        : 'auto',
			backgroundColor : '#EBEBEC'
		},
		content : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'center'
		},
		contentArea : {
			width           : 'calc( 100% - 20px )',
			maxWidth        : '750px',
			display         : 'flex',
			flexDirection   : 'column',
			justifyContent  : 'flex-start',
			alignItems      : 'center',
			margin          : '30px 15px',
			padding         : '30px 0px',
			minWidth        : '320px',
			backgroundColor : '#FFF',
			borderRadius    : '20px'
		},
		pageContent : {
			width          : '100%',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'flex-start',
			alignItems     : 'center'
		},
		title : {
			fontSize : '1.1rem'
		},
		subTitle : {
			paddingLeft  : '18px',
			borderLeft   : '2px solid #cd2c82',
			fontSize     : '1rem',
			marginBottom : '15px'
		},
		contentWrapper : {
			marginTop : '25px'
		},
		purchaseItemLine : {
			display  : 'flex',
			minWidth : '340px'
		},
		purchaseItemCell : {
			border      : '1px solid #9E9E9E',
			paddingLeft : '3px'
		},
		purchaseItemCellSecond : {
			borderLeft : 'none'
		},
		purchaseItemCellAbove : {
			borderBottom : 'none'
		},
		ticketInfoLeft : {
			width    : '25%',
			maxWidth : '250px'
		},
		ticketInfoRight : {
			width    : '75%',
			maxWidth : '750px'
		},
		issuedTicketBox : {
			padding      : '4px 10px',
			border       : '1px solid #292929',
			borderRadius : '10px',
			marginBottom : '5px'
		},
		copyCodeBtn : {
			padding         : '1px 10px',
			backgroundColor : '#cd2c82',
			color           : '#FFF',
			cursor          : 'pointer',
			'&:hover'       : {
				backgroundColor : '#cd2c82',
				color           : '#FFF'
			}
		},
		contentText :
		{
			color    : '#292929',
			fontSize : '1rem'
		},
		contentTextSmall :
		{
			color    : '#292929',
			fontSize : '0.8rem'
		},
		inputLabel : {
			width         : '40%',
			minWidth      : '160px',
			fontSize      : '0.8rem',
			height        : '37px',
			lineHeight    : '37px',
			verticalAlign : 'middle'
		},
		inputLine : {
			display   : 'flex',
			minWidth  : '340px',
			marginTop : '10px'
		},
		inputText : {
			background   : '#FFF',
			padding      : '0.6rem 0.6rem',
			borderRadius : '0.2rem',
			color        : '#292929',
			textAlign    : 'left',
			border       : '1px solid #9E9E9E',
			'&:focus'    : {
				outline : 'none'
			},
			'&:disabled' : {
				backgroundColor : '#E8E8E8',
				color           : 'var(--text-color)'
			},
			'&::-ms-reveal' : {
				display : 'none'
			}
		},
		inputTextTicket : {
			width    : '70%',
			maxWidth : '240px'
		},
		inputCreditCardInfo : {
			width    : '100%',
			minWidth : '340px'
		},
		creditCardInput : {
			marginTop : '15px'
		},
		creditCardLabel : {
			fontSize : '0.8rem',
			color    : 'var(--text-color)'
		},
		agreeTermsLine : {
			marginTop      : '30px',
			width          : '340px',
			padding        : '5px 10px',
			display        : 'flex',
			justifyContent : 'center'
		},
		agreeTermsCheckBox : {
			width          : '30px',
			display        : 'flex',
			justifyContent : 'end'
		},
		agreeTermsText : {
			width         : '320px',
			paddingLeft   : '10px',
			fontSize      : '0.8rem',
			color         : '#292929',
			height        : '26px',
			lineHeight    : '26px',
			verticalAlign : 'middle'
		},
		errorTextArea : {
			marginTop      : '100px',
			width          : '100%',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'flex-start',
			alignItems     : 'center'
		},
		errorText : {
			color    : '#292929',
			fontSize : '1rem'
		},
		buttonSection : {
			width          : '100%',
			maxWidth       : '650px',
			marginTop      : '30px',
			display        : 'flex',
			justifyContent : 'space-around',
			[theme.breakpoints.down('sm')] :
			{
				justifyContent : 'space-between'
			}
		},
		backButton : {
			marginTop       : '15px',
			padding         : '4px 10px',
			backgroundColor : '#cd2c82',
			color           : 'white',
			minWidth        : '180px',
			borderRadius    : '10px',
			fontSize        : '1rem',
			'&:hover'       : {
				backgroundColor : '#D9388E'
			}
		},
		link :
		{
			color  : 'var(--submit-button-color)',
			cursor : 'pointer'
		},
		loadingWrapper :
		{
			width           : '100%',
			height          : 'calc( 100% - 40px)',
			display         : 'flex',
			paddingTop      : '250px',
			justifyContent  : 'center',
			alignItems      : 'flex-start',
			backgroundColor : 'rgba(255, 255, 255, 0.5)'
		},
		loadingIndicator :
		{
			color : 'var(--text-color)'
		},
		errorMsg : {
			width        : '100%',
			color        : 'red',
			fontSize     : '0.8rem',
			textAlign    : 'center',
			marginBottom : '10px'
		}
	});

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	},
	overrides :
	{
		MuiOutlinedInput : {
			input : {
				'&::-ms-reveal' : {
					display : 'none'
				}
			}
		},
		PrivateSwitchBase : {
			root : {
				padding : '1px'
			}
		}
	}
});

// home select input confirm complete

const AmazonPayComplete = ({
	classes
}) =>
{
	const intl = useIntl();
	const history = useHistory();

	const location = useLocation();

	const [ showErrorPage, setShowErrorPage ] = useState(false);

	const [ loading, setLoading ] = useState(true);

	const [ serviceParamKey, setServiceParamKey ] = useState('');

	const [ createdCode, setCreatedCode ] = useState('');

	const [ createdExpiryTimestamp, setCreatedExpiryTimestamp ] = useState(null);

	const [ createdExpiryTerm, setCreatedExpiryTerm ] = useState(
		{ afterPurchase: {}, afterUse: {} }
	);

	const [ createdMinutes, setCreatedMinutes ] = useState(null);

	const [ ticketEmail, setTicketEmail ] = useState('');

	const [ errorMsg, setErrorMsg ] = useState('');

	const completeTransaction = async (
		ticketOrderData,
		orderIdData
	) =>
	{
		const serviceTicketCreateForAmazonAPI = functions.httpsCallable('serviceTicketCreateForAmazon');

		try
		{
			const domain = getDomainWithoutSubdomain();

			const ticketCreate = await serviceTicketCreateForAmazonAPI({
				serviceAccountParamKey : ticketOrderData.serviceParamKey,
				tickets                : [
					{ ...ticketOrderData,
						orderId        : orderIdData,
						quantity       : 1,
						holesaleAmount : 0
					}
				],
				email            : ticketOrderData.email,
				phoneNumber      : ticketOrderData.phoneNumber,
				domain           : domain,
				additionalEmail1 : '',
				additionalEmail2 : '',
				buyer            : 'guest',
				salesChannel     : ISSUED_AT_GUEST_PAGE,
				paymentMethod    : PAYMENT_AMAZON_PAY,
				orderId          : orderIdData,
				amount           : ticketOrderData.amount,
				accessId         : ticketOrderData.accessId ? ticketOrderData.accessId : '',
				accessPass       : ticketOrderData.accessPass ? ticketOrderData.accessPass : ''
			});

			const ticketData = ticketCreate.data;

			if (ticketData.success && ticketData?.tickets.length > 0)
			{
				localStorage.removeItem('amazonPayOrderId');

				const createdTicket = ticketData.tickets[0];

				setCreatedCode(`${ticketOrderData.serviceParamKey.toUpperCase()}${createdTicket.ticketNumber}`);
				setCreatedMinutes(createdTicket.duration === -1 ? -1
					: createdTicket.duration ? createdTicket.duration/60 : 0);
				setCreatedExpiryTimestamp(createdTicket.expiryTimestamp);
				setCreatedExpiryTerm({
					afterPurchase : createdTicket.expiryTerm || {},
					afterUse      : createdTicket.expiryTermAfterUse || {}
				});

				setErrorMsg('');
				setLoading(false);
			}
			else
			{

				setErrorMsg(intl.formatMessage({
					id             : 'error.orderCanceled',
					defaultMessage : 'An error occurred and your order was canceled'
				}));
				setShowErrorPage(true);
				setLoading(false);

				return;
			}
		}
		catch (e)
		{
			setErrorMsg(intl.formatMessage({
				id             : 'error.error',
				defaultMessage : 'An error has occurred'
			}));
			setShowErrorPage(true);
			setLoading(false);

			return;
		}
	};

	useEffect(() =>
	{
		// index 0 is ? get strings after ?
		const paramsValue = location.search.substring(1);

		let paramsObj = {};

		if (paramsValue)
		{
			paramsObj = parseUrlParams({
				paramsValues : paramsValue,
				keys         : [ 'orderId', 'success' ]
			});
		}

		const storedOrderId = localStorage.getItem('amazonPayOrderId');

		if (paramsObj['success'] === 'true'
			&& paramsObj['orderId']
			&& storedOrderId === paramsObj['orderId']
		)
		{

			firestore.collection('serviceAmazonPayRecords')
				.doc(storedOrderId)
				.get()
				.then((doc) =>
				{
					if (doc.exists)
					{
						const orderData = doc.data();

						setServiceParamKey(orderData.serviceParamKey);

						setTicketEmail(orderData.email);

						completeTransaction({ ...orderData }, storedOrderId);

					}
					else
					{
						setShowErrorPage(true);
						setLoading(false);
					}
				});
		}
		else
		{
			setShowErrorPage(true);
			setLoading(false);
		}

	// eslint-disable-next-line
	}, [ location.search ]);

	const backToTop = () =>
	{
		const paramKeyValue =
			serviceParamKey ? serviceParamKey : DEFAULT_SERVICE_ACCOUNT_KEY;

		history.push({
			pathname : '/service',
			search   : `?id=${paramKeyValue}`
		});
	};

	return (
		<MuiThemeProvider theme={theme}>
			<Box className={classes.root}>
				<ServiceAppBar
					free={false}
					appBarHeight={40}
					disabled
				/>
				{ showErrorPage ?
					<Box className={classes.content}>
						<Box className={classes.contentArea}>
							{errorMsg &&
							<p className={classes.errorMsg}>{errorMsg}</p>
							}
							<Box className={classes.errorTextArea}>
								<Typography className={classes.errorText}>
									<FormattedMessage
										id='error.notIssue'
										defaultMessage='Cannot be issued'
									/>
								</Typography>
								<Button
									className={classes.backButton}
									onClick={backToTop}
								>
									<FormattedMessage
										id='label.back'
										defaultMessage='Back'
									/>
								</Button>
							</Box>
						</Box>
					</Box>
					: loading ?
						<Box className={classes.loadingWrapper}>
							<CircularProgress className={classes.loadingIndicator}/>
						</Box>
						:
						<Box className={classes.content}>
							<Box className={classes.contentArea}>
								{errorMsg &&
								<p className={classes.errorMsg}>{errorMsg}</p>
								}
								<PurchaseComplete
									serviceParamKey={serviceParamKey}
									ticketCode={createdCode}
									ticketExpiryTimestamp={createdExpiryTimestamp}
									ticketExpiryTerm={createdExpiryTerm}
									ticketMinutes={createdMinutes}
									ticketEmail={ticketEmail}
									free={false}
								/>
							</Box>
						</Box>
				}
			</Box>
		</MuiThemeProvider>
	);
};

AmazonPayComplete.propTypes =
{
	classes : PropTypes.object.isRequired
};

const makeMapStateToProps = () =>
{
	const mapStateToProps = () =>
	{
		return {
		};
	};

	return mapStateToProps;
};

const mapDispatchToProps = () =>
{
	return {
	};
};

export default withRoomContext(connect(
	makeMapStateToProps,
	mapDispatchToProps,
	null,
	{}
)(withStyles(styles)(AmazonPayComplete)));