import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';

import { Person } from '@material-ui/icons';

import TableTranslationHistory from './TableTranslationHistory';

const styles = () => ({
	translationContentContainer : {
		width           : '100%',
		height          : '100%',
		backgroundColor : '#929291',
		color           : '#FFF'
	},
	translationContent : {
		width   : '100%',
		height  : '100%',
		padding : '20px'
	},
	translationContentTitleContainer : {
		width          : '100%',
		gap            : '20px',
		display        : 'flex',
		justifyContent : 'center',
		alignItems     : 'center',
		flexDirection  : 'column',
		marginBottom   : '20px'
	},
	translationContentTitle : {
		fontSize   : '34px',
		fontWeight : 'bold',
		textAlign  : 'center'
	},
	userInfoContainer : {
		width          : '100%',
		display        : 'flex',
		justifyContent : 'center',
		alignItems     : 'center',
		fontSize       : '18px',
		gap            : '8px'
	},
	userInfoLabel : {
		color : '#ddff00'
	},
	'@media (max-width: 768px)' : {
		translationContentTitle : {
			fontSize : '20px'
		},
		userInfoContainer : {
			fontSize : '16px'
		},
		translationContent : {
			padding : '10px'
		},
		translationContentTitleContainer : {
			marginBottom : '10px'
		}
	}
});

const TranslationContent = ({ classes, calls, customerId }) =>
{
	const intl = useIntl();

	return (
		<div className={classes.translationContentContainer}>
			<div className={classes.translationContent}>
				<div className={classes.translationContentTitleContainer}>
					<h1 className={classes.translationContentTitle}>
						{intl.formatMessage({ id: 'connect.app.title' })}
					</h1>
					{ customerId &&
					<div className={classes.userInfoContainer}>
						<Person />
						<p>{customerId}</p>
						<p className={classes.userInfoLabel}>
							{intl.formatMessage({ id: 'connect.label.customerId' })}
						</p>
					</div>
					}
				</div>
				<TableTranslationHistory calls={calls} />
			</div>
		</div>
	);
};

export default withStyles(styles)(TranslationContent);
