import { NOT_IN_QUEUE, QUEUE_PROMOTED } from '../const';

const initialState =
{
	status          : NOT_IN_QUEUE,
	skillId         : '',
	length          : 0,
	order           : 0,
	waitMinutesMax  : 0,
	waitMinutesMin  : 0,
	personalCallKey : ''
};

const settings = (state = initialState, action) =>
{
	switch (action.type)
	{
		case 'SET_QUEUE_STATUS' : {
			return {
				...state,
				status : action.payload.status
			};
		}

		case 'SET_QUEUE_SKILL_ID' : {
			return {
				...state,
				skillId : action.payload.skillId
			};
		}

		case 'UPDATE_QUEUE_WAIT_STATE':
		{
			return {
				...state,
				length : action.payload.length,
				order  : action.payload.order
			};
		}

		case 'UPDATE_QUEUE_WAIT_MINUTES':
		{
			return {
				...state,
				waitMinutesMax : action.payload.max,
				waitMinutesMin : action.payload.min
			};
		}

		case 'PROMOTED':
		{
			return {
				...state,
				status          : QUEUE_PROMOTED,
				personalCallKey : action.payload.personalCallKey
			};
		}

		case 'SET_QUEUE_ERROR':
		{
			return {
				...state,
				error : action.payload.flag
			};
		}

		case 'RESET_QUEUE' :
		{
			return { ...initialState };
		}

		default:
			return state;
	}
};

export default settings;
