// dayjs
const dayjs = require('dayjs');
const timezone = require('dayjs/plugin/timezone');
const weekday = require('dayjs/plugin/weekday');
const utc = require('dayjs/plugin/utc');

const timeZone = 'Asia/Tokyo';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault(timeZone);
dayjs.extend(weekday);

export const calcSecondsFromTimestampForSkill = ({ from, to, btn }) =>
{
	let sumOfTimeInSeconds = 0;

	const fromDayObj = dayjs(from).tz(timeZone);
	const toDayObj = dayjs(to);

	let termInDays = toDayObj.diff(fromDayObj, 'day');

	let workingSecondsPerDay = 24*60*60; // 24 hours

	// 開始時間のタイムスタンプ
	let startOfTodayTimestamp = 0;

	// 時間指定あり
	if (btn.workTimeSetting && btn.openTime && btn.closeTime)
	{

		const openTimeAr = btn.openTime.split(':');

		const openTimeHour = Number(openTimeAr[0]);
		const openTimeMin = Number(openTimeAr[1]);

		const closeTimeAr = btn.closeTime.split(':');

		const closeTimeHour = Number(closeTimeAr[0]);
		const closeTimeMin = Number(closeTimeAr[1]);

		workingSecondsPerDay
			= (closeTimeHour - openTimeHour)*60*60 + (closeTimeMin -openTimeMin)*60;

		startOfTodayTimestamp = dayjs().tz(timeZone)
			.hour(openTimeHour)
			.minute(openTimeMin)
			.valueOf();
	}
	else
	{
		startOfTodayTimestamp = dayjs().tz(timeZone)
			.startOf('day')
			.valueOf();
	}

	if (to <= from)
	{
		return 0;
	}
	else if (to > startOfTodayTimestamp)
	{
		termInDays -= 1;

		const todaySeconds = (to - startOfTodayTimestamp)/1000;

		if (todaySeconds > 0)
		{
			sumOfTimeInSeconds += Math.round(todaySeconds);
		}
	}

	let targetDay = fromDayObj;

	for (let i = 0; i <= termInDays; i++)
	{
		// .day() => 0 = Sunday, 1 = Monday, ..., 6 = Saturday
		let dayOfWeek = targetDay.day() -1;

		// btn.holiday => 0 = Monday, ..., 6 = Sunday
		if (dayOfWeek === -1)
		{
			dayOfWeek = 6;
		}

		// 休みの曜日でなければ秒数を追加
		if (!btn.btnHoliday || btn.btnHoliday[dayOfWeek] !== 1)
		{
			sumOfTimeInSeconds += workingSecondsPerDay;
		}

		targetDay = targetDay.add(1, 'day');
	}

	return sumOfTimeInSeconds;

};

export const calcSecondsFromTimestampForDay = ({ dateString }) =>
{
	let sumOfTimeInSeconds = 0;

	const now = dayjs().tz(timeZone);

	const todayStr = now.format('YYYY-MM-DD');

	// 開始時間のタイムスタンプ
	const startOfDayTimestamp = now.startOf('day').valueOf();

	let endOfDayTimestamp = 0;

	if (dateString === todayStr)
	{
		endOfDayTimestamp = now.valueOf();
	}
	else
	{
		endOfDayTimestamp = now.endOf('day').valueOf();
	}

	sumOfTimeInSeconds += Math.round((endOfDayTimestamp - startOfDayTimestamp)/1000);

	if (sumOfTimeInSeconds < 0)
	{
		sumOfTimeInSeconds = 0;
	}

	return sumOfTimeInSeconds;

};

export const calcSecondsFromTimestampForMonth = ({ dateString }) =>
{
	let sumOfTimeInSeconds = 0;

	const startOfMonthObj = dayjs(`${dateString}-01`);

	const now = dayjs().tz(timeZone);

	const todayStr = now.format('YYYY-MM');

	const workingSecondsPerDay = 24*60*60; // 24 hours

	let endOfMonthObj = 0;

	let termInDays = 0;

	if (dateString === todayStr)
	{
		endOfMonthObj = now;
		// 今日の分は別で計算するので一日マイナス
		termInDays = endOfMonthObj.diff(startOfMonthObj, 'day') - 1;

		// 今日の分の計算
		const startOfDayTimestamp = dayjs().tz(timeZone)
			.startOf('day')
			.valueOf();
		// 今の時間
		const endOfDayTimestamp = dayjs().tz(timeZone)
			.valueOf();
		const todaySeconds = (endOfDayTimestamp - startOfDayTimestamp)/1000;

		if (todaySeconds > 0)
		{
			sumOfTimeInSeconds += Math.round(todaySeconds);
		}
	}
	else
	{
		endOfMonthObj = startOfMonthObj.endOf('month');
		termInDays = endOfMonthObj.diff(startOfMonthObj, 'day');
	}

	let targetDay = startOfMonthObj;

	for (let i = 0; i <= termInDays; i++)
	{

		sumOfTimeInSeconds += workingSecondsPerDay;

		targetDay = targetDay.add(1, 'day');
	}

	if (sumOfTimeInSeconds < 0)
	{
		sumOfTimeInSeconds = 0;
	}

	return sumOfTimeInSeconds;

};