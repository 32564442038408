// status
export const NOT_IN_QUEUE = 0;
export const QUEUE_WAIT = 1;
export const QUEUE_PROMOTED = 2;

export const WAITING_GUESTS_STATUS_CONNECTED = 1; // connected to call
export const WAITING_GUESTS_STATUS_DISCONNECT = 2; // disconnected

// checkDate
export const YEAR_MONTH = 1;
export const MONTH_DAY = 2;
