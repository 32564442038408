import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { ja } from 'date-fns/locale';

import 'date-fns';
import 'date-fns/locale/ja';

import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

const useStyles = makeStyles(() => ({
	root : {
		'& .MuiBox-root' : {
			cursor : 'pointer'
		},
		'& .MuiFormControl-marginNormal' : {
			display    : 'flex',
			alignItems : 'center',
			margin     : '0 auto',
			width      : 'fit-content'
		},
		'& .MuiInputBase-root' : {
			color           : 'var(--text-color-black)',
			fontSize        : '24px',
			padding         : '8px 10px',
			backgroundColor : 'transparent',
			width           : 'auto',
			cursor          : 'pointer',
			transition      : 'color 0.3s ease',
			'&:hover'       : {
				color : '#939292'
			},
			'&.Mui-focused' : {
				color : 'var(--text-color-black)'
			}
		},
		'& .MuiInputLabel-root' : {
			color           : '#939292',
			fontSize        : '14px',
			transform       : 'translate(14px, 10px) scale(1)',
			backgroundColor : '#FFF',
			padding         : '0 4px',
			zIndex          : 1,
			pointerEvents   : 'none'
		},
		'& .Mui-focused' : {
			color : 'var(--text-color-black)'
		},
		'& .MuiInputAdornment-root .MuiButtonBase-root' : {
			marginLeft : '-12px'
		},
		'& .MuiInputBase-input' : {
			textAlign  : 'center',
			cursor     : 'pointer',
			fontSize   : '16px',
			transition : 'color 0.3s ease',
			'&:hover'  : {
				color : '#939292'
			}
		}
	},
	toolbar : {
		'& .MuiTypography-h4' : {
			display : 'none'
		},
		'& .MuiPickersToolbar-toolbar' : {
			height          : '60px',
			backgroundColor : '#00b5a5'
		},
		'& .MuiPickersToolbarText-toolbarTxt' : {
			fontSize : '20px',
			color    : '#FFF'
		},
		'& .MuiPickersMonth-monthSelected' : {
			color   : '#00b5a5',
			opacity : 0.6
		}
	},
	datePickerWrapper : {
		display    : 'flex',
		alignItems : 'center',
		gap        : '8px'
	},
	chevronIcon : {
		color      : 'var(--text-color-black)',
		cursor     : 'pointer',
		fontSize   : '24px',
		fontWeight : 'bold',
		transition : 'color 0.3s ease',
		'&:hover'  : {
			color : '#939292'
		}
	},
	disabledIcon : {
		'&:hover' : {
			color : 'var(--text-color-black)'
		}
	}
}));

const CustomKeyboardDatePicker = withStyles({
	root : {
		'& .MuiInputBase-input' : {
			color : 'var(--text-color-black)'
		},
		'& .MuiInput-underline:before' : {
			borderBottom : 'none'
		},
		'& .MuiInput-underline:after' : {
			borderBottom : 'none'
		},
		'& .MuiIconButton-root' : {
			padding      : 0,
			borderBottom : 'none'
		},
		'& .MuiButtonBase-root' : {
			color : '#939292'
		},
		'&:hover .MuiInput-underline:before' : {
			borderBottom : 'none'
		}
	}
})(KeyboardDatePicker);

class JapaneseDateFnsUtils extends DateFnsUtils
{
	getMonthText(month)
	{
		return `${month + 1}月`;
	}

	getDatePickerHeaderText(date)
	{
		return `${date.getFullYear()}年${date.getMonth() + 1}月`;
	}

	getCalendarHeaderText(date)
	{
		return `${date.getFullYear()}年`;
	}
}

export default function DatePickerYearMonth({ dateYearMonth, setDateYearMonth })
{
	const classes = useStyles();
	const [ isOpen, setIsOpen ] = useState(false);

	const currentDate = new Date();

	const isCurrentMonth = (date) =>
	{
		return (
			date.getMonth() === currentDate.getMonth()
				&& date.getFullYear() === currentDate.getFullYear()
		);
	};

	const handleOpen = () =>
	{
		setIsOpen(true);
	};

	const handleClose = () =>
	{
		setIsOpen(false);
	};

	const handleDateChange = (date) =>
	{
		setDateYearMonth(date);
	};

	const handlePrevMonth = () =>
	{
		const newDate = new Date(dateYearMonth);

		newDate.setMonth(newDate.getMonth() - 1);
		setDateYearMonth(newDate);
	};

	const handleNextMonth = () =>
	{
		const newDate = new Date(dateYearMonth);

		newDate.setMonth(newDate.getMonth() + 1);
		if (newDate <= currentDate)
		{
			setDateYearMonth(newDate);
		}
	};

	return (
		<MuiPickersUtilsProvider utils={JapaneseDateFnsUtils} locale={ja}>
			<Box className={classes.root} container justifyContent='center'>
				<div className={classes.datePickerWrapper}>
					<ChevronLeftIcon className={classes.chevronIcon} onClick={handlePrevMonth} />
					<CustomKeyboardDatePicker
						className={classes.root}
						views={[ 'month' ]}
						variant='inline'
						format='yyyy 年 MM 月分'
						margin='normal'
						id='date-picker-inline'
						value={dateYearMonth}
						onChange={handleDateChange}
						onOpen={handleOpen}
						onClose={handleClose}
						open={isOpen}
						PopoverProps={{
							className : classes.toolbar
						}}
						KeyboardButtonProps={{
							style : {
								opacity : 0
							}
						}}
						InputProps={{
							readOnly     : true,
							onClick      : handleOpen,
							'aria-label' : 'change date'
						}}
						disableToolbar={false}
						maxDate={currentDate}
					/>
					<ChevronRightIcon
						className={`${classes.chevronIcon} ${isCurrentMonth(dateYearMonth) ? classes.disabledIcon : ''}`}
						onClick={handleNextMonth}
						style={{
							cursor  : isCurrentMonth(dateYearMonth) ? 'not-allowed' : 'pointer',
							opacity : isCurrentMonth(dateYearMonth) ? 0.5 : 1
						}}
					/>
				</div>
			</Box>
		</MuiPickersUtilsProvider>
	);
}

DatePickerYearMonth.propTypes = {
	dateYearMonth    : PropTypes.instanceOf(Date).isRequired,
	setDateYearMonth : PropTypes.func.isRequired
};
