// React
import React from 'react';

// Prop types
import PropTypes from 'prop-types';

// Mui styles
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';

// Mui core
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

import TimePicker from 'react-time-picker';

const styles = () =>
	({
		fieldset : {
			width          : '95%',
			height         : '11rem',
			borderColor    : 'var(--text-color)',
			borderRadius   : '0.3rem',
			borderWidth    : '1px',
			borderStyle    : 'solid',
			display        : 'flex',
			flexDirection  : 'column',
			alignItems     : 'center',
			justifyContent : 'center'
		},
		legend : {
			marginLeft : '4%',
			fontSize   : '0.9rem',
			color      : 'var(--text-color)',
			padding    : '0 3%'
		},
		day : {
			display                    : 'flex',
			flexDirection              : 'row',
			justifyContent             : 'center',
			padding                    : '2% 0',
			cursor                     : 'pointer',
			'& > div:not(:last-child)' : {
				marginRight : '3%'
			}
		},
		active : {
			width          : '1.8rem',
			height         : '1.8rem',
			background     : 'var(--text-color)',
			border         : '1px solid var(--text-color)',
			borderRadius   : '1.8rem',
			display        : 'flex',
			alignItems     : 'center',
			justifyContent : 'center',
			color          : 'white',
			fontSize       : '0.9rem'
		},
		inActive : {
			width          : '1.8rem',
			height         : '1.8rem',
			background     : 'white',
			border         : '1px solid var(--text-color)',
			borderRadius   : '1.8rem',
			display        : 'flex',
			alignItems     : 'center',
			justifyContent : 'center',
			color          : 'var(--text-color)',
			fontSize       : '0.9rem'
		},
		timeGroup : {
			display        : 'flex',
			flexDirection  : 'row',
			justifyContent : 'center',
			color          : 'var(--text-color)',
			fontSize       : '1rem',
			padding        : '2% 0'
		},
		time : {
			border         : 'none',
			display        : 'flex',
			alignItems     : 'center',
			justifyContent : 'center',
			fontSize       : '0.8rem',
			textAlign      : 'center',
			'& > div'      : {
				borderRadius : 5
			}
		},
		nationalHolidayInput :
		{
			padding : '2% 0',
			display : 'flex'
		},
		nationalHolidayInputTitle :
		{
			marginTop   : '3px',
			marginRight : '2rem',
			fontSize    : '0.9rem'
		},
		nationalHolidaySelect :
		{
			border       : '1px solid var(--text-color)',
			borderRadius : '0.3rem',
			padding      : '0.2rem 0.3rem',
			color        : 'var(--text-color)',
			fontSize     : '0.8rem',
			minWidth     : '8rem'
		}
	});

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	},
	overrides : {
		MuiSelect : {
			select : {
				'&:focus' : {
					borderRadius : '0.4rem'
				}
			}
		},
		MuiTypography : {
			root : {
				color    : 'var(--text-color)',
				fontSize : '0.8rem'
			},
			body1 : {
				color    : 'var(--text-color)',
				fontSize : '0.8rem'
			}
		}
	}
});

const week = [ '月', '火', '水', '木', '金', '土', '日' ];

const WorkTimeSetting = ({
	classes,
	holiday,
	handleChangeHolidayCallback,
	openTime,
	setOpenTime,
	closeTime,
	setCloseTime,
	nationalHoliday,
	handleChangeNationalHolidayCallback
}) =>
{

	return (
		<MuiThemeProvider theme={theme}>
			<fieldset className={classes.fieldset}>
				<legend className={classes.legend}>システム稼働時間</legend>
				<Box className={classes.day}>
					{ week.map((day, index) =>
					{
						return (
							<Box key={index}>
								<Box
									className={holiday[index] === 1 ?
										classes.active : classes.inActive}
									onClick={() => handleChangeHolidayCallback(index)}
								>
									{day}
								</Box>
							</Box>
						);
					})}
				</Box>
				<Box className={classes.timeGroup}>
					<TimePicker
						className={classes.time}
						onChange={setOpenTime}
						disableClock
						value={openTime}
					/>
					<Box ml={1} mr={1}>～</Box>
					<TimePicker
						className={classes.time}
						onChange={setCloseTime}
						disableClock
						value={closeTime}
					/>
				</Box>

				<Box className={classes.nationalHolidayInput}>
					<Typography className={classes.nationalHolidayInputTitle}>
						祝日
					</Typography>
					<Select
						classes={{ root: classes.nationalHolidaySelect }}
						value={nationalHoliday}
						onChange={(e) => handleChangeNationalHolidayCallback(e)}
						disableUnderline
					>
						<MenuItem value={0}>休みなし</MenuItem>
						<MenuItem value={1}>祝日休み</MenuItem>
						<MenuItem value={2}>祝日休み+日曜振替</MenuItem>
						<MenuItem value={3}>祝日休み+土日振替</MenuItem>
					</Select>

				</Box>
			</fieldset>
		</MuiThemeProvider>
	);
};

WorkTimeSetting.propTypes =
{
	classes                             : PropTypes.object.isRequired,
	holiday                             : PropTypes.object.isRequired,
	handleChangeHolidayCallback         : PropTypes.func.isRequired,
	openTime                            : PropTypes.string.isRequired,
	setOpenTime                         : PropTypes.func.isRequired,
	closeTime                           : PropTypes.string.isRequired,
	setCloseTime                        : PropTypes.func.isRequired,
	nationalHoliday                     : PropTypes.number.isRequired,
	handleChangeNationalHolidayCallback : PropTypes.func.isRequired
};

export default withStyles(styles)(WorkTimeSetting);
