// develop
export const ENV_DEVELOP = 'development';
// staging
export const ENV_STAGING = 'staging';
// production
export const ENV_PRODUCTION = 'production';

/**
 * networkVersion アプリバージョン
 */
// apiIfVer = networkVersion
export const SERVER_VERSION = '1.0.0';
// webVer = アプリバージョン
export const APP_VERSION = '1.0.0';

// 未対応
export const RECIPROCAL = '1';
// 対応済
export const NONRECIPROCAL = '2';

// 担当者
export const MANAGER = 'manager';

// 対応者
export const MEMBER = 'member';

// ROOM STATUS
export const ROOM_OFFLINE = 0;
export const ROOM_ONLINE = 1; //
export const ROOM_AFK = 2;
export const ROOM_CALLING = 3; //
export const ROOM_TALKING = 4;
export const ROOM_MEMO_EDIT = 5;
export const ROOM_MEETING = 6;
export const ROOM_SUPPORT = 7;
export const ROOM_APPOINTMENT = 8; //

// MEMO STATUS
export const MEMO_TEMP = 0;
export const MEMO_CONFIRM = 1;
export const MEMO_TODO_OPEN = 2;
export const MEMO_TODO_CLOSE = 3;
export const MEMO_TODO_REMOVED = 4;

// WINDWOS SIZE
export const FULLHD_HOST_HEADER = 85;
export const FULLHD_HOST_CONTENT_WIDTH = 1294;
export const FULLHD_HOST_CONTENT_HEIGHT = 823;
export const FULLHD_HOST_INNER_CONTENT_WIDTH = 714;
export const FULLHD_HOST_INNER_CONTENT_HEIGHT = 714;
export const FULLHD_HOST_INNER_CONTENT_SIDE_WIDTH = 250;
export const FULLHD_HOST_SELF_WIDTH = 250;

export const FULLHD_GUEST_CONTENT_WIDTH = 823;
export const FULLHD_GUEST_CONTENT_HEIGHT = 823;
export const FULLHD_GUEST_INNER_CONTENT_WIDTH = 760;
export const FULLHD_GUEST_INNER_CONTENT_HEIGHT = 760;
export const FULLHD_GUEST_SELF_WIDTH = 250;

export const FULLHD_NAVI_HEIGHT = 121;

// CALL STATUS
export const CALL_STATUS_CATCH = 0;
export const CALL_STATUS_STOP = 1;
export const CALL_STATUS_NO_REPLY = 2;
export const CALL_STATUS_NO_ONE_AVAILABLE = 3;
export const CALL_STATUS_FINISHED = 4;

// PLANS
export const PLANS = [
	{ id: 0, name: 'スタートアッププラン' },
	{ id: 1, name: 'ビジネスプラン' },
	{ id: 2, name: 'プロフェッショナルプラン' }
];

// ボタン表示位置
export const BTN_POSTIONS = [
	{ id: 0, name: '左' },
	{ id: 1, name: '中間' },
	{ id: 2, name: '右' }
];

// host order
export const HOST_ORDERS = {
	owner     : 0,
	admin     : 1,
	sv1       : 2,
	sv2       : 3,
	operator1 : 4,
	operator2 : 5
};

// host login
export const NO_HOST_LOGIN = 0;
export const HOST_OPERATOR_LOGIN = 1;
export const HOST_ADMIN_LOGIN = 2;

// monitoring
export const MONITORING_OFF = 0;
export const MONITORING_JOINED = 1;
export const MONITORING_JOINED_FROM_START = 2;
export const MONITORING_WAITING = 3;
export const MONITORING_ROOM_OFFLINE = 4; // モニタリング終了処理を実行し、MONITORING_OFFに移行

// screen sharing
export const SHARE_REGULAR_SIZE = 1;
export const SHARE_SCREEN_FULL_SIZE = 2;
export const SHARE_WINDOW_FULL_SIZE = 3;

// chat
export const CHAT_TEXT = 1;
export const CHAT_ICONS = 2;
export const CHAT_FILE = 3;
export const CHAT_FILE_MONITORING = 4;
export const CHAT_COMMENT = 5;

// portal security
export const PORTAL_OPEN = 0;
export const PORTAL_SECURITY_KEY = 1;
export const PORTAL_LOGIN = 2;

// personalCall
export const NOT_PERSONAL_CALL = 0;
export const PERSONAL_CALL_TARGET_FIRST = 1;
export const PERSONAL_CALL_TARGET = 2;
export const PERSONAL_CALL_NOT_TARGET = 3;
export const PERSONAL_CALL_INVALID_URL = 4;

export const CONSUMPTION_TAX = 0.1;