import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { ChevronLeft, Home } from '@material-ui/icons';

// locale
import { FormattedMessage } from 'react-intl';

import Logo from '../../../../images/connect/zeus_logo.png';

const styles = () => ({
	headerContainer : {
		width           : '100%',
		height          : '80px',
		backgroundColor : 'black'
	},
	contentHeaderContainer : {
		display    : 'flex',
		alignItems : 'center',
		gap        : '50px',
		padding    : '0 20px',
		height     : '100%',
		color      : '#FFF'
	},
	logo : {
		width     : '80px',
		height    : '80px',
		objectFit : 'contain',
		cursor    : 'pointer'
	},
	navigationContainer : {
		display        : 'flex',
		justifyContent : 'space-between',
		alignItems     : 'center'
	},
	navigationItem : {
		margin   : '0 10px',
		cursor   : 'pointer',
		fontSize : '16px'
	},
	backButton : {
		display : 'none'
	},
	homeButton : {
		display : 'none'
	},
	'@media (max-width: 720px)' : {
		contentHeaderContainer : {
			padding        : '0 10px',
			justifyContent : 'space-between'
		},
		navigationContainer : {
			display : 'none'
		},
		backButton : {
			display         : 'block',
			border          : 'none',
			backgroundColor : 'none'
		},
		backIcon : {
			color  : '#FFF',
			width  : '36px',
			height : '36px'
		},
		homeButton : {
			display : 'block'
		},
		homeIcon : {
			color  : '#FFF',
			width  : '36px',
			height : '36px'
		}
	}
});

const Header = ({ classes, setPage }) =>
{
	return (
		<div className={classes.headerContainer}>
			<div className={classes.contentHeaderContainer}>
				<div className={classes.backButton}>
					<ChevronLeft className={classes.backIcon} />
				</div>
				<img onClick={() => setPage('landing')} src={Logo} alt='logo' className={classes.logo} />
				<div className={classes.navigationContainer}>
					<div className={classes.navigationItem} onClick={() => setPage('landing')}>
						<FormattedMessage
							id='connect.home'
							defaultMessage='Home'
						/>
					</div>
					<div className={classes.navigationItem} onClick={() => setPage('history')}>
						<FormattedMessage
							id='connect.TranslationHistory'
							defaultMessage='Translation History'
						/>
					</div>
				</div>
				<Link to='/' className={classes.homeButton}>
					<Home className={classes.homeIcon} onClick={() => setPage('landing')}/>
				</Link>
			</div>
		</div>
	);
};

export default withStyles(styles)(Header);
