import domready from 'domready';
import React, { Suspense } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import isElectron from 'is-electron';

import { createIntl, createIntlCache } from 'react-intl';
import { IntlProvider } from 'react-intl-redux';

import { Route, HashRouter, BrowserRouter, Switch } from 'react-router-dom';
import randomString from 'random-string';
import Logger from './Logger';
import debug from 'debug';
import RoomClient from './RoomClient';
import RoomContext from './RoomContext';
import deviceInfo from './deviceInfo';
import * as meActions from './actions/meActions';
// import UnsupportedBrowser from './components/UnsupportedBrowser';
// import LoginDialog from './components/SecondDoor/Host/LoginDialog';
import LoadingView from './components/Loader/LoadingView';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { persistor, store } from './store';
import { SnackbarProvider } from 'notistack';
import * as serviceWorker from './serviceWorker';
import { LazyPreload } from './components/Loader/LazyPreload';
// import { detectDevice } from 'mediasoup-client';
import AdminHome from './components/SecondDoor/Admin/AdminHome';
import GuestLobby from './components/SecondDoor/Guest/GuestLobby';
import SetNewPasswordPage from './components/SecondDoor/Password/SetNewPasswordPage';
import PortalHome from './components/SecondDoor/Portal/PortalHome';
import ServiceHome from './components/SecondDoor/Service/ServiceCall/ServiceHome';
import ConnectHome from './components/SecondDoor/Service/ConnectCall/ConnectHome';
import ServiceAdmin from './components/SecondDoor/Service/ServiceManage/ServiceAdmin';
import ConnectAdmin from './components/SecondDoor/Service/ConnectManage/ConnectAdmin';
import ServiceGuestFreeTicket from './components/SecondDoor/Service/ServiceCall/ServiceGuestFreeTicket';
import ServiceGuestPurchase from './components/SecondDoor/Service/ServiceCall/ServiceGuestPurchase';
import AmazonPayReview from './components/SecondDoor/Service/ServiceCall/AmazonPayReview';
import AmazonPayComplete from './components/SecondDoor/Service/ServiceCall/AmazonPayComplete';
import ServiceResendTicket from './components/SecondDoor/Service/ResendTicket/ServiceResendTicket';

import { recorder } from './BrowserRecorder';

import './index.css';

const App = LazyPreload(() => import(/* webpackChunkName: "app" */ './components/App'));

const cache = createIntlCache();

/*
const supportedBrowsers={
	'windows' : {
		'internet explorer' : '>12',
		'microsoft edge'    : '>18'
	},
	'safari'                       : '>12',
	'firefox'                      : '>=60',
	'chrome'                       : '>=74',
	'chromium'                     : '>=74',
	'opera'                        : '>=62',
	'samsung internet for android' : '>=11.1.1.52'
};
*/

const locale = navigator.language;

const intl = createIntl({
	locale,
	defaultLocale : 'ja',
	messages      : null
}, cache);

recorder.intl = intl;

if (process.env.REACT_APP_DEBUG === '*' || process.env.NODE_ENV !== 'production')
{
	debug.enable('* -engine* -socket* -RIE* *WARN* *ERROR*');
}

const logger = new Logger();

let roomClient;

RoomClient.init({ store });

const theme = createTheme(window.config.theme);

let Router;

if (isElectron())
	Router = HashRouter;
else
	Router = BrowserRouter;

domready(() =>
{
	logger.debug('DOM ready');

	run();
});

function run()
{
	logger.debug('run() [environment:%s]', process.env.NODE_ENV);

	const randomStringFromTimestamp= Number(new Date()).toString(36)
		.slice(-8);

	const peerId = randomString({ length: 8 }).toLowerCase() + randomStringFromTimestamp;

	const urlParser = new URL(window.location);
	const parameters = urlParser.searchParams;

	const accessCode = parameters.get('code');
	const produce = parameters.get('produce') !== 'false';
	const forceTcp = parameters.get('forceTcp') === 'true';
	const displayName = parameters.get('displayName');

	const muted = parameters.get('muted') === 'true';

	const { pathname } = window.location;

	let basePath = pathname.substring(0, pathname.lastIndexOf('/'));

	if (!basePath)
		basePath = '/';

	// Get current device.
	const { device, userAgent }= deviceInfo();

	/*
	let unsupportedBrowser = false;

	let webrtcUnavailable = false;

	if (detectDevice() === undefined)
	{
		logger.error('Your browser is not supported [deviceInfo:"%o"]', device);

		unsupportedBrowser = true;
	}
	else if (
		navigator.mediaDevices === undefined ||
		navigator.mediaDevices.getUserMedia === undefined ||
		window.RTCPeerConnection === undefined
	)
	{
		logger.error('Your browser is not supported [deviceInfo:"%o"]', device);

		webrtcUnavailable = true;
	}
	else if (
		!device.bowser.satisfies(
			window.config.supportedBrowsers || supportedBrowsers
		)
	)
	{
		logger.error(
			'Your browser is not supported [deviceInfo:"%o"]',
			device
		);

		unsupportedBrowser = true;
	}
	else
	{
		logger.debug('Your browser is supported [deviceInfo:"%o"]', device);
	}

	if (unsupportedBrowser || webrtcUnavailable)
	{
		render(
			<MuiThemeProvider theme={theme}>
				<IntlProvider value={intl}>
					<UnsupportedBrowser
						webrtcUnavailable={webrtcUnavailable}
						platform={device.platform}
					/>
				</IntlProvider>
			</MuiThemeProvider>,
			document.getElementById('SecondDoor')
		);

		return;
	}
	*/

	store.dispatch(
		meActions.setMe({
			peerId,
			loginEnabled : window.config.loginEnabled
		})
	);

	store.dispatch(
		meActions.setUserAgent(userAgent)
	);

	roomClient = new RoomClient(
		{
			peerId,
			accessCode,
			device,
			produce,
			forceTcp,
			displayName,
			muted,
			basePath
		});

	// roomClient.setLocale('ja');
	global.CLIENT = roomClient;

	render(
		<Provider store={store}>
			<MuiThemeProvider theme={theme}>
				<IntlProvider value={intl}>
					<PersistGate loading={<LoadingView />} persistor={persistor}>
						<RoomContext.Provider value={roomClient}>
							<SnackbarProvider>
								<Router basename={basePath}>
									<Suspense fallback={<LoadingView />}>
										<Router>
											<Switch>
												<Route exact path='/admin' component={AdminHome} />
												<Route exact path='/login' component={App} />
												<Route exact path='/lobby' component={GuestLobby} />
												<Route exact path='/host' component={App} />
												<Route exact path='/p' component={SetNewPasswordPage} />
												<Route exact path='/plp' component={SetNewPasswordPage} />
												<Route exact path='/sap' component={SetNewPasswordPage} />
												<Route exact path='/sgp' component={SetNewPasswordPage} />
												<Route exact path='/portal' component={PortalHome} />
												<Route exact path='/service' component={ServiceHome} />
												<Route exact path='/connect' component={ConnectHome} />
												<Route exact path='/manage' component={ServiceAdmin} />
												<Route exact path='/urls' component={ConnectAdmin} />
												<Route exact path='/free' component={ServiceGuestFreeTicket}/>
												<Route exact path='/purchase' component={ServiceGuestPurchase}/>
												<Route exact path='/amazonReview' component={AmazonPayReview}/>
												<Route exact path='/amazonComplete' component={AmazonPayComplete}/>
												<Route exact path='/resend' component={ServiceResendTicket}/>
												<Route path='/' component={App} />
												<Route path='/:id' component={App} />
											</Switch>
										</Router>
									</Suspense>
								</Router>
							</SnackbarProvider>
						</RoomContext.Provider>
					</PersistGate>
				</IntlProvider>
			</MuiThemeProvider>
		</Provider>,
		document.getElementById('SecondDoor')
	);
}

serviceWorker.unregister();
