import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { withRoomContext } from '../../../../RoomContext';

// material ui
import { Button, Typography } from '@material-ui/core';

// components
import ConnectPurchaseDialog from './ConnectPurchaseDialog';

const styles = () =>
	({
		root : {
			display       : 'flex',
			flexDirection : 'column',
			alignItems    : 'end'
		},
		keys : {
			fontSize  : '0.8rem',
			color     : 'var(--text-color)',
			textAlign : 'end',
			margin    : '0',
			padding   : '0 2px'
		},
		button : {
			padding         : '3px 20px',
			fontSize        : '1rem',
			backgroundColor : '#8eba14',
			color           : 'white',
			maxWidth        : '260px',
			'&:hover'       : {
				filter : 'saturate(105%) brightness(105%)'
			}
		},
		hiddenInput :
		{
			display : 'none'
		}
	});

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	}
});

const ConnectPurchaseButton = ({
	classes,
	paramKey,
	priceList,
	priceListTerm,
	email,
	defaultExpiryTerm,
	accountId
}) =>
{
	const hiddenFileInput = useRef();

	const [ fileArray, setFileArray ] = useState([]);

	const [ fileResult, setFileResult ] = useState([]);

	const [ priceListPurchase, setPriceListPurchase ] = useState([]);

	const [ showConnectPurchaseDialog, setShowConnectPurchaseDialog ] = useState(false);

	const startPurchaseByCsv = () =>
	{
		hiddenFileInput.current.click();
	};

	const csvFileToArray = (string) =>
	{
		const csvHeader = string.slice(0, string.indexOf('\n')).split(',');
		const csvRows = string.slice(string.indexOf('\n') + 1).split('\n');

		const array = csvRows.map((row) =>
		{
			const values = row.split(',');

			const obj = {};

			csvHeader.forEach((header, index) =>
			{
				let headerStr = '';

				if (header)
				{
					headerStr = header.trim();
				}

				let valueVal = values[index] || '';

				if (headerStr === 'id' && values[index])
				{
					valueVal = values[index].trim();
				}
				// remove space
				obj[headerStr] = valueVal;
			});

			return obj;
		});

		setFileArray(array);
	};

	const handleCSV = (event) =>
	{
		event.preventDefault();

		const fileUploaded = event.target.files[0];

		const fileReader = new FileReader();

		fileReader.onload = async function(e)
		{
			const text = e.target.result;

			csvFileToArray(text);

		};

		try
		{
			fileReader.readAsText(fileUploaded);
		}
		catch (e)
		{
			// eslint-disable-next-line no-console
			console.log('error:', e);
		}
	};

	useEffect(() =>
	{
		if (fileArray && fileArray.length > 0)
		{
			const purchaseList = [];

			const resultList = [];

			priceList.forEach((item) =>
			{
				purchaseList.push({ ...item, quantity: 0, expiryTerm: defaultExpiryTerm });
			});

			priceListTerm.forEach((item) =>
			{
				purchaseList.push({ ...item, quantity: 0 });
			});

			fileArray.forEach((fileItem) =>
			{
				const customerId = fileItem['id'];

				const entryKey = fileItem['entry_key'];

				const emailValue = fileItem['email'] || email;

				if (entryKey.slice(0, 4) === paramKey.toUpperCase())
				{
					const sameKeyIndex = purchaseList.findIndex(
						(row) => row.entryKey === entryKey);

					if (sameKeyIndex !== -1)
					{
						// quantity欄がない場合は1とみなす
						if (fileItem.quantity === undefined || (typeof fileItem.quantity === 'string' && fileItem.quantity.trim() === ''))
						{
							purchaseList[sameKeyIndex].quantity += 1;
							resultList.push({
								status     : 'success',
								entryKey   : entryKey,
								customerId : customerId,
								email      : emailValue,
								quantity   : 1
							});
						}
						else
						{
							try
							{
								const quantityValue = Number(fileItem.quantity);

								if (quantityValue)
								{
									purchaseList[sameKeyIndex].quantity += quantityValue;
									resultList.push({
										status     : 'success',
										entryKey   : entryKey,
										customerId : customerId,
										email      : emailValue,
										quantity   : quantityValue
									});
								}
								else
								{
									resultList.push({
										status     : 'noQuantity',
										entryKey   : entryKey,
										customerId : customerId,
										email      : emailValue,
										quantity   : fileItem.quantity
									});
								}
							}
							catch
							{
								resultList.push({
									status     : 'noQuantity',
									entryKey   : entryKey,
									customerId : customerId,
									quantity   : fileItem.quantity
								});
							}
						}
					}
					else
					{
						resultList.push({
							status     : 'keyNotFound',
							entryKey   : entryKey,
							customerId : customerId,
							quantity   : fileItem.quantity
						});
					}
				}
				else
				{
					resultList.push({
						status     : 'wrongAccount',
						entryKey   : entryKey,
						customerId : customerId,
						quantity   : fileItem.quantity
					});
				}
			});

			setFileResult(resultList);

			setPriceListPurchase(purchaseList);

			setShowConnectPurchaseDialog(true);
		}
	}, [ fileArray, priceList, priceListTerm, paramKey, defaultExpiryTerm, email ]);

	return (
		<MuiThemeProvider theme={theme}>
			<div className={classes.root}>
				<Typography className={classes.keys}>
					id:顧客側識別番号、entry_key:エントリーキー、email:メールアドレス、quantity:数量
				</Typography>
				<Button
					variant='contained'
					className={classes.button}
					onClick={startPurchaseByCsv}
				>
					csvファイルから購入
				</Button>
				<input
					type='file'
					accept='.csv, text/csv, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values'
					ref={hiddenFileInput}
					onChange={handleCSV}
					className={classes.hiddenInput}
				/>
				<ConnectPurchaseDialog
					show={showConnectPurchaseDialog}
					buyer={'system-admin'}
					closeMethod={
						() => setShowConnectPurchaseDialog(false)
					}
					accountId={accountId}
					paramKey={paramKey}
					fileResult={fileResult}
					priceListPurchase={priceListPurchase}
				/>
			</div>
		</MuiThemeProvider>
	);
};

ConnectPurchaseButton.propTypes =
{
	classes           : PropTypes.object.isRequired,
	paramKey          : PropTypes.string.isRequired,
	email             : PropTypes.string.isRequired,
	defaultExpiryTerm : PropTypes.object.isRequired,
	priceList         : PropTypes.array.isRequired,
	priceListTerm     : PropTypes.array.isRequired,
	accountId         : PropTypes.string.isRequired
};

const mapStateToProps = () =>
{
	return {};
};

const mapDispatchToProps = () =>
{
	return {};
};

export default withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{}
)(withStyles(styles)(ConnectPurchaseButton)));
