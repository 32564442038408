import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withRoomContext } from '../../../../RoomContext';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';

// Message
import { FormattedMessage } from 'react-intl';

const styles = () =>
	({
		closeBtn :
		{
			background : '#cd2c82',
			border     : '1px solid #cd2c82',
			color      : '#fff',
			padding    : '4px 30px',
			'&:hover' :
			{
				backgroundColor : '#fff',
				color           : '#cd2c82',
				opacity         : 0.8
			}
		},
		contentTitle : {
			color : '#292929'
		},
		contentText : {
			whiteSpace : 'pre-wrap',
			fontSize   : '0.9rem',
			color      : '#292929',
			padding    : '15px 0'
		},
		dialogButtons :
		{
			width          : '100%',
			display        : 'flex',
			justifyContent : 'center'
		}
	});

const NotificationDialog = ({
	classes,
	open,
	handleClose,
	notification
}) =>
{
	return (
		<Dialog
			fullWidth
			open={open}
			onClose={handleClose}
			scroll={'paper'}
			aria-labelledby='scroll-dialog-title'
			aria-describedby='scroll-dialog-description'
		>
			<DialogTitle
				className={classes.contentTitle}
			>
				{notification.title}
			</DialogTitle>
			<DialogContent dividers>
				<pre
					id='scroll-dialog-description'
					tabIndex={-1}
					className={classes.contentText}
				>
					{notification?.content}
				</pre>
			</DialogContent>
			<DialogActions>
				<Box className={classes.dialogButtons}>
					<Button
						className={classes.closeBtn}
						onClick={handleClose}
						color='primary'
					>
						<FormattedMessage
							id='label.close'
							defaultMessage='Close'
						/>
					</Button>
				</Box>
			</DialogActions>
		</Dialog>
	);
};

NotificationDialog.propTypes =
{
	classes      : PropTypes.object.isRequired,
	open         : PropTypes.bool.isRequired,
	handleClose  : PropTypes.func.isRequired,
	notification : PropTypes.object
};

const mapStateToProps = () =>
{
	return {};
};

const mapDispatchToProps = () =>
{
	return {};
};

export default withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{}
)(withStyles(styles)(NotificationDialog)));
