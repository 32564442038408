const constants = {
	heightTableRow      : 23,
	backgroundColorHead : '#929291',
	tableRowStyles      : {
		container : {
			padding : '10px'
		},
		cellHead : {
			borderRight : '1px solid #c3c3c3'
		},
		head : {
			borderRadius : '5px'
		},
		table : {
			marginTop    : '10px',
			minWidth     : 700,
			boxShadow    : '0px 4px 6px -2px #c3c3c3',
			borderRadius : '8px'
		},
		lastRow : {
			'& tr' : {
				marginTop : '10px'
			},
			'& td' : {
				borderBottom : '2px solid #cacaca'
			}
		},
		categoryCell : {
			borderBottom : '2px solid #cacaca'
		}
	}
};

export default constants;
