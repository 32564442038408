import React, { useState, useEffect, useCallback, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withRoomContext } from '../../../../RoomContext';
import Button from '@material-ui/core/Button';

// components
import MonthlySalesAmount from '../../Common/MonthlySalesAmount';

// material Ui
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core//Checkbox';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import SearchIcon from '@material-ui/icons/Search';
import CachedIcon from '@material-ui/icons/Cached';

// firestore
import { firestore } from '../../../../lib/firebase';

// dayjs
import dayjs from 'dayjs';

import { showNumberWithComma } from '../../../../utils';

// const 
import { DEFAULT_SERVICE_ACCOUNT_KEY, ISSUED_AT_ADMIN_PAGE, ISSUED_AT_GUEST_PAGE, CONSUMPTION_TAX } from '../../../../const';

// dayjsの タイムゾーンの設定
dayjs.extend(require('dayjs/plugin/timezone'));
dayjs.extend(require('dayjs/plugin/utc'));
dayjs.extend(require('dayjs/plugin/objectSupport'));
dayjs.tz.setDefault('Asia/Tokyo');

const styles = (theme) =>
	({
		root :
		{
			width    : '100%',
			height   : '100%',
			padding  : '0 10px',
			position : 'relative',
			color    : 'var(--text-color)',
			fontSize : '0.9rem'
		},
		homeContentSection : {
			minHeight : '400px',
			marginTop : '20px'
		},
		homeContentBox : {
			width        : '100%',
			height       : '50%',
			marginTop    : '30px',
			padding      : '15px',
			borderRadius : '10px',
			boxShadow    : '1px 1px 3px #bbb inset'
		},
		homeContentBoxSold : {
			backgroundColor : '#EBF6F4'
		},
		homeContentBoxPurchased : {
			backgroundColor : '#F9DBE1'
		},
		detailButton : {
			marginTop       : '15px',
			backgroundColor : '#cd2c82',
			color           : '#FFF',
			padding         : '2px 30px',
			'&:hover' :
			{
				backgroundColor : '#D9388E'
			}
		},
		listHeaderSection : {
			marginTop : '10px'
		},
		listActionSection : {
			marginTop : '10px'
		},
		listTableSection : {
			marginTop : '10px'
		},
		title : {
			color      : '#292929',
			fontSize   : '1.1rem',
			fontWeight : 'bold'
		},
		scrollBox : {
			overflowY : 'auto',
			maxHeight : 'calc(100vh - 410px)'
		},
		scrollContent : {
			overflowX    : 'auto',
			height       : '100%',
			paddingRight : '5px'
		},
		table : {
			overflowX : 'hidden'
		},
		tableNavigation :
		{
			margin         : '15px 0',
			display        : 'flex',
			justifyContent : 'start',
			fontSize       : '1rem'
		},
		dateNavigation : {
			display        : 'flex',
			justifyContent : 'flex-start',
			color          : '#292929'
		},
		dateNavigationButtons : {
			display        : 'flex',
			justifyContent : 'space-between',
			marginLeft     : '10px'
		},
		dateNavigationButton : {
			height   : '23px',
			padding  : '0 10px',
			fontSize : '0.9rem',
			color    : '#292929',
			border   : '1px solid #292929'
		},
		dateNavigationCurrent : {
			width     : '150px',
			textAlign : 'center'
		},
		exportButtonWrapper : {
			marginLeft : '40px'
		},
		totalAmount : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'center',
			marginBottom   : '30px'
		},
		totalAmountBox : {
			width           : '90%',
			maxWidth        : '700px',
			display         : 'flex',
			justifyContent  : 'space-between',
			backgroundColor : '#292929',
			borderRadius    : '15px',
			padding         : '10px 20px'
		},
		totalAmountTitle : {
			fontSize : '1rem',
			color    : '#FFF'
		},
		totalAmountValue : {
			fontSize : '1.1rem',
			color    : '#FFF'
		},
		exportButton : {
			marginLeft : '15px',
			height     : '23px',
			padding    : '0 10px',
			fontSize   : '0.9rem',
			color      : '#292929',
			border     : '1px solid #292929'
		},
		tableHeader :
		{
			display : 'flex',
			width   : '100%'
		},
		search : {
			width          : '100%',
			display        : 'flex',
			flexDirection  : 'row',
			padding        : '0 0 0 0',
			justifyContent : 'center',
			[theme.breakpoints.down('xs')] :
			{
				alignItems : 'center'
			}
		},
		searchArea : {
			width         : '50%',
			display       : 'flex',
			flexDirection : 'column'
		},
		searchGroup : {
			color          : '#878686',
			fontSize       : '0.9rem',
			display        : 'flex',
			alignItems     : 'center',
			justifyContent : 'left',
			marginBottom   : '0.8rem',
			[theme.breakpoints.down('xs')] :
			{
				padding : '0.5rem 0'
			}
		},
		searchTitle : {
			width       : '6rem',
			textAlign   : 'left',
			marginRight : '0.9rem'
		},
		smallTitle : {
			fontSize : '0.8rem'
		},
		searchGroupSearch : {
			width          : 'calc(70% + 6.9rem)',
			color          : '#878686',
			fontSize       : '0.9rem',
			display        : 'flex',
			alignItems     : 'start',
			justifyContent : 'end',
			marginRight    : '16px',
			[theme.breakpoints.down('xs')] :
			{
				padding : '0.5rem 0'
			}
		},
		searchInput : {
			background   : '#999898',
			padding      : '0.5rem 0.7rem',
			borderRadius : '0.5rem',
			color        : 'white',
			textAlign    : 'left',
			border       : 'none',
			width        : '70%',
			marginRight  : '1rem',
			'&:focus'    : {
				outline : 'none'
			}
		},
		separator : {
			color    : 'var(--text-color)',
			margin   : '0 0.5rem',
			fontSize : '1rem'
		},
		searchBtn : {
			width           : '6rem',
			borderRadius    : '0.5rem',
			fontSize        : '0.8rem',
			backgroundColor : '#9ec317',
			color           : 'white',
			boxShadow       : '2px 2px 4px inset #aaa',
			justifyContent  : 'center',
			alignItems      : 'center',
			display         : 'flex',
			lineHeight      : 1.5,
			whiteSpace      : 'nowrap',
			marginRight     : '0',
			'&:hover'       : {
				backgroundColor : '#f50057'
			}
		},
		searchIcon : {
			width  : '1rem',
			height : '1rem'
		},
		circular : {
			display        : 'flex',
			justifyContent : 'center',
			width          : '100%',
			paddingBottom  : '10px'
		},
		loadingIcon : {
			fontSize  : '2rem',
			color     : '#999898',
			animation : '$blinkEffect 0.5s ease infinite'
		},
		'@keyframes blinkEffect' : {
			'0%' : {
				opacity : 0
			},
			'100%' : {
				opacity : 1
			}
		}
	});

const today = new Date();

const thisYear = today.getFullYear();

const thisMonth = today.getMonth()+1;

let nextMonth = thisMonth+1;

let nextMonthYear = thisYear;

if (nextMonth > 12)
{
	nextMonth = nextMonth - 12;
	nextMonthYear = nextMonthYear + 1;
}

const showNumberHint = (accountIdentifier, numberHint) =>
{
	if (accountIdentifier && numberHint)
	{
		return `${accountIdentifier.toUpperCase()}********${numberHint}`;
	}
	else
	{
		return '';
	}
};

const includeConsumptionTax = (amount) =>
{

	return Math.floor(amount*(1+CONSUMPTION_TAX));
};

const splitItemsLen = 100;

let searched = false;

const ServiceSales = ({
	classes
}) =>
{
	const [ pageContent, setPageContent ]= useState('home');

	const [ nextStartItemIndex, setNextStartItemIndex ] = useState(splitItemsLen);

	const [ adminPurchasedAmount, setAdminPurchasedAmount ] = useState(null);

	const [ customerSalesAmount, setCustomerSalesAmount ] = useState(null);

	const [ ownSalesAmount, setOwnSalesAmount ] = useState(null);

	const [ holesalePurchasedAmount, setHolesalePurchasedAmount ] = useState(null);

	const [
		customerSalesCommissionAmount,
		setCustomerSalesCommissionAmount
	] = useState(null);

	const [ ownSalesCommissionAmount, setOwnSalesCommissionAmount ] = useState(null);

	const [ adminPurchaseCount, setAdminPurchaseCount ] = useState(0);

	const [ customerSalesCount, setCustomerSalesCount ]= useState(0);

	const [ ownSalesCount, setOwnSalesCount ] = useState(0);

	const [ allTickets, setAllTickets ] = useState([]);

	const [ allTicketsFiltered, setAllTicketsFiltered ] = useState([]);

	const [
		adminPurchasedTicketsFiltered,
		setAdminPurchasedTicketsFiltered
	] = useState([]);

	const [
		customerSalesTicketsFiltered,
		setCustomerSalesTicketsFiltered
	] = useState([]);

	const [ ownSalesTicketsFiltered, setOwnSalesTicketsFiltered ] = useState([]);

	const [ targetDate, setTargetDate ] = useState(
		{
			year          : thisYear,
			month         : thisMonth,
			nextMonthYear : nextMonthYear,
			nextMonth     : nextMonth
		}
	);

	// search 
	const [ searchParamKey, setSearchParamKey ] = useState('');
	const [ searchBrokerParamKey, setSearchBrokerParamKey ] = useState('');

	const scrollRef = useRef();

	const handleChangePageContent = (value) =>
	{
		setNextStartItemIndex(splitItemsLen);
		setPageContent(value);
	};

	const fetchTickets = useCallback(() =>
	{

		firestore.collection('serviceTickets')
			.onSnapshot((snapshot) =>
			{

				const allTicketsList = [];

				snapshot.forEach((doc) =>
				{
					const docData = doc.data();

					allTicketsList.push({
						id       : doc.id,
						selected : false,
						...docData
					});

				});

				allTicketsList.sort((a, b) =>
				{
					if (a.salesAmount < b.salesAmount) { return -1; }
					if (a.salesAmount > b.salesAmount) { return 1; }

					return 0;
				});

				allTicketsList.sort((a, b) =>
				{
					if (a.createdAt < b.createdAt) { return -1; }
					if (a.createdAt > b.createdAt) { return 1; }

					return 0;
				});

				setNextStartItemIndex(splitItemsLen);

				setAllTickets([ ...allTicketsList ]);

				setAllTicketsFiltered([ ...allTicketsList ]);

			});
	}, []);

	const search = () =>
	{
		let ticketsList = [ ...allTickets ];

		searched=true;

		setNextStartItemIndex(splitItemsLen);

		setAllTicketsFiltered(() =>
		{

			ticketsList = ticketsList.filter((item) =>
			{
				if (searchParamKey)
				{
					if (!item.accountParamKey
						|| !item.accountParamKey.includes(searchParamKey.toUpperCase()))
					{
						return false;
					}
				}

				if (searchBrokerParamKey)
				{
					if (!item.brokerAccountParamKey
						|| !item.brokerAccountParamKey.includes(searchBrokerParamKey.toUpperCase()))
					{
						return false;
					}
				}

				return true;
			}
			);

			return [ ...ticketsList ];
		});
	};

	useEffect(() =>
	{
		fetchTickets();
	}, [ fetchTickets ]);

	useEffect(() =>
	{

		let customerSalesAmountVal = 0;

		let ownSalesAmountVal = 0;

		let adminPurchaseAmountVal = 0;

		let customerCommissionAmountVal = 0;

		let ownCommissionAmountVal = 0;

		let holesaleAmountVal = 0;

		const date = dayjs({
			year  : targetDate.year,
			month : targetDate.month -1,
			day   : 1
		});

		const startMonth = date.startOf('month').valueOf();

		const endMonth = date.endOf('month').valueOf();

		const customerSalesItems = [];

		const ownSalesItems = [];

		const adminPurchaseItems = [];

		const defaultAccountParamKey = DEFAULT_SERVICE_ACCOUNT_KEY.toUpperCase();

		allTicketsFiltered.forEach((item) =>
		{
			if (item.createdAt >= startMonth && item.createdAt <= endMonth)
			{
				if (item.salesChannel === ISSUED_AT_ADMIN_PAGE)
				{
					adminPurchaseItems.push({
						...item,
						selected : item.selected ? item.selected : false
					});

					if (!item.orderCanceled)
					{
						adminPurchaseAmountVal += includeConsumptionTax(item.salesAmount);
						holesaleAmountVal += includeConsumptionTax(item.holesaleAmount);
					}
				}
				else if (item.accountParamKey === defaultAccountParamKey)
				{
					ownSalesItems.push({
						...item,
						selected : item.selected ? item.selected : false
					});

					if (!item.orderCanceled)
					{
						ownSalesAmountVal += includeConsumptionTax(item.salesAmount);
						ownCommissionAmountVal += includeConsumptionTax(item.commission);
					}
				}
				else
				{
					customerSalesItems.push({
						...item,
						selected : item.selected ? item.selected : false
					});

					if (!item.orderCanceled)
					{
						customerSalesAmountVal += includeConsumptionTax(item.salesAmount);
						customerCommissionAmountVal += includeConsumptionTax(item.commission);
					}
				}
			}
		});

		if (!searched)
		{
			setCustomerSalesAmount(customerSalesAmountVal);
			setCustomerSalesCommissionAmount(customerCommissionAmountVal);

			setOwnSalesAmount(ownSalesAmountVal);
			setOwnSalesCommissionAmount(ownCommissionAmountVal);

			setAdminPurchasedAmount(adminPurchaseAmountVal);
			setHolesalePurchasedAmount(holesaleAmountVal);
		}

		setCustomerSalesCount(customerSalesItems.length);

		setCustomerSalesTicketsFiltered(
			[ ...customerSalesItems ].slice(0, nextStartItemIndex)
		);

		setOwnSalesCount(ownSalesItems.length);

		setOwnSalesTicketsFiltered([ ...ownSalesItems ].slice(0, nextStartItemIndex));

		setAdminPurchaseCount(adminPurchaseItems.length);

		setAdminPurchasedTicketsFiltered(
			[ ...adminPurchaseItems ].slice(0, nextStartItemIndex)
		);

	}, [
		allTicketsFiltered,
		targetDate,
		nextStartItemIndex
	]);

	const showDateTime = (timestamp) =>
	{
		if (!timestamp)
		{
			return '';
		}
		else
		{
			return dayjs(timestamp).format('YYYY.MM.DD HH:mm');
		}
	};

	const toggleTicketSelected = (itemId) =>
	{
		setAllTicketsFiltered((state) =>
		{
			const items = [ ...state ];

			const targetIndex = items.findIndex((item) => item.id === itemId);

			if (targetIndex !== -1)
			{
				items[targetIndex].selected = !items[targetIndex].selected;
			}

			return [ ...items ];
		});
	};

	const changeTargetMonth = (val) =>
	{
		searched = false;

		setSearchParamKey('');
		setSearchBrokerParamKey('');

		setTargetDate((state) =>
		{
			let newMonth = state.month+val;

			let newYear = state.year;

			if (newMonth < 1)
			{
				newYear = newYear - 1;
				newMonth = 12;
			}
			else if (newMonth > 12)
			{
				newYear = newYear + 1;
				newMonth = 1;
			}

			let newNextMonthYear = newYear;

			let newNextMonth = newMonth + 1;

			if (newNextMonth > 12)
			{
				newNextMonthYear = newNextMonthYear + 1;
				newNextMonth = 1;
			}

			return {
				year          : newYear,
				month         : newMonth,
				nextMonthYear : newNextMonthYear,
				nextMonth     : newNextMonth
			};
		});

	};

	const downloadFile = ({ data, fileName, fileType }) =>
	{
		const bom = new Uint8Array([ 0xEF, 0xBB, 0xBF ]);
		const blob = new Blob([ bom, data ], { type: fileType });
		const a = document.createElement('a');

		a.download = fileName;
		a.href = window.URL.createObjectURL(blob);

		const clickEvent = new MouseEvent('click', { view: window, bubbles: true, cancelable: true });

		a.dispatchEvent(clickEvent);
		a.remove();
	};

	const exportToCsv = ({ all }) =>
	{

		const ticketsToExport = [];

		let salesChannel = '';

		if (pageContent === 'adminPurchasedList')
		{
			salesChannel = ISSUED_AT_ADMIN_PAGE;
		}
		else
		{
			salesChannel = ISSUED_AT_GUEST_PAGE;
		}

		const date = dayjs({
			year  : targetDate.year,
			month : targetDate.month -1,
			day   : 1
		});

		const startMonth = date.startOf('month').valueOf();

		const endMonth = date.endOf('month').valueOf();

		let allTicketsFilteredExport = [];

		if (all)
		{
			allTicketsFilteredExport = [ ...allTicketsFiltered ];
		}
		else
		{
			allTicketsFilteredExport
				= allTicketsFiltered.filter((itm) => itm.selected === true);
		}

		if (pageContent === 'adminPurchasedList')
		{
			allTicketsFilteredExport.forEach((item) =>
			{
				if (
					item.salesChannel === salesChannel
					&& item.createdAt >= startMonth
					&& item.createdAt <= endMonth)
				{
					ticketsToExport.push({
						...item,
						selected : item.selected ? item.selected : false
					});
				}
			});
		}
		else if (pageContent === 'ownSalesList')
		{
			const defaultAccountParamKey = DEFAULT_SERVICE_ACCOUNT_KEY.toUpperCase();

			allTicketsFilteredExport.forEach((item) =>
			{
				if (
					item.salesChannel === salesChannel
						&& item.createdAt >= startMonth
						&& item.createdAt <= endMonth
						&& item.accountParamKey === defaultAccountParamKey)
				{
					ticketsToExport.push({
						...item,
						selected : item.selected ? item.selected : false
					});
				}
			});
		}
		else
		{
			allTicketsFilteredExport.forEach((item) =>
			{
				const defaultAccountParamKey = DEFAULT_SERVICE_ACCOUNT_KEY.toUpperCase();

				if (
					item.salesChannel === salesChannel
						&& item.createdAt >= startMonth
						&& item.createdAt <= endMonth
						&& item.accountParamKey !== defaultAccountParamKey)
				{
					ticketsToExport.push({
						...item,
						selected : item.selected ? item.selected : false
					});
				}
			});
		}

		const headers = [ '日付,チケットコード,アカウント名,企業ID, 仲介企業名,仲介手数料(%),仲介手数料(円),携帯電話番号,利用可能時間(秒),通話時間(秒),定価, 卸売価格 ,手数料(%),手数料(円),キャンセル' ];

		const body = [];

		ticketsToExport.forEach((d) =>
		{
			const {
				createdAt,
				accountParamKey,
				numberHint,
				accountName,
				brokerAccountName,
				phoneNumber,
				duration,
				consumedSeconds,
				salesAmount,
				holesaleAmount,
				commissionPercentage,
				commission,
				brokerPercentage,
				brokerCommission,
				orderCanceled
			} = d;

			body.push([ showDateTime(createdAt), showNumberHint(accountParamKey, numberHint), accountName, accountParamKey?.toLowerCase(), brokerAccountName, brokerPercentage, brokerCommission, phoneNumber, duration, consumedSeconds, salesAmount, holesaleAmount, commissionPercentage, commission, orderCanceled ].join(','));
		});

		downloadFile({
			data     : [ ...headers, ...body ].join('\n'),
			fileName : 'tickets.csv',
			fileType : 'text/csv'
		});
	};

	const serviceSalesSearch = (
		<>
			<FormControl component='fieldset'>
				<RadioGroup row aria-label='pageContent' name='pageContent' value={pageContent} onChange={(e) => handleChangePageContent(e.target.value)}>
					<FormControlLabel value='customerSalesList' control={<Radio />} label='登録企業 <登録 QR コードから販売>' />
					<FormControlLabel value='ownSalesList' control={<Radio />} label='運営元 <直接販売>' />
					<FormControlLabel value='adminPurchasedList' control={<Radio />} label='登録企業 <管理ページから購入>' />
				</RadioGroup>
			</FormControl>
			<Box className={classes.search}>
				<Box className={classes.searchArea}>
					<Box className={classes.searchGroup}>
						<p className={classes.searchTitle}>企業ID</p>
						<input
							type='text'
							value={searchParamKey}
							className={classes.searchInput}
							onChange={(e) => setSearchParamKey(e.target.value)}
						/>
					</Box>
				</Box>
				<Box className={classes.searchArea}>
					<Box className={classes.searchGroup}>
						<p className={classes.searchTitle}>仲介企業ID</p>
						<input
							type='text'
							value={searchBrokerParamKey}
							className={classes.searchInput}
							onChange={(e) => setSearchBrokerParamKey(e.target.value)}
						/>
					</Box>
					<Box className={classes.searchGroupSearch}>
						<Button
							className={classes.searchBtn}
							onClick={search}
						>
							<SearchIcon className={classes.searchIcon}/>
							<p >検&nbsp;&nbsp;索</p>
						</Button>
					</Box>
				</Box>
			</Box>
		</>
	);

	const handleScroll = () =>
	{
		if (scrollRef.current)
		{
			try
			{
				const scrollHeight = Math.round(scrollRef.current.scrollHeight);

				const scrollAmount = Math.round(scrollRef.current.scrollTop);

				const elementHeight = Math.round(scrollRef.current.clientHeight);

				// scroll reaches end
				if (scrollHeight - 50 < scrollAmount + elementHeight)
				{
					// update startIndex
					setNextStartItemIndex(nextStartItemIndex+splitItemsLen);
				}
			}
			catch
			{
				// do nothing
			}
		}
	};

	return (
		<Box className={classes.root}>
			{ pageContent === 'adminPurchasedList' ?
				<Box>
					<Box className={classes.tableHeader}>
						<p className={classes.title}>
							登録企業決済
						</p>
					</Box>
					{serviceSalesSearch}
					< MonthlySalesAmount
						originPage={'editAccount'}
						data={'purchase'}
						year={targetDate.year}
						month={targetDate.month}
						paymentYear={targetDate.nextMonthYear}
						paymentMonth={targetDate.nextMonth}
						ticketsListLength={adminPurchaseCount}
						salesAmount={adminPurchasedAmount}
						holesaleAmount={holesalePurchasedAmount}
						commissionAmount={null}
					/>
					<Box className={classes.tableNavigation}>
						<Box className={classes.dateNavigation}>
							<Box className={classes.dateNavigationButtons}>
								<Button
									className={classes.dateNavigationButton}
									startIcon={<NavigateBeforeIcon />}
									onClick={() => changeTargetMonth(-1)}
								>
									前月
								</Button>
								<Box className={classes.dateNavigationCurrent}>
									{targetDate.year}年{targetDate.month}月分
								</Box>

								<Button
									className={classes.dateNavigationButton}
									startIcon={<NavigateNextIcon />}
									onClick={() => changeTargetMonth(1)}
								>
									翌月
								</Button>
							</Box>
						</Box>

						<Box className={classes.exportButtonWrapper}>
							<Button
								className={classes.exportButton}
								onClick={() => exportToCsv({ all: false })}
							>
								CSV出力
							</Button>

							<Button
								className={classes.exportButton}
								onClick={() => exportToCsv({ all: true })}
							>
								全データCSV出力
							</Button>
						</Box>
					</Box>
					<Box
						className={classes.scrollBox}
						onScroll={handleScroll}
						ref={scrollRef}
					>
						<Box
							className={classes.scrollContent}
						>
							<TableContainer component={Paper}>
								<Table className={classes.table} aria-label='simple table'>
									<TableHead>
										<TableRow>
											<TableCell> </TableCell>
											<TableCell align='center'>日付</TableCell>
											<TableCell align='center'>チケットコード</TableCell>
											<TableCell align='center'>追加メール送信先1</TableCell>
											<TableCell align='center'>追加メール送信先2</TableCell>
											<TableCell align='center'>定価</TableCell>
											<TableCell align='center'>御社購入価格()</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{adminPurchasedTicketsFiltered.map((row) => (
											<TableRow key={row.id}>
												<TableCell padding='checkbox' align='center'>
													<Checkbox
														checked={row.selected}
														inputProps={{ 'aria-labelledby': `checkBox-${row.id}` }}
														onClick={() => toggleTicketSelected(row.id)}
													/>
												</TableCell>
												<TableCell align='center'>{showDateTime(row.createdAt)}</TableCell>
												<TableCell align='center'>{ showNumberHint(row.accountParamKey, row.numberHint)}</TableCell>
												<TableCell align='center'>{row.additionalEmail1}</TableCell>
												<TableCell align='center'>{row.additionalEmail2}</TableCell>
												<TableCell align='center'>{row.salesAmount}</TableCell>
												<TableCell align='center'>{row.holesaleAmount}</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
							{ (adminPurchaseCount > splitItemsLen
								&& ((adminPurchaseCount !== adminPurchasedTicketsFiltered.length)
								)
							)
								&&
								<>
									<li className={classes.circular}>
										<CachedIcon className={classes.loadingIcon}/>
									</li>
								</>
							}
						</Box>
					</Box>
				</Box>
				: pageContent === 'customerSalesList' ?
					<Box>
						<Box className={classes.tableHeader}>
							<p className={classes.title}>
								登録企業 &lt;登録 QR コードから販売&gt;
							</p>
						</Box>
						{serviceSalesSearch}
						< MonthlySalesAmount
							originPage={'editAccount'}
							data={'customerSales'}
							year={targetDate.year}
							month={targetDate.month}
							paymentYear={targetDate.nextMonthYear}
							paymentMonth={targetDate.nextMonth}
							ticketsListLength={customerSalesCount}
							salesAmount={customerSalesAmount}
							holesaleAmount={null}
							commissionAmount={customerSalesCommissionAmount}
						/>
						<Box className={classes.tableNavigation}>
							<Box className={classes.dateNavigation}>
								<Box className={classes.dateNavigationButtons}>
									<Button
										className={classes.dateNavigationButton}
										startIcon={<NavigateBeforeIcon />}
										onClick={() => changeTargetMonth(-1)}
									>
										前月
									</Button>
									<Box className={classes.dateNavigationCurrent}>
										{targetDate.year}年{targetDate.month}月分
									</Box>

									<Button
										className={classes.dateNavigationButton}
										startIcon={<NavigateNextIcon />}
										onClick={() => changeTargetMonth(1)}
									>
										翌月
									</Button>
								</Box>
							</Box>

							<Box className={classes.exportButtonWrapper}>
								<Button
									className={classes.exportButton}
									onClick={() => exportToCsv({ all: false })}
								>
									CSV出力
								</Button>

								<Button
									className={classes.exportButton}
									onClick={() => exportToCsv({ all: true })}
								>
									全データCSV出力
								</Button>
							</Box>
						</Box>
						<Box
							className={classes.scrollBox}
							onScroll={handleScroll}
							ref={scrollRef}
						>
							<Box
								className={classes.scrollContent}
							>
								<TableContainer component={Paper}>
									<Table className={classes.table} aria-label='simple table'>
										<TableHead>
											<TableRow>
												<TableCell> </TableCell>
												<TableCell align='center'>日付</TableCell>
												<TableCell align='center'>チケットコード</TableCell>
												<TableCell align='center'>二次代理店</TableCell>
												<TableCell align='center'>メールアドレス</TableCell>
												<TableCell align='center'>定価</TableCell>
												<TableCell align='center'>手数料</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{customerSalesTicketsFiltered.map((row) => (
												<TableRow key={row.id}>
													<TableCell padding='checkbox' align='center'>
														<Checkbox
															checked={row.selected}
															inputProps={{ 'aria-labelledby': `checkBox-${row.id}` }}
															onClick={() => toggleTicketSelected(row.id)}
														/>
													</TableCell>
													<TableCell align='center'>{showDateTime(row.createdAt)}</TableCell>
													<TableCell align='center'>{ showNumberHint(row.accountParamKey, row.numberHint)}</TableCell>
													<TableCell align='center'>{row.distributerName}</TableCell>
													<TableCell align='center'>{row.email}</TableCell>
													<TableCell align='center'>{row.salesAmount}</TableCell>
													<TableCell align='center'>{row.commission}</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
								{ (customerSalesCount > splitItemsLen
								&& ((customerSalesCount !== customerSalesTicketsFiltered.length)
								)
								)
								&&
								<>
									<li className={classes.circular}>
										<CachedIcon className={classes.loadingIcon}/>
									</li>
								</>
								}
							</Box>
						</Box>
					</Box>
					: pageContent === 'ownSalesList' ?
						<Box>
							<Box className={classes.tableHeader}>
								<p className={classes.title}>
									運営元 &lt;直接販売&gt;
								</p>
							</Box>
							{serviceSalesSearch}
							< MonthlySalesAmount
								originPage={'editAccount'}
								data={'ownSales'}
								year={targetDate.year}
								month={targetDate.month}
								paymentYear={targetDate.nextMonthYear}
								paymentMonth={targetDate.nextMonth}
								ticketsListLength={ownSalesCount}
								salesAmount={ownSalesAmount}
								holesaleAmount={null}
								commissionAmount={ownSalesCommissionAmount}
							/>
							<Box className={classes.tableNavigation}>
								<Box className={classes.dateNavigation}>
									<Box className={classes.dateNavigationButtons}>
										<Button
											className={classes.dateNavigationButton}
											startIcon={<NavigateBeforeIcon />}
											onClick={() => changeTargetMonth(-1)}
										>
											前月
										</Button>
										<Box className={classes.dateNavigationCurrent}>
											{targetDate.year}年{targetDate.month}月分
										</Box>

										<Button
											className={classes.dateNavigationButton}
											startIcon={<NavigateNextIcon />}
											onClick={() => changeTargetMonth(1)}
										>
											翌月
										</Button>
									</Box>
								</Box>

								<Box className={classes.exportButtonWrapper}>
									<Button
										className={classes.exportButton}
										onClick={() => exportToCsv({ all: false })}
									>
										CSV出力
									</Button>

									<Button
										className={classes.exportButton}
										onClick={() => exportToCsv({ all: true })}
									>
										全データCSV出力
									</Button>
								</Box>
							</Box>
							<Box
								className={classes.scrollBox}
								onScroll={handleScroll}
								ref={scrollRef}
							>
								<Box
									className={classes.scrollContent}
								>
									<TableContainer component={Paper}>
										<Table className={classes.table} aria-label='simple table'>
											<TableHead>
												<TableRow>
													<TableCell> </TableCell>
													<TableCell align='center'>日付</TableCell>
													<TableCell align='center'>チケットコード</TableCell>
													<TableCell align='center'>二次代理店</TableCell>
													<TableCell align='center'>メールアドレス</TableCell>
													<TableCell align='center'>定価</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{ownSalesTicketsFiltered.map((row) => (
													<TableRow key={row.id}>
														<TableCell padding='checkbox' align='center'>
															<Checkbox
																checked={row.selected}
																inputProps={{ 'aria-labelledby': `checkBox-${row.id}` }}
																onClick={() => toggleTicketSelected(row.id)}
															/>
														</TableCell>
														<TableCell align='center'>{showDateTime(row.createdAt)}</TableCell>
														<TableCell align='center'>{ showNumberHint(row.accountParamKey, row.numberHint)}</TableCell>
														<TableCell align='center'>{row.distributerName}</TableCell>
														<TableCell align='center'>{row.email}</TableCell>
														<TableCell align='center'>{row.salesAmount}</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									</TableContainer>
									{ (ownSalesCount > splitItemsLen
								&& ((ownSalesCount !== ownSalesTicketsFiltered.length)
								)
									)
								&&
								<>
									<li className={classes.circular}>
										<CachedIcon className={classes.loadingIcon}/>
									</li>
								</>
									}
								</Box>
							</Box>
						</Box>
						:
						<>
							<Box className={classes.homeContentSection}>
								<Box className={classes.totalAmount}>
									<Box className={classes.totalAmountBox}>
										<p className={classes.totalAmountTitle}>
											LIVE通訳売上トータル
										</p>
										<p className={classes.totalAmountValue}>
											{
												showNumberWithComma(
													ownSalesAmount+customerSalesAmount+adminPurchasedAmount
												)
											}円(税別)
										</p>
									</Box>
								</Box>
								<Box className={classes.dateNavigation}>
									<Box className={classes.dateNavigationButtons}>
										<Button
											className={classes.dateNavigationButton}
											startIcon={<NavigateBeforeIcon />}
											onClick={() => changeTargetMonth(-1)}
										>
											前月
										</Button>
										<Box className={classes.dateNavigationCurrent}>
											{targetDate.year}年{targetDate.month}月分
										</Box>

										<Button
											className={classes.dateNavigationButton}
											startIcon={<NavigateNextIcon />}
											onClick={() => changeTargetMonth(1)}
										>
											翌月
										</Button>
									</Box>
								</Box>
								<Box className={`${classes.homeContentBox} ${classes.homeContentBoxSold}`}>
									<p className={classes.title}>
										登録企業 &lt;登録 QR コードから販売&gt;
									</p>
									< MonthlySalesAmount
										originPage={'editAccount'}
										data={'customerSales'}
										year={targetDate.year}
										month={targetDate.month}
										paymentYear={targetDate.nextMonthYear}
										paymentMonth={targetDate.nextMonth}
										ticketsListLength={customerSalesCount}
										salesAmount={customerSalesAmount}
										holesaleAmount={null}
										commissionAmount={customerSalesCommissionAmount}
									/>

									<Button
										className={classes.detailButton}
										onClick={() => handleChangePageContent('customerSalesList')}
									>
										<FormattedMessage
											id='label.detail'
											defaultMessage='Detail'
										/>
									</Button>
								</Box>
								<Box className={`${classes.homeContentBox} ${classes.homeContentBoxSold}`}>
									<p className={classes.title}>
										運営元 &lt;直接販売&gt;
									</p>
									< MonthlySalesAmount
										originPage={'editAccount'}
										data={'ownSales'}
										year={targetDate.year}
										month={targetDate.month}
										paymentYear={targetDate.nextMonthYear}
										paymentMonth={targetDate.nextMonth}
										ticketsListLength={ownSalesCount}
										salesAmount={ownSalesAmount}
										holesaleAmount={null}
										commissionAmount={ownSalesCommissionAmount}
									/>

									<Button
										className={classes.detailButton}
										onClick={() => handleChangePageContent('ownSalesList')}
									>
										<FormattedMessage
											id='label.detail'
											defaultMessage='Detail'
										/>
									</Button>
								</Box>

								<Box className={`${classes.homeContentBox} ${classes.homeContentBoxPurchased}`}>
									<p className={classes.title}>
										登録企業決済
									</p>
									< MonthlySalesAmount
										originPage={'editAccount'}
										data={'purchase'}
										year={targetDate.year}
										month={targetDate.month}
										paymentYear={targetDate.nextMonthYear}
										paymentMonth={targetDate.nextMonth}
										ticketsListLength={adminPurchaseCount}
										salesAmount={adminPurchasedAmount}
										paymentAmount={holesalePurchasedAmount}
										holesaleAmount={holesalePurchasedAmount}
										commissionAmount={null}
									/>

									<Button
										className={classes.detailButton}
										onClick={() => handleChangePageContent('adminPurchasedList')}
									>
										<FormattedMessage
											id='label.detail'
											defaultMessage='Detail'
										/>
									</Button>
								</Box>
							</Box>
						</>
			}
		</Box>
	);
};

ServiceSales.propTypes =
{
	classes : PropTypes.object.isRequired
};

const mapStateToProps = () =>
{
	return {
	};
};

const mapDispatchToProps = () =>
{
	return {
	};
};

export default withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{}
)(withStyles(styles)(ServiceSales)));