import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import DatePickerDayMonthYear from './DatePickerDayMonthYear';

import constants from '../../constants';
import { exportToCSVSkill } from '../../utils/exportCSV';
import { formatDataForButtons } from './skillCalculator';

const styles = () => ({
	hostContainerHeader : {
		display        : 'flex',
		justifyContent : 'space-between',
		width          : '100%',
		borderBottom   : '2px solid #cacaca',
		padding        : '15px 5px 5px 20px',
		marginBottom   : '14px'
	},
	hostLanguages : {
		padding         : '4px',
		backgroundColor : '#FFF',
		borderRadius    : '2px',
		marginRight     : '5px',
		color           : '#707070',
		fontSize        : '10px'
	},
	hostButtonCsv : {
		padding         : '2px 25px',
		backgroundColor : '#e65600',
		border          : 'none',
		borderRadius    : '5px',
		color           : '#FFF',
		cursor          : 'pointer',
		fontSize        : '12px'
	},
	hostContainerDateTime : {
		display        : 'flex',
		justifyContent : 'flex-start',
		alignItems     : 'center',
		padding        : '0px 5px 0px 20px'
	},
	hostTextFilter : {
		color       : '#c3c3c3',
		marginRight : '15px',
		paddingTop  : '20px'
	},
	hostTableRowSkillItemColumn : {
		minWidth    : '150px',
		borderRight : '1px solid #c3c3c3'
	},
	hostTableRowSkillLanguageColumn : {
		borderRight    : '1px solid #c3c3c3',
		color          : '#FFF',
		'&:last-child' : {
			borderRight : 'none'
		}
	},
	hostTableRowSkillItem : {
		textAlign : 'right'
	},
	hostTildeFilter : {
		fontSize : '30px',
		color    : '#c3c3c3',
		padding  : '20px 10px 0 10px'
	},
	hostDropIcon : {
		color : '#525252'
	},
	hostGlobalText : {
		'& th, & td' : {
			color : '#939292'
		},
		'& tr:nth-of-type(3), & tr:nth-of-type(7), & tr:nth-of-type(9)' : {
			border       : 'unset',
			borderBottom : '2px solid #cacaca'
		}
	},
	tableContainerScroll : {
		width    : '100% !important',
		overflow : 'auto !important',
		padding  : '6px 8px 10px'
	},
	customTableBody : {
		width     : '100%',
		padding   : '10px',
		boxShadow : '0px 4px 6px -2px #c3c3c3'
	},
	tableHead : {
		borderRadius : '10px 0px 0px 0px'
	},
	tableHeadLastChild : {
		borderRadius : '0px 10px 0px 0px'
	},
	cellHeadLastChild : {
		borderRight : '0px solid #c3c3c3 !important'
	},
	hostContainerNav : {
		position  : 'absolute',
		top       : '50%',
		left      : '50%',
		transform : 'translate(-50%, -50%)'
	},
	hostCustomDatePicker : {
		'& .MuiTableCell-root' : {
			padding : '0px 16px !important'
		},
		'& .MuiInputBase-root' : {
			padding   : '4px !important',
			marginTop : '0px !important'
		},
		'& .MuiFormControl-root' : {
			marginTop    : '12px !important',
			marginBottom : '7px !important'
		}
	},
	hostTableRow : {
		'& th:first-child' : {
			borderRadius : '8px 0px 0px 0px'
		},
		'& th:last-child' : {
			borderRadius : '0px 8px 0px 0px'
		}
	},
	hostTableRowSkill : {
		'& th:first-child' : {
			borderRadius : '10px 0px 0px 0px'
		},
		'& th:last-child' : {
			borderRadius : '0px 10px 0px 0px'
		}
	},
	hostPerformanceText : {
		fontSize   : '13px',
		fontWeight : 600,
		color      : '#525252'
	}
});

const StyledTableCell = withStyles((theme) => ({
	head : {
		backgroundColor : constants.backgroundColorHead,
		color           : theme.palette.common.white,
		border          : '1px solid #FFF'
	},
	body : {
		fontSize        : 14,
		height          : constants.heightTableRow,
		border          : '1px solid #E0E0E0',
		padding         : '0px 16px',
		backgroundColor : '#FFF'
	}
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	root : {
		'&:nth-of-type(odd)' : {
			backgroundColor : theme.palette.action.hover
		}
	}
}))(TableRow);

// downLoadDate
const toDay = new Date();

const fromDay = new Date(toDay);

fromDay.setMonth(toDay.getMonth() - 1);

function Skill({ classes, calls, roomsSnapshots, queueResults, buttons })
{
	const [ dataSkillDateTime, setDataSkillDateTime ] = useState([]);
	const [ fromDateTime, setFromDateTime ] = useState(fromDay);
	const [ toDateTime, setToDateTime ] = useState(toDay);

	useEffect(() =>
	{
		const data = formatDataForButtons(
			fromDateTime ? fromDateTime.getTime() : 0,
			toDateTime ? toDateTime.getTime() : 0,
			calls,
			roomsSnapshots,
			queueResults,
			buttons
		);

		setDataSkillDateTime(data);
	}, [ fromDateTime, toDateTime, calls, roomsSnapshots, queueResults, buttons ]);

	const renderTableRows = () =>
	{
		if (!dataSkillDateTime || dataSkillDateTime.length === 0) return null;

		const firstItem = dataSkillDateTime[0];

		const keys = Object.keys(firstItem);

		return keys.map((key) => (
			<StyledTableRow key={key}>
				<StyledTableCell component='th' scope='row'>
					{key}
				</StyledTableCell>

				{ dataSkillDateTime.map((item, index) => (
					<StyledTableCell align='right' key={index}>
						{item[key]}
					</StyledTableCell>
				))}
			</StyledTableRow>
		));
	};

	return (
		<div className='host-container'>
			<TableContainer component={Paper} className={`${classes.tableContainerScroll}`}>
				<div className={classes.hostContainerHeader}>
					<div className={classes.hostPerformanceText}>スキル別パフォーマンス</div>
					<button
						className={classes.hostButtonCsv}
						onClick={() =>
							exportToCSVSkill(buttons, dataSkillDateTime, 'data-host-skill.csv', fromDateTime, toDateTime)
						}
					>
						CSV 出力
					</button>
				</div>
				<div className={classes.hostContainerDateTime}>
					<div className={classes.hostTextFilter}>期間指定</div>
					<div>
						<DatePickerDayMonthYear
							value={fromDateTime}
							setDateTime={setFromDateTime}
						/>
					</div>
					<div className={classes.hostTildeFilter}>~</div>
					<div>
						<DatePickerDayMonthYear
							value={toDateTime}
							setDateTime={setToDateTime}
						/>
					</div>
				</div>
				<Table className={`${classes.customTableBody}`} aria-label='customized table'>
					<TableHead>
						<TableRow className={classes.hostTableRow}>
							<StyledTableCell className={classes.hostTableRowSkillItemColumn}>
								項目
							</StyledTableCell>
							{ buttons.map((btn, index) => (
								<StyledTableCell
									className={classes.hostTableRowSkillLanguageColumn}
									style={{ width: `${100/(buttons.length)}%`, maxWidth: `${85/buttons.length}%` }}
									align='center'
									key={index}
								>
									<div className={classes.hostTableRowSkillItem}>
										<span>{btn.label ? btn.label : btn.btnText}</span>
									</div>
								</StyledTableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody className={classes.hostGlobalText}>
						{renderTableRows(buttons)}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
}

export default withStyles(styles)(Skill);
