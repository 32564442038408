// React
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
// Prop types
import PropTypes from 'prop-types';

// Redux
import { withRoomContext } from '../../../../RoomContext';
import { connect } from 'react-redux';
import * as roomActions from '../../../../actions/roomActions';
import * as meActions from '../../../../actions/meActions';

// Components
import JoinRoomNoticeDialog from '../../Common/JoinRoomNoticeDialog';
import NoTalkTimeDialog from '../Parts/NoTalkTimeDialog';
import ConfirmDialog from '../../Common/ConfirmDialog';
import ServiceAppBar from '../Parts/ServiceAppBar';
import ServiceButton from '../Parts/ServiceButton';
import LandingPage from './LandingPage';
import TranslationHistory from './translation-history/TranslationHistory';

import { withStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';

// material ui 
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

// Firebase
import { firestore } from '../../../../lib/firebase';
// import { useAuthState } from 'react-firebase-hooks/auth';

// Daysjs
import dayjs from 'dayjs';

// const 
import { ROOM_ONLINE } from '../../../../const';

// util
import { parseUrlParams, getMilliSecondsNow } from '../../../../utils';

// locale
import { useIntl, FormattedMessage } from 'react-intl';

// images
import englishImg from '../../../../images/flags/english.png'; // eslint-disable-line
import koreaImg from '../../../../images/flags/korea.png'; // eslint-disable-line
import chinaImg from '../../../../images/flags/china.png'; // eslint-disable-line

// dayjsの タイムゾーンの設定
dayjs.extend(require('dayjs/plugin/timezone'));
dayjs.extend(require('dayjs/plugin/utc'));
dayjs.tz.setDefault('Asia/Tokyo');

const breakPointAspectRatio = 1;

const appBarHeight = 40;

const styles = (theme) =>
	({
		root :
		{
			width           : '100%',
			height          : '100%',
			overflowX       : 'auto',
			backgroundColor : '#EAEAEA'
		},
		content : {
			width          : '100%',
			height         : '100%',
			minWidth       : '320px',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'center',
			alignItems     : 'flex-start',
			position       : 'relative'
		},
		loginContent : {
			width    : '100%',
			height   : '100%',
			minWidth : '320px'
		},
		infomationArea : {
			width         : '98%',
			display       : 'flex',
			flexDirection : 'column',
			alignItems    : 'center',
			marginTop     : '25px'
		},
		informationBox : {
			position        : 'relative',
			padding         : '4px 10px',
			backgroundColor : 'rgba(0,0,0,0.55)',
			borderRadius    : '20px',
			width           : '100%',
			display         : 'flex',
			flexDirection   : 'column',
			alignItems      : 'center'
		},
		informationBoxSP : {
			maxWidth : '650px',
			minWidth : '315px',
			width    : '94%'
		},
		informationBoxPC : {
			width    : '98%',
			maxWidth : '800px'
		},
		ticketNumber : {
			width          : '95%',
			display        : 'flex',
			justifyContent : 'end',
			paddingRight   : '1%',
			marginBottom   : '4px'
		},
		ticketNumberSP : {
			maxWidth : '650px'
		},
		ticketNumberPC : {
			maxWidth : '800px'
		},
		ticketNumberText : {
			paddingTop : '4px',
			color      : '#292929',
			fontSize   : '0.7rem',
			[theme.breakpoints.up('sm')] :
			{
				paddingTop : '2px',
				fontSize   : '0.8rem'
			}
		},
		ticketNumberBtn : {
			marginLeft      : '12px',
			color           : '#292929',
			fontSize        : '0.8rem',
			backgroundColor : '#FFF',
			padding         : '0 5px',
			lineHeight      : '20px',
			cursor          : 'pointer',
			borderRadius    : '4px',
			boxShadow       : '1px 1px rgba(0, 0, 0, 0.4)',
			marginBottom    : '1px',
			[theme.breakpoints.up('sm')] :
			{
				marginLeft : '16px'
			}
		},
		expiryDateNoLimit : {
			display        : 'flex',
			justifyContent : 'center',
			width          : '100%'
		},
		expiryDateText : {
			color    : '#FFF',
			fontSize : '0.9rem',
			padding  : '3px 10px'
		},
		expiryDateTextNoLimit : {
			margin : '0 5%'
		},
		expiryDateTextWithLimit :
		{
			width        : '90%',
			borderBottom : '1px solid #FFF'
		},
		expiryDateTextFree : {
			textAlign : 'center'
		},
		remainingTime : {
			width        : '90%',
			padding      : '3px 10px',
			borderBottom : '1px solid #FFF'
		},
		remainingTimeLine : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'space-between'
		},
		remainingTimeText : {
			color    : '#FFF',
			fontSize : '1rem'
		},
		remainingTimeTextFree : {
			width     : '100%',
			textAlign : 'center',
			color     : '#F4DA23',
			fontSize  : '1rem'
		},
		explanation : {
			color    : '#FFF',
			fontSize : '0.7rem',
			width    : '90%',
			padding  : '3px 10px'
		},
		explanationFree : {
			textAlign : 'center'
		},
		buttonSection : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'center'
		},
		buttonBoxTitle : {
			color     : '#292929',
			fontSize  : '1rem',
			width     : '100%',
			textAlign : 'center'
		},
		buttonAreaPC :
		{
			display         : 'flex',
			flexDirection   : 'column',
			justifyContent  : 'flex-start',
			padding         : '20px 0 10px 0',
			marginTop       : '1%',
			width           : '98%',
			maxWidth        : '800px',
			backgroundColor : '#FFF',
			borderRadius    : '10px'
		},
		buttonAreaSP :
		{
			display         : 'flex',
			flexDirection   : 'column',
			justifyContent  : 'flex-start',
			padding         : '20px 0 10px 0',
			marginTop       : '1%',
			width           : '90%',
			maxWidth        : '650px',
			minWidth        : '300px',
			backgroundColor : '#FFF',
			borderRadius    : '10px'
		},
		naviSection : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'center',
			marginTop      : '20px'
		},
		naviItemWrapper : {
			width          : '80%',
			maxWidth       : '500px',
			display        : 'flex',
			justifyContent : 'space-between'
		},
		naviItem : {
			width          : '50%',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'center',
			alignItems     : 'center'
		},
		naviItemLink : {
			cursor : 'pointer'
		},
		naviImg : {
			height : '28px',
			width  : 'auto'
		},
		naviTextHome : {
			color    : '#cd2c82',
			fontSize : '0.7rem'
		},
		naviTextLink : {
			color    : 'var(--text-color)',
			fontSize : '0.7rem'
		},
		buttonRow :
		{
			display        : 'flex',
			justifyContent : 'center',
			width          : '100%',
			padding        : '0 1%',
			margin         : '1% 0'
		},
		imageContentPC : {
			position       : 'relative',
			objectPosition : '50% 50%',
			objectFit      : 'contain !important',
			maxWidth       : '100vw',
			maxHeight      : '100%'
		},
		imageContentSP : {
			position       : 'relative',
			objectPosition : '50% 50%',
			objectFit      : 'contain !important',
			maxWidth       : '100%',
			maxHeight      : '100vh'
		},
		container : {
			display         : 'flex',
			flexDirection   : 'column',
			justifyContent  : 'flex-start',
			alignItems      : 'center',
			width           : '100%',
			height          : `calc( 100% - ${appBarHeight}px )`,
			backgroundColor : '#EAEAEA'
		},
		fullCoveredScreen : {
			position        : 'fixed',
			top             : '0',
			left            : '0',
			height          : '100vh',
			width           : '100vw',
			display         : 'flex',
			flexDirection   : 'column',
			justifyContent  : 'center',
			alignItems      : 'center',
			backgroundColor : 'white'
		},
		loadingCircular : {
			color : '#cd2c82'
		},
		disabledIndicator : {
			display         : 'flex',
			flexDirection   : 'column',
			justifyContent  : 'center',
			alignItems      : 'center',
			backgroundColor : 'white',
			padding         : '20px 25px',
			border          : '1px solid var(--text-color)',
			borderRadius    : '10px',
			maxWidth        : '90vw',
			marginBottom    : '15%'
		},
		disabledText : {
			fontSize  : '1.5rem',
			color     : 'var(--text-color)',
			textAlign : 'center'
		},
		title : {
			textAlign : 'center'
		}
	});

const showExpiryTime = (timestamp) =>
{
	if (timestamp === 0 || timestamp === '0')
	{
		return '';
	}
	else if (timestamp)
	{
		const dayjsTime = dayjs(timestamp);

		return dayjsTime.format('YYYY/MM/DD H:mm');
	}
	else
	{
		return '';
	}
};

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	}
});

let roomStatusListener = null;

let termsPage = 'default';

const ConnectHome = ({
	classes,
	setJoinRoomNoticeDialogOpen,
	intlObj,
	connectInfos,
	setConnectInfos
}) =>
{
	const location = useLocation();

	const intl = useIntl();

	// const [ serviceLogin ] = useAuthState(auth);

	const [ page, setPage ] = useState('landing');

	const [ authKey, setAuthKey ] = useState(null);

	const [ accountParamKey, setAccountParamKey ] = useState('');

	const [ notalkTime, setNoTalkTime ] = useState(false);

	const [ connectPageData, setConnectPageData ] = useState({});

	// Image URls
	// const [ bgPCUrl, setBgPCUrl ] = useState('');
	// const [ bgSPUrl, setBgSPUrl ] = useState('');

	const [ buttons, setButtons ] = useState([]);

	// Button Area
	const [ btnAreaContent, setBtnAreaContent ] = useState({
		PC : {
			columns           : 2,
			btnMargin         : '15px 15px',
			btnPadding        : '15px 40px',
			btnFontSize       : '1.5vw',
			btnFontSizeSecond : '1vw'
		},
		SP : {
			columns           : 1,
			btnMargin         : '15px 15px',
			btnPadding        : '15px 40px',
			btnFontSize       : '2vw',
			btnFontSizeSecond : '1.5vw'
		}
	});

	const [ btnAreaRows, setBtnAreaRows ] = useState({
		PC : 1,
		SP : 1
	});

	// window size
	const [ aspectRatio, setAspectRatio ] = useState(window.innerWidth/window.innerHeight);

	// available room
	const [ roomAvailable, setRoomAvailable ] = useState([]);

	const [ confirmDialogState, setConfirmDialogState ] = useState(
		{
			show    : false,
			title   : '',
			message : ''
		}
	);

	// nationalHolidays 
	const [ nationalHolidaysList, setNationalHolidaysList ] = useState([ [] ]);

	const [ calls, setCalls ] = useState();

	const [ loading, setLoading ] = useState(true);

	const buttonAreaRef = useRef();

	// const bgImgRef = useRef();
	// const bgImgAreaRef = useRef();

	const [ disabled, setDisabled ] = useState({ value: false, reason: '' });

	const updateDimensions = useCallback(() =>
	{
		setAspectRatio(window.innerWidth/window.innerHeight);

	}, [ ]);

	useEffect(() =>
	{
		window.addEventListener('resize', updateDimensions);

		return () => window.removeEventListener('resize', updateDimensions);

	}, [ updateDimensions ]);

	useEffect(() =>
	{
		// 取得が終わった段階で表示
		if (!loading)
		{
			updateDimensions();
		}

	}, [ updateDimensions, loading ]);

	const fetchInfos = useCallback(() =>
	{
		if (authKey && accountParamKey)
		{
			firestore.collection('connections')
				.where('accountParamKey', '==', accountParamKey)
				.where('connectionKey', '==', authKey)
				.get()
				.then((data) =>
				{
					if (data.docs && data.docs[0])
					{
						const connectionData = data.docs[0].data();

						if (connectionData.expiryTimestamp !== -1)
						{
							const milliSecondsNow = getMilliSecondsNow();

							if (!connectionData.expiryTimestamp
								|| milliSecondsNow > connectionData.expiryTimestamp)
							{
								setDisabled({ value: true, reason: 'expired' });
								setLoading(false);

								return;
							}
						}

						setConnectInfos({ ...connectionData, id: data.docs[0].id });

						firestore.collection('connectAccounts')
							.doc(connectionData.accountId)
							.get()
							.then((account) =>
							{
								if (account.exists)
								{
									const connectAccountData = account.data();

									termsPage = connectAccountData.termsPage ? connectAccountData.termsPage : 'default';

									if (connectAccountData.browserTitle)
									{
										document.title = connectAccountData.browserTitle;
									}

									setDisabled(connectAccountData.disabled ? { value: true, reason: 'suspended' }: { value: false, reason: '' });

									setBtnAreaContent(connectAccountData.btnAreaContent
										?	connectAccountData.btnAreaContent
										: {
											PC : {
												columns           : 2,
												btnMargin         : '15px 15px',
												btnPadding        : '15px 40px',
												btnFontSize       : '1.5vw',
												btnFontSizeSecond : '1vw'
											},
											SP : {
												columns           : 1,
												btnMargin         : '15px 15px',
												btnPadding        : '15px 40px',
												btnFontSize       : '2vw',
												btnFontSizeSecond : '1.5vw'
											}
										}
									);

									if (connectAccountData.buttons)
									{
									// how many rows in btnArea
										const pcRows = connectAccountData.btnAreaContent?.PC?.columns
											? Math.ceil(
												connectAccountData.buttons.length
											/connectAccountData.btnAreaContent.PC.columns
											) : 1;

										const spRows = connectAccountData.btnAreaContent?.SP?.columns
											? Math.ceil(
												connectAccountData.buttons.length
											/connectAccountData.btnAreaContent.SP.columns
											) : 1;

										setBtnAreaRows(
											{
												PC : pcRows,
												SP : spRows
											});
									}

									setButtons(connectAccountData.buttons
										? connectAccountData.buttons : []);

									const buttonSubdomains = [];

									connectAccountData.buttons.forEach((b) =>
									{
										if (!buttonSubdomains.includes(b.subdomain))
										{
											buttonSubdomains.push(b.subdomain);
										}
									});

									setLoading(false);

									roomStatusListener = firestore.collection('rooms')
										.where('subdomain', 'in', buttonSubdomains)
										.onSnapshot((snapshot) =>
										{
											// ボタンごとにavailable/not availableをチェック
											// {'buttonId1': true, 'buttonId2': false}
											const subdomainSkillsAvalilable = [];

											snapshot.forEach((r) =>
											{
												if (r.exists)
												{
													const roomData = r.data();

													if (roomData.status === ROOM_ONLINE)
													{
														subdomainSkillsAvalilable.push({
															subdomain : roomData.subdomain,
															skills    : roomData.skills || []
														});
													}
												}
											});

											const roomAvailableAr = [];

											// 祝日の取得の要否。
											let getNationalHolidays = false;

											// buttonsに移し替える
											if (connectAccountData?.buttons)
											{
												connectAccountData.buttons.forEach((b, btnIndex) =>
												{
													// button with skillId
													if (b.skillId && b.skillId !== '_')
													{
														const subdomainSkillIndex = subdomainSkillsAvalilable
															.findIndex((item) =>
																item.subdomain === b.subdomain
																&& item.skills.includes(b.skillId));

														if (subdomainSkillIndex !== -1)
														{
															roomAvailableAr[btnIndex] = true;
														}
														else
														{
															roomAvailableAr[btnIndex] = false;
														}
													}
													else // button without skillId
													{
														const subdomainIndex = subdomainSkillsAvalilable
															.findIndex((item) => item.subdomain === b.subdomain);

														if (subdomainIndex !== -1)
														{
															roomAvailableAr[btnIndex] = true;
														}
														else
														{
															roomAvailableAr[btnIndex] = false;
														}
													}
													// 祝日休みならgetNationalHolidaysをtrueにして取得する
													if (b.nationalHoliday)
													{
														getNationalHolidays = true;
													}
												});

												setRoomAvailable([ ...roomAvailableAr ]);

												// 必要なら祝日データを取得
												if (getNationalHolidays === true)
												{
													firestore.collection('nationalHolidaysJP').orderBy('createdAt', 'desc')
														.get()
														.then((document) =>
														{
															const nationalHolidaysAr
																= document.docs[0]?.data()?.holidays;

															if (nationalHolidaysAr)
															{
																setNationalHolidaysList([
																	nationalHolidaysAr.filter(
																		(h) =>
																			Number(h.type) <= Number(1)
																	),
																	nationalHolidaysAr.filter(
																		(h) =>
																			Number(h.type) <= Number(2)
																	),
																	nationalHolidaysAr.filter(
																		(h) =>
																			Number(h.type) <= Number(3)
																	)
																]);
															}
														});
												}
											}
										});
								}
								else
								{
									setDisabled({ value: true, reason: 'notFound' });
									setLoading(false);
								}
							});
					}
					else
					{
						setDisabled({ value: true, reason: 'notFound' });
						setLoading(false);
					}
				});
		}
	}, [ authKey, setConnectInfos, accountParamKey ]);

	const fetchCalls = useCallback(() =>
	{
		setLoading(true);

		firestore.collection('calls')
			.where('connectionAccountId', '==', connectInfos.accountId)
			.where('connectionCustomerId', '==', connectInfos.customerId)
			.get()
			.then((data) =>
			{
				if (data.docs)
				{
					const callsData = [];

					data.docs.forEach((doc) =>
					{
						callsData.push({
							id : doc.id,
							...doc.data()
						});

					});

					callsData.sort((a, b) =>
					{
						if (a.timestamp < b.timestamp) { return 1; }
						if (a.timestamp > b.timestamp) { return -1; }

						return 0;
					});

					setCalls([ ...callsData ]);
					setLoading(false);

				}
				else
				{
					setLoading(false);
				}
			})
			.catch(() =>
			{
				setLoading(false);
			})
		;

	}, [ connectInfos?.customerId, connectInfos.accountId, setCalls, setLoading ]);

	const calcRemainingMinutes = (addMinutes) =>
	{
		// if (serviceGuest.remainingSeconds)
		// {
		// 	return serviceGuest.remainingSeconds;
		// }
		// else
		if (connectInfos?.duration === -1)
		{
			return '-';
		}
		else if (connectInfos?.duration)
		{
			const consumedValue
				= connectInfos.consumedSeconds ? connectInfos.consumedSeconds : 0;

			const remainingSeconds
				= connectInfos.duration - consumedValue;

			if (!addMinutes)
			{
				addMinutes = 0;
			}

			if (remainingSeconds)
			{
				return (Math.ceil(remainingSeconds/60) + addMinutes).toString();
			}
			else
			{
				return (0 + addMinutes).toString();
			}
		}
		else
		{
			return 0;
		}
	};

	const handleJoin = (itemIndex) =>
	{
		// なぜかリセットされないときがあるためリセット
		setConnectPageData({});

		if (buttons[itemIndex] && connectInfos.id)
		{
			const targetBtn = buttons[itemIndex];

			const consumedValue
				= connectInfos.consumedSeconds ? connectInfos.consumedSeconds : 0;

			const remainingSeconds
				= connectInfos.duration - consumedValue;

			if (remainingSeconds || connectInfos.duration === -1)
			{
				setConnectPageData({
					connectionId : connectInfos.id,
					btnKey       : targetBtn.btnKey,
					subdomain    : targetBtn.subdomain,
					locale       : intlObj.locale ? intlObj.locale : '',
					termsPage    : termsPage
				});

				setJoinRoomNoticeDialogOpen(true);
			}
			else
			{
				setConfirmDialogState({
					show  : true,
					title : intl.formatMessage({
						id             : 'service.noTimeTitle',
						defaultMessage : 'No talk time'
					}),
					message : intl.formatMessage({
						id             : 'service.noTimeMessage',
						defaultMessage : 'The remaining time of ticket is not enough'
					})
				});
			}
		}
	};

	useEffect(() =>
	{
		// index 0 is ? get strings after ?
		const paramsValue = location.search.substring(1);

		if (paramsValue)
		{
			const paramsObj = parseUrlParams({
				paramsValues : paramsValue,
				keys         : [ 'account', 'key' ]
			});

			if (paramsObj['account'] && paramsObj['key'])
			{
				setAccountParamKey(paramsObj['account']);
				setAuthKey(paramsObj['key']);
			}
			else
			{
				setLoading(false);
				setDisabled({ value: true, reason: 'notFound' });
				setAccountParamKey('');
				setAuthKey(null);
			}
		}
		else
		{
			setLoading(false);
			setDisabled({ value: true, reason: 'notFound' });
			setAccountParamKey('');
			setAuthKey(null);
		}
	}, [ location.search ]);

	useEffect(() =>
	{
		if (authKey)
		{
			fetchInfos();
		}

		return () =>
		{
			if (roomStatusListener)
			{
				// stop listening
				try
				{
					roomStatusListener();
				}
				catch
				{
					// do nothing
				}
			}

			roomStatusListener = null;

		};
	}, [ fetchInfos, authKey ]);

	useEffect(() =>
	{
		if (page === 'history' && connectInfos?.customerId)
		{
			fetchCalls();
		}
	}, [ page, fetchCalls, connectInfos?.customerId ]);

	const disconnect = () =>
	{
		localStorage.removeItem('serviceConnectionId');
	};

	useEffect(() =>
	{

		if (connectInfos?.duration)
		{
			const consumedValue
				= connectInfos.consumedSeconds ? connectInfos.consumedSeconds : 0;

			const remainingSeconds
				= connectInfos.duration - consumedValue;

			if (remainingSeconds || connectInfos.duration === -1)
			{
				setNoTalkTime(false);
			}
			else
			{
				setNoTalkTime(true);
				localStorage.removeItem('loginTicketCode');
			}
		}
		else
		{
			setNoTalkTime(true);
		}

	}, [ connectInfos.duration, connectInfos.consumedSeconds ]);

	useEffect(() =>
	{
		return () =>
		{
			setPage('landing');
		};
	}, []);

	const btnFlagConds = {
		'english' : {
			alt : 'english',
			img : englishImg,
			text :
	<FormattedMessage
		id='service.english'
		defaultMessage='English'
	/>
		},
		'korea' : {
			alt : 'korean',
			img : koreaImg,
			text :
	<FormattedMessage
		id='service.korean'
		defaultMessage='한국어（Korean）'
	/>
		},
		'korean' : {
			alt : 'korean',
			img : koreaImg,
			text :
	<FormattedMessage
		id='service.korean'
		defaultMessage='한국어（Korean）'
	/>
		},
		'china' : {
			alt : 'chinese',
			img : chinaImg,
			text :
	<FormattedMessage
		id='service.chinese'
		defaultMessage='中文（Chinese）'
	/>
		},
		'chinese' : {
			alt : 'chinese',
			img : chinaImg,
			text :
	<FormattedMessage
		id='service.chinese'
		defaultMessage='中文（Chinese）'
	/>
		},
		'spanish' : {
			alt : 'spanish',
			img : null,
			text :
	<FormattedMessage
		id='service.spanish'
		defaultMessage='Spanish'
	/>
		},
		'spain' : {
			alt : 'spanish',
			img : null,
			text :
	<FormattedMessage
		id='service.spanish'
		defaultMessage='Spanish'
	/>
		},
		'portuguese' : {
			alt : 'portuguese',
			img : null,
			text :
	<FormattedMessage
		id='service.portuguese'
		defaultMessage='Portuguese'
	/>
		},
		'portugal' : {
			alt : 'portuguese',
			img : null,
			text :
	<FormattedMessage
		id='service.portuguese'
		defaultMessage='Portuguese）'
	/>
		}
	};

	return (
		<MuiThemeProvider theme={theme}>
			{
				loading ?
					<div className={classes.fullCoveredScreen}>
						<CircularProgress className={classes.loadingCircular}/>
					</div>

					: disabled.value ?
						<div className={classes.fullCoveredScreen}>
							<div className={classes.disabledIndicator}>
								<Typography className={classes.disabledText}>
									{ disabled.reason === 'notFound' ?
										<FormattedMessage
											id='error.urlNotCorrect'
											defaultMessage='URL is not correct'
										/>
										: disabled.reason === 'expired' ?
											<FormattedMessage
												id='error.expiredTicket'
												defaultMessage='Ticket has expired'
											/>
											:
											<FormattedMessage
												id='error.unusable'
												defaultMessage='Currently unavailable. Please contact the service operating company.'
											/>
									}
								</Typography>
							</div>
						</div>
						: page === 'landing' ?
							<LandingPage
								setPage={setPage}
							/>
							: page === 'history' ?
								<TranslationHistory
									calls={calls}
									setPage={setPage}
									customerId={connectInfos?.customerId}
								/>
								:
								<>
									<ConfirmDialog
										show={confirmDialogState.show}
										title={confirmDialogState.title}
										message={confirmDialogState.message}
										accept={() => {}}
										cancel={() => setConfirmDialogState({ show: false, title: '', message: '' })}
										closeButtonOnly
									/>
									<div
										className={classes.root}
									>
										<div className={classes.content}>
											<NoTalkTimeDialog
												open={notalkTime}
												connectParamKey={''}
												type={'connect'}
											/>
											<ServiceAppBar
												appBarHeight={appBarHeight}
											/>

											{ aspectRatio < breakPointAspectRatio ?
												<>
													<div className={classes.container}>
														<div className={classes.infomationArea}>
															{ connectInfos.ticketNumberHint &&
															<div className={`${classes.ticketNumber} ${classes.ticketNumberSP}`}>
																<div
																	className={classes.ticketNumberBtn}
																	onClick={disconnect}
																>
																	<FormattedMessage
																		id='service1.disconnect'
																		defaultMessage='Disconnect'
																	/>
																</div>
															</div>
															}
															<div className={`${classes.informationBox} ${classes.informationBoxSP}`}>
																{ 	connectInfos.duration === -1 ?
																	<div className={classes.expiryDateNoLimit}>
																		<Typography className={`${classes.expiryDateText} ${classes.expiryDateTextNoLimit}`
																		}
																		>
																			<FormattedMessage
																				id='service.period'
																				defaultMessage='Expiration'
																			/>
																		</Typography>
																		<Typography className={`${classes.expiryDateText} ${classes.expiryDateTextNoLimit}`
																		}
																		>
																			{showExpiryTime(connectInfos.expiryTimestamp)}
																		</Typography>
																	</div>
																	: (connectInfos.expiryTimestamp
														||connectInfos.expiryTimestamp === 0)
																		? <Typography className={`${classes.expiryDateText} ${classes.expiryDateTextWithLimit}`
																		} >
																			<FormattedMessage
																				id='service.period'
																				defaultMessage='Expiration'
																			/>&nbsp;&nbsp;&nbsp;&nbsp;
																			{showExpiryTime(connectInfos.expiryTimestamp)}
																		</Typography>
																		: null
																}
																{ connectInfos.duration !== -1 &&
																	<>
																		<Typography className={classes.remainingTime}>
																			<div className={classes.remainingTimeLine}>
																				<Typography className={classes.remainingTimeText}>
																					<FormattedMessage
																						id='service1.vaildTime'
																						defaultMessage='Currently available'
																					/>
																				</Typography>
																				<Typography className={classes.remainingTimeText}>
																					<FormattedMessage
																						id='paidservice.time'
																						defaultMessage='{minutesPurchased} minutes'
																						values={{
																							minutesPurchased : calcRemainingMinutes(0)
																						}}
																					/>
																				</Typography>
																			</div>
																		</Typography>
																		<Typography className={classes.explanation}>
																			<FormattedMessage
																				id='service1.caution'
																				defaultMessage='If the available minutes ended 0 during a call, the call will be extended by up to 2 minutes.'
																			/>
																		</Typography>
																	</>
																}
															</div>
														</div>
														<div
															className={classes.buttonSection}
														>
															<div className={classes.buttonAreaSP} ref={buttonAreaRef}>
																<Typography className={classes.buttonBoxTitle}>
																	<FormattedMessage
																		id='service.selectLaunguage'
																		defaultMessage='Select language and start interpreting'
																	/>
																</Typography>
																<>
																	{ [ ...Array(btnAreaRows.SP) ].map((_r, rowIndex) =>
																	{
																		return (
																			<div className={classes.buttonRow} key={rowIndex}>
																				<>
																					{[ ...Array(btnAreaContent.SP.columns) ]
																						.map((_c, columnIndex) =>
																							(
																								<ServiceButton
																									type={'connect'}
																									key={`${rowIndex}-${columnIndex}`}
																									rowIndex={rowIndex}
																									columnIndex={columnIndex}
																									buttons={buttons}
																									btnAreaContentVal={btnAreaContent.SP}
																									btnFlagConds={btnFlagConds}
																									roomAvailable={roomAvailable}
																									handleJoin={handleJoin}
																									nationalHolidaysList={
																										nationalHolidaysList
																									}
																								/>
																							)
																						)
																					}
																				</>
																			</div>
																		);
																	}
																	)}
																</>

															</div>
														</div>
													</div>

												</>
												:
												<>

													<div className={classes.container}>
														<div className={classes.infomationArea}>
															<div className={`${classes.informationBox} ${classes.informationBoxPC}`}>
																{ 	connectInfos.duration === -1 ?
																	<div className={classes.expiryDateNoLimit}>
																		<Typography className={classes.expiryDateText
																		}
																		>
																			<FormattedMessage
																				id='service.period'
																				defaultMessage='Expiration'
																			/>
																		</Typography>
																		<Typography className={classes.expiryDateText
																		}
																		>
																			{showExpiryTime(connectInfos.expiryTimestamp)}
																		</Typography>
																	</div>
																	: (connectInfos.expiryTimestamp
														||connectInfos.expiryTimestamp === 0
																	) ?
																		<Typography
																			className={`${classes.expiryDateText} ${classes.expiryDateTextWithLimit}`
																			}
																		>
																			<FormattedMessage
																				id='service.period'
																				defaultMessage='Expiration'
																			/>&nbsp;&nbsp;&nbsp;&nbsp;
																			{showExpiryTime(connectInfos.expiryTimestamp)}
																		</Typography>
																		: null
																}
																{ connectInfos.duration !== -1 &&
																	<>
																		<Typography className={classes.remainingTime}>
																			<div className={classes.remainingTimeLine}>
																				<Typography className={classes.remainingTimeText}>
																					<FormattedMessage
																						id='service1.vaildTime'
																						defaultMessage='Currently available'
																					/>
																				</Typography>
																				<Typography className={classes.remainingTimeText}>
																					<FormattedMessage
																						id='paidservice.time'
																						defaultMessage='{minutesPurchased} minutes'
																						values={{
																							minutesPurchased : calcRemainingMinutes(0)
																						}}
																					/>
																				</Typography>
																			</div>
																		</Typography>
																		<Typography className={classes.explanation}>
																			<FormattedMessage
																				id='service1.caution'
																				defaultMessage='When remaning time become 0, Max {minutes} minutes extended.'
																				values={{
																					minutes : 2
																				}}
																			/>
																		</Typography>
																	</>
																}
															</div>
														</div>
														<div
															className={classes.buttonSection}
														>
															<div className={classes.buttonAreaPC} ref={buttonAreaRef}>
																<Typography className={classes.buttonBoxTitle}>
																	<FormattedMessage
																		id='service.selectLaunguage'
																		defaultMessage='Select language and start interpreting'
																	/>
																</Typography>
																<>
																	{ [ ...Array(btnAreaRows.PC) ].map((_r, rowIndex) =>
																	{
																		return (
																			<div className={classes.buttonRow} key={rowIndex}>
																				<>
																					{[ ...Array(btnAreaContent.PC.columns) ]
																						.map((_c, columnIndex) =>
																							(
																								<ServiceButton
																									type={'connect'}
																									key={`${rowIndex}-${columnIndex}`}
																									rowIndex={rowIndex}
																									columnIndex={columnIndex}
																									buttons={buttons}
																									btnAreaContentVal={btnAreaContent.PC}
																									btnFlagConds={btnFlagConds}
																									roomAvailable={roomAvailable}
																									handleJoin={handleJoin}
																									nationalHolidaysList={
																										nationalHolidaysList
																									}
																								/>
																							)
																						)
																					}
																				</>
																			</div>
																		);
																	}
																	)}
																</>
															</div>
														</div>
													</div>

												</>
											}
											<JoinRoomNoticeDialog
												type={'connect'}
												connectPageData={connectPageData}
											/>
										</div>
									</div>
								</>
			}
		</MuiThemeProvider>
	);
};

ConnectHome.propTypes =
{
	room                        : PropTypes.object.isRequired,
	classes                     : PropTypes.object.isRequired,
	intlObj                     : PropTypes.object.isRequired,
	setJoinRoomNoticeDialogOpen : PropTypes.func.isRequired,
	connectInfos                : PropTypes.object.isRequired,
	setConnectInfos             : PropTypes.func.isRequired
};

const mapStateToProps = (state) =>
{
	return {
		room         : state.room,
		intlObj      : state.intl,
		connectInfos : state.me.connectInfos
	};
};

const mapDispatchToProps = (dispatch) =>
{
	return {
		setJoinRoomNoticeDialogOpen : (joinRoomNoticeDialogOpen) =>
		{
			dispatch(roomActions.setJoinRoomNoticeDialogOpen(joinRoomNoticeDialogOpen));
		},
		setConnectInfos : (connectInfos) =>
		{
			dispatch(meActions.setConnectInfos(connectInfos));
		}
	};
};

export default withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{
		areStatesEqual : (next, prev) =>
		{
			return (
				prev.room === next.room &&
				prev.intl === next.intl &&
				prev.intl.locale === next.intl.locale &&
				prev.intl.localesList === next.intl.localesList &&
				prev.intl.messages === next.intl.messages &&
				prev.me === next.me &&
				prev.me.connectInfos === next.me.connectInfos
			);
		}
	}
)(withStyles(styles)(ConnectHome)));
