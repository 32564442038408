import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { withRoomContext } from '../../../../RoomContext';
// material ui
import { Box, FormControl, AppBar, Typography } from '@material-ui/core';
import * as intlActions from '../../../../actions/intlActions';

// locale
import * as locales from '../../../../translations/locales';
import { createIntl, FormattedMessage } from 'react-intl';

const styles = (theme) =>
	({
		title : {
			textAlign : 'center'
		},
		languageSelectBox : {
			position       : 'absolute !important',
			marginTop      : 'auto',
			marginBottom   : 'auto',
			top            : '0',
			bottom         : '0',
			right          : '15px',
			height         : '100%',
			display        : 'flex',
			alignItems     : 'center',
			justifyContent : 'center',
			[theme.breakpoints.down('sm')] :
			{
				right          : '2px',
				justifyContent : 'end'
			}
		},
		formControl : {
			minWidth       : 75,
			display        : 'flex',
			justifyContent : 'end'
		},
		langSelect : {
			backgroundColor : 'rgba(255,255,255, 0)',
			height          : '25px',
			fontSize        : '0.8rem',
			borderRadius    : '10px',
			border          : '1px solid #292929',
			color           : '#292929',
			padding         : '0 0 0 10px',
			width           : '80px',
			[theme.breakpoints.down('sm')] :
			{
				fontSize : '0.7rem',
				width    : '70px'
			}
		}
	});

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	}
});

const ServiceAppBar = ({
	classes,
	free,
	intlObj,
	updateIntl,
	appBarHeight,
	disabled
}) =>
{
	const setLocale = (lc) =>
	{
		const one = locales.loadOne(lc);

		updateIntl({
			locale   : one.locale[0],
			messages : one.messages,
			list   	 : locales.getList()
		});

		createIntl({
			locale   : one.locale[0],
			messages : one.messages
		});

		document.documentElement.lang = one.locale[0].toUpperCase();

		localStorage.setItem('guestLacale', lc);

	};

	const appBarStyle = {
		width           : '100%',
		height          : `${appBarHeight}px`,
		position        : 'relative',
		display         : 'flex',
		justifyContent  : 'center',
		backgroundColor : '#cd2c82'
	};

	if (free)
	{
		appBarStyle.backgroundColor = '#f4da23';
		appBarStyle.color = '#292929';
	}

	return (
		<MuiThemeProvider theme={theme}>
			<AppBar
				position='fixed'
				style={appBarStyle}
			>
				<Typography className={classes.title}>
					<FormattedMessage
						id='service.serviceTitle'
						defaultMessage='LIVE interpretation ticket'
					/>
				</Typography>
				<Box className={classes.languageSelectBox}>
					<FormControl variant='outlined' className={classes.formControl}>
						<select
							value={intlObj.locale}
							onChange={(e) => setLocale(e.target.value)}
							label='Lang'
							className={classes.langSelect}
							disabled={disabled ? true : false}
						>
							<option value={'ja'}>JP</option>
							<option value={'en'}>EN</option>
							<option value={'zn'}>CN(SC)</option>
							<option value={'zn-tw'}>CN(TC)</option>
							<option value={'ko'}>KR</option>
						</select>
					</FormControl>
				</Box>
			</AppBar>
		</MuiThemeProvider>
	);
};

ServiceAppBar.propTypes =
{
	classes      : PropTypes.object.isRequired,
	free         : PropTypes.bool.isRequired,
	intlObj      : PropTypes.object.isRequired,
	updateIntl   : PropTypes.func.isRequired,
	appBarHeight : PropTypes.number.isRequired,
	disabled     : PropTypes.bool
};

const mapStateToProps = (state) =>
{
	return {
		intlObj : state.intl
	};
};

const mapDispatchToProps = (dispatch) =>
{
	return {
		updateIntl : (args) =>
		{
			dispatch(intlActions.updateIntl({ ...args }));
		}
	};
};

export default withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{
		areStatesEqual : (next, prev) =>
		{
			return (
				prev.room.monitoring === next.room.monitoring &&
				prev.room.monitoringTimer === next.room.monitoringTimer
			);
		}
	}
)(withStyles(styles)(ServiceAppBar)));
