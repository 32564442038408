import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { withRoomContext } from '../../../../RoomContext';

// Mui
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dropzone from 'react-dropzone';

// components 
import ConnectStatusBar from '../Parts/ConnectStatusBar';
import ConnectPurchaseContent from '../../Common/ConnectPurchaseContent';

// dayjs
import dayjs from 'dayjs';
import CloudUpload from '@material-ui/icons/CloudUpload';

// dayjsの タイムゾーンの設定
dayjs.extend(require('dayjs/plugin/timezone'));
dayjs.extend(require('dayjs/plugin/utc'));
dayjs.tz.setDefault('Asia/Tokyo');

const styles = () =>
	({
		root :
		{
			width          : '100%',
			height         : '100%',
			minWidth       : '300px',
			position       : 'relative',
			color          : 'var(--text-color)',
			overflowX      : 'auto',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'flex-start',
			alignItems     : 'center'

		},
		content : {
			maxWidth        : '1100px',
			minWidth        : '300px',
			width           : '100%',
			height          : '100%',
			backgroundColor : '#FFF'
		},
		section : {
			width          : '100%',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'flex-start',
			alignItems     : 'center',
			margin         : '15px 0'
		},
		headerTitle : {
			fontSize    : '1.5rem',
			width       : '100%',
			paddingLeft : '10px',
			color       : '#231815'
		},
		keys : {
			fontSize : '0.8rem',
			color    : 'var(--text-color)',
			margin   : '0',
			padding  : '0 2px'
		},
		dropzone : {
			display         : 'flex',
			flexDirection   : 'column',
			justifyContent  : 'center',
			alignItems      : 'center',
			height          : '300px',
			backgroundColor : '#FAFAFA',
			width           : '90%',
			maxWidth        : '800px',
			color           : 'var(--text-color)',
			fontSize        : '1rem',
			border          : 'dashed 2px var(--text-color)',
			marginTop       : '20px'
		},
		dropzoneContent : {
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'center',
			alignItems     : 'center',
			width          : '100%',
			height         : '100%'
		},
		dropzoneIcon : {
			height : '50px',
			width  : '50px',
			color  : 'var(--text-color)'
		},
		dropzoneBtn : {
			color           : 'var(--text-color)',
			border          : '1px solid var(--text-color)',
			backgroundColor : '#FFF',
			padding         : '2px 5px',
			'&:hover'       : {
				filter : 'saturate(105%) brightness(105%)'
			}
		},
		dropzoneTextPointer : {
			cursor : 'pointer'
		},
		table : {
			margin : '15px 0'
		},
		actionBtn : {
			color           : '#FFF',
			border          : '1px solid #00B79C',
			backgroundColor : '#00B79C',
			padding         : '4px 15px',
			'&:hover'       : {
				backgroundColor : '#00B79C',
				filter          : 'saturate(105%) brightness(105%)'
			}
		},
		backBtn : {
			color           : '#FFF',
			border          : '1px solid #989797',
			backgroundColor : '#989797',
			padding         : '3px 20px',
			'&:hover'       : {
				backgroundColor : '#989797',
				filter          : 'saturate(105%) brightness(105%)'
			}
		},
		errorMsg : {
			width        : '100%',
			height       : '1.2rem',
			color        : 'red',
			marginBottom : '8px',
			fontSize     : '0.8rem',
			textAlign    : 'center'
		},
		loading : {
			width           : '100vw',
			height          : '100vh',
			zIndex          : 9999999,
			position        : 'fixed',
			top             : 0,
			left            : 0,
			display         : 'flex',
			justifyContent  : 'center',
			alignItems      : 'center',
			backgroundColor : 'rgba(255, 255, 255, 0.5)'
		}
	});

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	}
});

// const domain = getDomainWithoutSubdomain();

// const showDate = (timestamp) =>
// {
// 	if (timestamp === 0 || timestamp === '0')
// 	{
// 		return '';
// 	}
// 	else if (timestamp)
// 	{
// 		const dayjsTime = dayjs(timestamp);

// 		return dayjsTime.format('YYYY.MM.DD H:mm');
// 	}
// 	else
// 	{
// 		return '';
// 	}
// };

// const showConnectionUrl = (paramKey, key) =>
// {
// 	return `https://${domain}/connect?/account=${paramKey}&id=${key}`;
// };

const ConnectPurchase = ({
	classes,
	connectAccount
}) =>
{

	const [ page, setPage ] = useState('import');

	const [ fileArray, setFileArray ] = useState([]);

	const [ fileResult, setFileResult ] = useState([]);

	const [ priceListPurchase, setPriceListPurchase ] = useState([]);

	// csv File
	const [ csvFile, setCsvFile ] = useState(null);

	const csvFileToArray = (string) =>
	{

		const csvHeader = string.slice(0, string.indexOf('\n')).split(',');
		const csvRows = string.slice(string.indexOf('\n') + 1).split('\n');

		const array = csvRows.map((row) =>
		{
			const values = row.split(',');

			const obj = {};

			csvHeader.forEach((header, index) =>
			{
				let headerStr = '';

				if (header)
				{
					headerStr = header.trim();
				}

				let valueVal = values[index] || '';

				if (headerStr === 'id' && values[index])
				{
					valueVal = values[index].trim();
				}
				// remove space
				obj[headerStr] = valueVal;
			});

			return obj;
		});

		setFileArray(array);
	};

	const onDropCsv = useCallback((acceptedFiles) =>
	{
		if (acceptedFiles.length > 0)
		{
			acceptedFiles.forEach((file) =>
			{
				const fileReader = new FileReader();

				fileReader.onload = () =>
				{
					const text = fileReader.result;

					// update files contents
					csvFileToArray(text);
				};

				try
				{
					fileReader.readAsText(file);
				}
				catch (e)
				{
				// eslint-disable-next-line no-console
					console.log('error:', e);
				}
			});

			setCsvFile(acceptedFiles[0]);
		}
	}, []);

	useEffect(() =>
	{
		if (fileArray && fileArray.length > 0)
		{
			const purchaseList = [];

			const resultList = [];

			connectAccount.priceList.forEach((item) =>
			{
				purchaseList.push({
					...item,
					quantity   : 0,
					expiryTerm : connectAccount.defaultExpiryTerm
				});
			});

			connectAccount.priceListTerm.forEach((item) =>
			{
				purchaseList.push({ ...item, quantity: 0 });
			});

			fileArray.forEach((fileItem) =>
			{
				const entryKey = fileItem['entry_key'];

				const emailValue = fileItem['email'] || '';

				const customerId = fileItem['id'];

				const sameKeyIndex = purchaseList.findIndex(
					(row) => row.entryKey === entryKey);

				if (entryKey.slice(0, 4) === connectAccount?.paramKey?.toUpperCase())
				{
					if (sameKeyIndex !== -1)
					{
						// quantity欄がない場合は1とみなす
						if (fileItem.quantity === undefined || (typeof fileItem.quantity === 'string' && fileItem.quantity.trim() === ''))
						{
							purchaseList[sameKeyIndex].quantity += 1;

							resultList.push({
								status     : 'success',
								entryKey   : entryKey,
								customerId : customerId,
								email      : emailValue || connectAccount.email,
								quantity   : 1
							});
						}
						else if (fileItem.quantity)
						{
							try
							{
								const quantityValue = Number(fileItem.quantity);

								if (quantityValue)
								{
									purchaseList[sameKeyIndex].quantity += quantityValue;

									resultList.push({
										status     : 'success',
										entryKey   : entryKey,
										customerId : customerId,
										email      : emailValue || connectAccount.email,
										quantity   : quantityValue
									});
								}
								else
								{
									resultList.push({
										status     : 'noQuantity',
										entryKey   : entryKey,
										customerId : customerId,
										quantity   : fileItem.quantity
									});
								}
							}
							catch
							{
								resultList.push({
									status     : 'noQuantity',
									entryKey   : entryKey,
									customerId : customerId,
									quantity   : fileItem.quantity
								});
							}
						}
						else
						{
							resultList.push({
								status     : 'noQuantity',
								entryKey   : entryKey,
								customerId : customerId,
								quantity   : fileItem.quantity
							});
						}
					}
					else
					{
						resultList.push({
							status     : 'keyNotFound',
							entryKey   : entryKey,
							customerId : customerId,
							quantity   : fileItem.quantity
						});
					}
				}
				else
				{
					resultList.push({
						status     : 'wrongAccount',
						entryKey   : entryKey,
						customerId : customerId,
						quantity   : fileItem.quantity
					});
				}

			});

			setFileResult(resultList);

			setPriceListPurchase(purchaseList);

		}
	}, [ fileArray, connectAccount ]);

	const startImport = () =>
	{
		setPage('file');
	};

	const closeConnectPurchaseDialog = () =>
	{
		setPage('import');
		setCsvFile(null);
		setFileArray([]);
		setFileResult([]);
	};

	return (
		<MuiThemeProvider theme={theme}>
			<Box className={classes.root}>

				<Box className={classes.content} >
					{ page !== 'import' ?
						<Box className={classes.section}>
							{
								page === 'complete' ?
									<Typography className={classes.headerTitle} variant='h1'>
										購入完了
									</Typography>
									:
									<Typography className={classes.headerTitle} variant='h1'>
										購入確認
									</Typography>
							}

							<ConnectStatusBar activeCount={page === 'complete' ? 3 : 2}/>

							<ConnectPurchaseContent
								buyer={'user-admin'}
								closeMethod={closeConnectPurchaseDialog}
								accountId={connectAccount?.id}
								paramKey={connectAccount?.paramKey}
								fileResult={fileResult}
								priceListPurchase={priceListPurchase}
								page={page}
								setPage={setPage}
							/>
						</Box>
						:
						<Box className={classes.section}>
							<Typography className={classes.headerTitle} variant='h1'>
								CSV読み込み
							</Typography>

							<ConnectStatusBar activeCount={1}/>

							<Box className={classes.section}>
								<Typography className={classes.keys}>
									id:顧客側識別番号、entry_key:エントリーキー、email:メールアドレス、quantity:数量
								</Typography>
								<Dropzone
									onDrop={onDropCsv}
									accept={'csv, text/csv, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values'}
								>
									{ ({ getRootProps, getInputProps }) => (
										<div className={classes.dropzone} {...getRootProps()}>
											<div>
												<input {...getInputProps()} />
												{csvFile ?
													<Typography
														className={classes.dropzoneTextPointer}
													>
														{csvFile.name}
													</Typography>
													:
													<Box className={classes.dropzoneContent}>
														<CloudUpload className={classes.dropzoneIcon}/>
														<Typography>CSVファイルをドロップまたは</Typography>
														<Button className={classes.dropzoneBtn}>ファイルを選択</Button>
													</Box>
												}
											</div>
										</div>
									)}
								</Dropzone>

							</Box>

							<Box className={classes.section}>
								<Button
									className={classes.actionBtn}
									onClick={startImport}
								>
									CSV読み込み
								</Button>
							</Box>
						</Box>
					}
				</Box>

			</Box>
		</MuiThemeProvider>
	);
};

ConnectPurchase.propTypes =
{
	classes        : PropTypes.object.isRequired,
	connectAccount : PropTypes.object
};

const mapStateToProps = () =>
{
	return {
	};
};

const mapDispatchToProps = () =>
{
	return {
	};
};

export default withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{}
)(withStyles(styles)(ConnectPurchase)));