import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { withRoomContext } from '../../../../RoomContext';

// material ui
import { Box, Typography } from '@material-ui/core';

const styles = () =>
	({
		root : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'center',
			margin         : '20px 0'
		},
		statusItem : {
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'flex-start',
			alignItems     : 'center',
			minWidth       : '90px',
			maxWidth       : '210px',
			width          : '30%',
			margin         : '0 3px'
		},
		statusItemCircle : {
			width           : '50px',
			height          : '50px',
			backgroundColor : '#EEE',
			borderRadius    : '50%',
			display         : 'flex',
			flexDirection   : 'column',
			justifyContent  : 'center',
			alignItems      : 'center'
		},
		statusItemCircleActive : {
			backgroundColor : '#00B79C !important'
		},
		statusItemNumber : {
			fontSize   : '1.2rem',
			lineHeight : '1.2rem',
			color      : 'var(--text-color)'
		},
		statusItemNumberActive : {
			fontSize   : '1.2rem',
			lineHeight : '1.2rem',
			color      : '#FFF'
		},
		statusItemText : {
			marginTop : '3px',
			fontSize  : '0.9rem',
			color     : 'var(--text-color)'
		}
	});

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	}
});

const ConnectStatusBar = ({
	classes,
	activeCount
}) =>
{
	return (
		<MuiThemeProvider theme={theme}>
			<Box className={classes.root}>
				<Box className={classes.statusItem}>
					<Box className={activeCount > 0 ? `${classes.statusItemCircle} ${classes.statusItemCircleActive}`: classes.statusItemCircle}>
						<Typography className={classes.statusItemNumberActive}>
							1
						</Typography>
					</Box>
					<Typography className={classes.statusItemText}>
						CSV読み込み
					</Typography>
				</Box>
				<Box className={classes.statusItem}>
					<Box className={activeCount > 1 ? `${classes.statusItemCircle} ${classes.statusItemCircleActive}`: classes.statusItemCircle}>
						<Typography
							className={activeCount > 1
								? classes.statusItemNumberActive
								: classes.statusItemNumber}
						>
							2
						</Typography>
					</Box>
					<Typography className={classes.statusItemText}>
						ユーザーURL発行
					</Typography>
				</Box>
				<Box className={classes.statusItem}>
					<Box className={activeCount > 2 ? `${classes.statusItemCircle} ${classes.statusItemCircleActive}`: classes.statusItemCircle}>
						<Typography
							className={activeCount > 2
								? classes.statusItemNumberActive
								: classes.statusItemNumber}
						>
							3
						</Typography>
					</Box>
					<Typography className={classes.statusItemText}>
						CSVダウンロード
					</Typography>
				</Box>
			</Box>
		</MuiThemeProvider>
	);
};

ConnectStatusBar.propTypes =
{
	classes     : PropTypes.object.isRequired,
	activeCount : PropTypes.number.isRequired

};

const mapStateToProps = () =>
{
	return {};
};

const mapDispatchToProps = () =>
{
	return {};
};

export default withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{}
)(withStyles(styles)(ConnectStatusBar)));
