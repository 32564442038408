import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { withRoomContext } from '../../../RoomContext';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

// material ui
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

// firestore
import { firestore, functions } from '../../../lib/firebase';

const styles = () =>
	({
		root :
		{
			zIndex : 99,
			width  : '100%',
			height : '100%'
		},
		dialogPaper :
		{
			padding         : '1% 1%',
			width           : 550,
			minHeight       : 550,
			backgroundColor : '#666666'
		},
		dialogPaperNew :
		{
			padding         : '1% 1%',
			width           : 600,
			minHeight       : 350,
			backgroundColor : '#666666'
		},
		title :
		{
			width         : '100%',
			display       : 'flex',
			flexDirection : 'column',
			alignItems    : 'center'
		},
		titleText :
		{
			color    : 'white',
			fontSize : '1.1rem'
		},
		dialogContent :
		{
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'flex-start',
			alignItems     : 'center',
			padding        : '8px 8px'
		},
		inputSection :
		{
			width          : '100%',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'center',
			alignItems     : 'center',
			flexGrow       : '1'
		},
		addButtonSection :
		{
			position : 'absolute',
			right    : '40px',
			top      : '24px'
		},
		addBtn :
		{
			border          : '1px solid #8eba14',
			backgroundColor : '#8eba14',
			color           : 'white',
			padding         : '4px 12px',
			'&:hover'       : {
				backgroundColor : '#8eba14',
				filter          : 'saturate(105%) brightness(105%)'
			}
		},
		inputField : {
			backgroundColor : 'white',
			border          : '1px solid var(--text-color)',
			borderRadius    : '0.4rem',
			padding         : '0.3rem 1rem',
			boxShadow       : '2px 2px 4px inset #eee',
			color           : 'var(--text-color)',
			fontSize        : '0.9rem',
			width           : '100%',
			'&:focus'       : {
				outline : 'none'
			},
			'&:disabled' : {
				backgroundColor : '#F6F6F6'
			}
		},
		deleteBtn :
		{
			color      : 'white',
			background : '#ee0000',
			border     : '1px solid #ee0000',
			padding    : '10px 18px',
			'&:hover'  : {
				background : '#ee0000',
				filter     : 'saturate(105%) brightness(110%)'
			}
		},
		createBtn :
		{
			width           : '30%',
			border          : '1px solid #8eba14',
			backgroundColor : '#8eba14',
			color           : 'white',
			'&:hover'       : {
				backgroundColor : '#8eba14',
				filter          : 'saturate(105%) brightness(105%)'
			}
		},
		cancelBtn :
		{
			width           : '30%',
			border          : '1px solid #fff',
			backgroundColor : 'transparent',
			color           : 'white'
		},
		apiList : {
			width         : '100%',
			height        : '100%',
			display       : 'flex',
			flexDirection : 'column',
			alignItems    : 'center'
		},
		topData : {
			width          : '100%',
			height         : '1.75rem',
			display        : 'flex',
			margin         : '1% 0',
			justifyContent : 'space-between',
			lineHeight     : 1
		},
		inputLabel : {
			width         : '30%',
			fontSize      : '0.9rem',
			paddingTop    : '10px',
			display       : 'flex',
			flexDirection : 'column',
			alignItems    : 'flex-start'
		},
		inputValue : {
			fontSize : '0.9rem',
			width    : '70%'
		},
		label : {
			color        : 'white',
			fontSize     : '0.9rem',
			overflowWrap : 'break-word'
		},
		textCol : {
			width          : '40%',
			fontSize       : '0.8rem',
			paddingTop     : '10px',
			display        : 'flex',
			justifyContent : 'flex-start'
		},
		buttonCol : {
			width          : '20%',
			paddingTop     : '10px',
			display        : 'flex',
			justifyContent : 'center'
		},
		typoCenter : {
			width     : '100%',
			textAlign : 'center'
		},
		noItemLine : {
			position : 'absolute',
			top      : '35%'
		},
		errorMsg : {
			color     : 'red',
			width     : '100%',
			height    : '1.2rem',
			marginTop : '5px',
			fontSize  : '1rem',
			textAlign : 'start'
		},
		loadingIndicator : {
			width          : '100%',
			height         : '100%',
			display        : 'flex',
			flexDirection  : 'column',
			alignItems     : 'center',
			justifyContent : 'center'
		},
		circularProgress : {
			color : 'white'
		}
	});

const theme = createTheme({
	overrides : {
		MuiBackdrop : {
			root : {
				backgroundColor : 'transparent'
			}
		},
		MuiDialogTitle : {
			root : {
				backgroundColor : 'transparent',
				color           : '#9ec317',
				width           : '100%'
			}
		},
		MuiDialogContent : {
			root : {
				color          : 'white',
				justifyContent : 'center',
				display        : 'flex',
				flexDirection  : 'column',
				alignItems     : 'center'
			}
		},
		MuiDialogActions : {
			root : {
				justifyContent : 'space-around'
			}
		},
		MuiTypography : {
			colorTextSecondary : {
				color : 'white'
			}
		},
		MuiPaper : {
			rounded : {
				borderRadius : '0.6rem'
			}
		}
	}
});

const ApiClientDialog = ({
	classes,
	show,
	closeMethod,
	setConfirmDialogState,
	idName,
	idVal
}) =>
{
	const [ loading, setLoading ] = useState(true);
	const [ page, setPage ] = useState('list');
	const [ apis, setApis ] = useState([]);

	const [ apiClientName, setApiClientName ] = useState('');
	const [ errorMsg, setErrorMsg ] = useState(null);

	const handleChangeApiClientName = (e) =>
	{
		setApiClientName(e.target.value);
	};

	useEffect(() =>
	{
		if (show && apis && apis.length === 0)
		{
			setLoading(true);

			firestore.collection('apiClients').where('idName', '==', idVal)
				.get()
				.then((data) =>
				{
					const apiAr = [];

					if (data)
					{
						data.forEach((doc) =>
						{
							apiAr.push({
								id : doc.id,
								...doc.data()
							});
						});
					}

					apiAr.sort((a, b) =>
					{
						if (a.createdAt < b.createdAt) { return -1; }
						if (a.createdAt > b.createdAt) { return 1; }

						return 0;
					});

					setApis([ ...apiAr ]);
					setLoading(false);
				});
		}
	// eslint-disable-next-line
	}, [ show ]);

	const deleteApiClient = (arg) =>
	{
		setErrorMsg(null);
		setLoading(true);

		setConfirmDialogState({
			show            : false,
			title           : '',
			message         : '',
			acceptMethod    : () => {},
			acceptMethodArg : {},
			closeButtonOnly : false
		});

		firestore.collection('apiClients').doc(arg.id)
			.delete()
			.then(() =>
			{
				setApis((state) =>
				{
					const apiAr = [ ...state ];

					const newApis = apiAr.filter((api) => api.id !== arg.id);

					return [ ...newApis ];
				});
				setLoading(false);
			})
			.catch(() =>
			{
				setErrorMsg('APIの削除に失敗しました。');
				setLoading(false);
			});
	};

	const deleteApiClientHandler = (id, apiName) =>
	{
		let deleteMessage = 'AIPクライアントを削除しますか?';

		if (apiName)
		{
			deleteMessage = `AIPクライアント"${apiName}"を削除しますか?`;
		}

		setConfirmDialogState({
			show            : true,
			title           : 'APIクライアントの削除',
			message         : deleteMessage,
			acceptMethod    : deleteApiClient,
			acceptMethodArg : {
				id : id
			},
			closeButtonOnly : false
		});
	};

	const downloadFile = ({ data, fileName }) =>
	{
		const bom = new Uint8Array([ 0xEF, 0xBB, 0xBF ]);
		// Create a blob with the data we want to download as a file
		const blob = new Blob([ bom, data ]);
		// Create an anchor element and dispatch a click event on it
		// to trigger a download
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');

		a.download = fileName;
		a.href = url;
		a.click();
		a.remove();
	};

	const createApiClient = () =>
	{
		const createAPIClientAPI = functions.httpsCallable('createApiClient');

		const parts = window.location.hostname.split('.');
		const subdomain = parts.shift();

		setErrorMsg(null);

		setLoading(true);

		createAPIClientAPI({
			apiName      : apiClientName,
			subdomain    : subdomain,
			userId       : idName === 'uid' ? idVal : '',
			connectionId : idName === 'connectionId' ? idVal : ''
		}).then((res) =>
		{
			const data = res.data;

			if (data.success)
			{
				setApiClientName('');

				setApis((state) =>
				{
					const apiAr = [ ...state ];
					const newApi = { id: data.apiId, apiName: data.apiName };

					apiAr.push(newApi);

					return [ ...apiAr ];
				});

				const jsonData = {
					apiId     : data.apiId,
					apiKey    : data.apiKey,
					subdomain : subdomain
				};

				downloadFile({
					data     : JSON.stringify(jsonData),
					fileName : 'apiConfig.json'
				});
				setPage('list');
				setLoading(false);
			}
			else
			{
				setErrorMsg('APIの作成に失敗しました。');
				setLoading(false);
			}
		})
			.catch(() =>
			{
				setErrorMsg('APIの作成に失敗しました。');
				setLoading(false);
			});
	};

	const backMethod = () =>
	{
		setErrorMsg('');
		setApiClientName('');
		setPage('list');
	};

	return (
		<MuiThemeProvider theme={theme}>
			<Dialog
				className={classes.root}
				open={show}
				onClose={() => {}}
				classes={{
					paper : page === 'new' ? classes.dialogPaperNew : classes.dialogPaper
				}}
			>
				{
					loading ?
						<DialogContent>
							<Box className={classes.loadingIndicator}>
								<CircularProgress className={classes.circularProgress}/>
							</Box>
						</DialogContent>
						: page === 'new' ?
							<>
								<DialogTitle id='form-dialog-title'>
									<div className={classes.title}>
										<div className={classes.titleText}>
											APIクライアント追加
										</div>
									</div>
								</DialogTitle>
								<DialogContent className={classes.dialogContent}>
									{ errorMsg &&
									<Box className={classes.topData}>
										<Typography className={classes.errorMsg}>
											{errorMsg}
										</Typography>
									</Box>
									}
									<Box className={classes.topData}>
										<Typography>
											APIIDとAPIキーが自動生成されます。
										</Typography>
									</Box>
									<Box className={classes.topData}>
										<Typography>
											作成後にダウンロードされる認証ファイルを使用してAPIに接続してください。
										</Typography>
									</Box>
									<Box className={classes.inputSection}>
										<Box className={`${classes.topData}`}>
											<Box className={classes.inputLabel}>
												<Typography className={classes.label}>名称</Typography>
											</Box>
											<Box className={classes.inputValue}>
												<input
													value={apiClientName}
													className={`${classes.inputField}`}
													onChange={handleChangeApiClientName}
													type='text'
												/>
											</Box>
										</Box>
									</Box>
								</DialogContent>
								<DialogActions>
									<Button
										className={classes.cancelBtn}
										onClick={backMethod}
										color='primary'
									>
										<FormattedMessage
											id='label.back'
											defaultMessage='Back'
										/>
									</Button>
									<Button
										className={classes.createBtn}
										onClick={createApiClient}
										color='primary'
									>
										<FormattedMessage
											id='label.create'
											defaultMessage='Create'
										/>
									</Button>
								</DialogActions>
							</>
							: // list
							<>
								<DialogTitle id='form-dialog-title'>
									<Box className={classes.title}>
										<Box className={classes.titleText}>
											APIクライアント
										</Box>
									</Box>
									<Box className={classes.addButtonSection}>
										<Button
											className={classes.addBtn}
											onClick={() => setPage('new')}
											color='primary'
										>
											追加
										</Button>
									</Box>
								</DialogTitle>
								<DialogContent className={classes.dialogContent}>
									{ errorMsg &&
									<Box className={classes.topData}>
										<Typography className={classes.errorMsg}>
											{errorMsg}
										</Typography>
									</Box>
									}

									{ !apis || apis.length === 0 ?
										<Box className={classes.noItemLine}>
											<Typography className={classes.typoCenter}>
												APIはありません
											</Typography>
										</Box>
										:
										<Box className={classes.apiList}>
											<Box className={`${classes.topData}`}>
												<Box className={classes.textCol}>
													<Typography className={classes.label}>
														名称
													</Typography>
												</Box>
												<Box className={classes.textCol}>
													<Typography className={classes.label}>
														APIキー
													</Typography>
												</Box>
												<Box className={classes.buttonCol}>
													<Typography className={classes.label}>
														削除
													</Typography>
												</Box>
											</Box>

											{ apis.map((api) => (
												<Box className={`${classes.topData}`} key={api.id}>
													<Box className={classes.textCol}>
														<Typography className={classes.label}>
															{api.apiName}
														</Typography>
													</Box>
													<Box className={classes.textCol}>
														<Typography className={classes.label}>{api.id}</Typography>
													</Box>
													<Box className={classes.buttonCol}>
														<Button
															className={classes.deleteBtn}
															onClick={() => deleteApiClientHandler(api.id, api.apiName)}
															color='secondary'
														>
															削除
														</Button>
													</Box>
												</Box>
											))}
										</Box>
									}
								</DialogContent>
								<DialogActions>
									<Button
										className={classes.cancelBtn}
										onClick={closeMethod}
										color='primary'
									>
										<FormattedMessage
											id='label.close'
											defaultMessage='Close'
										/>
									</Button>
								</DialogActions>
							</>
				}
			</Dialog>
		</MuiThemeProvider>
	);
};

ApiClientDialog.propTypes =
{
	classes               : PropTypes.object.isRequired,
	show                  : PropTypes.bool.isRequired,
	closeMethod           : PropTypes.func.isRequired,
	setConfirmDialogState : PropTypes.func.isRequired,
	idName                : PropTypes.string.isRequired,
	idVal                 : PropTypes.string.isRequired
};

const makeMapStateToProps = () =>
{
	const mapStateToProps = () =>
	{
		return {
		};
	};

	return mapStateToProps;
};

const mapDispatchToProps = () =>
{
	return {
	};
};

export default withRoomContext(connect(
	makeMapStateToProps,
	mapDispatchToProps,
	null,
	{
		areStatesEqual : () =>
		{
			return ({
			});
		}
	}
)(withStyles(styles)(ApiClientDialog)));