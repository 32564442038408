// React
import React from 'react';

// Prop types
import PropTypes from 'prop-types';

// Mui styles
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';

// material ui
import { Box, Button, Typography } from '@material-ui/core';

// Message
import { FormattedMessage } from 'react-intl';

// Components
import Header from './Header';

// images
import ServiceImage from '../../../../images/connect/service_image.png';

const styles = () =>
	({
		root : {
			width           : '100%',
			minHeight       : '100%',
			backgroundColor : '#1E1E1E',
			display         : 'flex',
			justifyContent  : 'center',
			paddingTop      : '30px'
		},
		content : {
			width          : '100%',
			height         : '100%',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'flex-start',
			alignItems     : 'center',
			maxWidth       : '600px',
			margin         : '0 15px'
		},
		introduction : {
			width    : '100%',
			maxWidth : '600px'
		},
		title : {
			fontSize : '1.3rem',
			color    : '#FFF'
		},
		introductionText : {
			fontSize : '0.8rem',
			color    : '#FFF'
		},
		image : {
			margin         : '10px 0',
			width          : '100%',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'center',
			alignItems     : 'center'
		},
		serviceImage : {
			width    : '100%',
			maxWidth : '400px',
			height   : 'auto'
		},
		buttons : {
			margin         : '10px 40px',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'flex-start',
			alignItems     : 'center',
			width          : '100%'
		},
		startBtn : {
			width           : '100%',
			maxWidth        : '600px',
			padding         : '4px 0',
			marginBottom    : '10px',
			color           : '#000',
			backgroundColor : '#FED70A',
			fontSize        : '1.2rem',
			'&:hover'       : {
				color           : '#000',
				backgroundColor : '#FED70A',
				filter          : 'saturate(105%) brightness(105%)'
			}
		},
		historyBtn : {
			width           : '100%',
			maxWidth        : '600px',
			padding         : '2px 0',
			color           : '#FFF',
			backgroundColor : '#757575',
			fontSize        : '1rem',
			'&:hover'       : {
				color           : '#FFF',
				backgroundColor : '#757575',
				filter          : 'saturate(105%) brightness(105%)'
			}
		},
		infomation : {
			margin         : '30px 0 10px 0',
			display        : 'flex',
			justifyContent : 'space-between',
			width          : '100%',
			maxWidth       : '600px'
		},
		infomationBox : {
			flexDirection  : 'column',
			justifyContent : 'center',
			alignItems     : 'center',
			border         : '1px solid #FFF',
			padding        : '10px 20px',
			margin         : '0',
			minHeight      : '80px'
		},
		infomationBoxLeft : {
			width       : '33%',
			marginRight : '5%'
		},

		infomationBoxRight : {
			width : '62%'
		},
		informationText : {
			color      : 'white',
			fontSize   : '1.1rem',
			lineHeight : '1.1rem',
			padding    : '4px 0',
			textAlign  : 'center'

		},
		informationTextSmall : {
			color     : 'white',
			fontSize  : '0.7rem',
			textAlign : 'center'
		}
	});

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	}
});

const LandingPage = ({
	classes,
	setPage
}) =>
{

	const start = () =>
	{
		setPage('home');
	};

	const showHistory = () =>
	{
		setPage('history');
	};

	return (
		<MuiThemeProvider theme={theme}>
			<Header
				setPage={setPage}
			/>
			<Box className={classes.root}>
				<Box className={classes.content}>
					<Box className={classes.introduction}>
						<Typography variant='h2' className={classes.title}>
							<FormattedMessage
								id='connect.title'
								defaultMessage='Multilingual Translation'
							/>
						</Typography>
						<Typography variant='h2' className={classes.introductionText}>
							<FormattedMessage
								id='connect.introduction1'
								defaultMessage='Multilingual translation support at'
							/>
						</Typography>
						<Typography variant='h2' className={classes.introductionText}>
							<FormattedMessage
								id='connect.introduction2'
								defaultMessage='tourist attractions, restaurant, hosplitals, transportations...'
							/>
						</Typography>
					</Box>
					<Box className={classes.image}>
						<img alt={'service introduction'} src={ServiceImage} className={classes.serviceImage}/>
					</Box>
					<Box className={classes.buttons}>
						<Button
							className={classes.startBtn}
							onClick={start}
						>
							<FormattedMessage
								id='connect.startBtn'
								defaultMessage='Start Now'
							/>
						</Button>
						<Button
							className={classes.historyBtn}
							onClick={showHistory}
						>
							<FormattedMessage
								id='connect.historyBtn'
								defaultMessage='Usage History'
							/>
						</Button>
					</Box>
					<Box className={classes.infomation}>
						<Box className={`${classes.infomationBoxLeft} ${classes.infomationBox}`}>
							<Typography className={classes.informationText}>
								<FormattedMessage
									id='connect.serviceTime1'
									defaultMessage='24 hours'
								/>
							</Typography>
							<Typography className={classes.informationText}>
								<FormattedMessage
									id='connect.serviceTime2'
									defaultMessage='365 days a year'
								/>
							</Typography>
						</Box>
						<Box className={`${classes.infomationBoxRight} ${classes.infomationBox}`}>
							<Typography className={classes.informationText}>
								<FormattedMessage
									id='connect.languages1'
									defaultMessage='5 languages available'
								/>
							</Typography>
							<Typography className={classes.informationTextSmall}>
								<FormattedMessage
									id='connect.languages2'
									defaultMessage='English, Chinese'
								/>
							</Typography>
							<Typography className={classes.informationTextSmall}>
								<FormattedMessage
									id='connect.languages3'
									defaultMessage='Korean, Spanish, Portuguese'
								/>
							</Typography>
						</Box>
					</Box>
				</Box>
			</Box>
		</MuiThemeProvider>
	);
};

LandingPage.propTypes =
{
	classes : PropTypes.object.isRequired,
	setPage : PropTypes.func.isRequired
};

export default withStyles(styles)(LandingPage);
