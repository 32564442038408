import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { withRoomContext } from '../../../../RoomContext';
import { useAuthState } from 'react-firebase-hooks/auth';

// components
import ConfirmDialog from '../../Common/ConfirmDialog';
import ConnectPurchase from './ConnectPurchase';
import ConnectUrls from './ConnectUrls';
import ConnectAdminLoginPage from './ConnectAdminLoginPage';
import ApiClientDialog from '../../Common/ApiClientDialog';
import Report from '../../Common/Report/Report';

// material ui
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Hidden from '@material-ui/core/Hidden';
import ListItem from '@material-ui/core/ListItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ListAltIcon from '@material-ui/icons/ListAlt';
import AssessmentIcon from '@material-ui/icons/Assessment';

// images
import TranslateLogo from '../../../../images/service_navi/translate_logo.svg';

// firestore
import { auth, firestore } from '../../../../lib/firebase';

// dayjs
import dayjs from 'dayjs';

// dayjsの タイムゾーンの設定
dayjs.extend(require('dayjs/plugin/timezone'));
dayjs.extend(require('dayjs/plugin/utc'));
dayjs.tz.setDefault('Asia/Tokyo');

const drawerWidth = 250;

const styles = (theme) =>
	({
		root :
		{
			width    : '100%',
			height   : '100%',
			position : 'relative',
			color    : 'var(--text-color)'
		},
		wrapper :
		{
			height                       : 'calc( 100% - 56px )',
			width                        : '100%',
			display                      : 'flex',
			justifyContent               : 'space-between',
			backgroundColor              : 'white',
			overflow                     : 'hidden',
			marginTop                    : '56px',
			[theme.breakpoints.up('sm')] : {
				marginTop : '0',
				height    : '100%'
			}
		},
		center :
		{
			width           : `calc( 100% - ${drawerWidth}px )`,
			height          : 'calc( 100% - 40px )',
			overflow        : 'auto',
			marginTop       : '5px',
			marginBottom    : '1%',
			backgroundColor : '#4B4B4B'
		},
		drawer : {
			[theme.breakpoints.up('sm')] : {
				width    : drawerWidth,
				minWidth : drawerWidth
			}

		},
		toolbar     : theme.mixins.toolbar,
		drawerPaper : {
			width           : drawerWidth,
			backgroundColor : '#4B4B4B'
		},
		drawlerList : {
			borderTop : '1px solid #707070'
		},
		drawerBox : {
			display        : 'flex',
			flexDirection  : 'column',
			flexShrink     : 0,
			height         : '100%',
			justifyContent : 'space-between'

		},
		selectedItem : {
			backgroundColor : '#343433',
			color           : '#FFF',
			borderLeft      : '6px solid #ffdc00',
			paddingLeft     : '10px !important',
			'&:hover' :
			{
				backgroundColor : '#343433',
				color           : '#FFF',
				borderLeft      : '6px solid #ffdc00'
			}
		},
		notSelectedItem : {
			color : '#FFF'
		},
		drawerLogo : {
			width          : '100%',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'flex-start',
			alignItems     : 'center',
			paddingBottom  : '20px',
			paddingTop     : '25px'
		},
		drawerLogoImg : {
			width  : '65%',
			height : 'auto'
		},
		drawerLogoText : {
			color     : '#FFF',
			fontSize  : '0.8rem',
			marginTop : '-6px',
			width     : '95%',
			textAlign : 'center'
		},
		linkToCall : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'start',
			borderTop      : '1px solid #707070',
			padding        : '15px 0 0 10px',
			cursor         : 'pointer'
		},
		linkToCallText : {
			paddingTop : '3px',
			color      : '#FFF',
			marginLeft : '10px'
		},
		linkToCallIcon : {
			width  : '25px',
			height : 'auto'
		},
		appBar : {
			backgroundColor : '#ffdc00',
			transition      : theme.transitions.create([ 'margin', 'width' ], {
				easing   : theme.transitions.easing.sharp,
				duration : theme.transitions.duration.leavingScreen
			}),
			[theme.breakpoints.up('sm')] : {
				display : 'none'
			}
		},
		appBarShift : {
			width      : `calc(100% - ${drawerWidth}px)`,
			marginLeft : drawerWidth,
			transition : theme.transitions.create([ 'margin', 'width' ], {
				easing   : theme.transitions.easing.easeOut,
				duration : theme.transitions.duration.enteringScreen
			}),
			[theme.breakpoints.up('sm')] : {
				display : 'none'
			}
		},
		menuButton : {
			marginRight : theme.spacing(2)
		},
		hide : {
			display : 'none'
		},
		drawerHeader : {
			display                      : 'flex',
			alignItems                   : 'center',
			padding                      : theme.spacing(0, 1),
			justifyContent               : 'flex-end',
			[theme.breakpoints.up('sm')] : {
				display : 'none'
			}
		},
		drawerText : {
			fontSize : '0.9rem'
		},
		drawerItemIcon : {
			color : '#FFF'
		},
		content : {
			flexGrow                     : 1,
			height                       : '100%',
			maxWidth                     : '100%',
			backgroundColor              : '#F5F5F5',
			padding                      : '10px',
			[theme.breakpoints.up('sm')] : {
				width   : `calc( 100vw - ${drawerWidth}px)`,
				padding : '20px'
			}
		},
		contentScroller : {
			width           : '100%',
			height          : '100%',
			display         : 'flex',
			flexDirection   : 'column',
			justifyContent  : 'flex-start',
			alignItems      : 'center',
			overflowX       : 'auto',
			backgroundColor : '#FFF',
			borderRadius    : '5px'
		},
		drawerBottomSection : {
			position : 'relative'
		},
		drawerBottomItem : {
			width          : '100%',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'space-between',
			alignItems     : 'center',
			paddingBottom  : '10px'
		},
		drawerAPIItem : {
			paddingBottom : '20px'
		},
		drawerLogoutItem : {
			borderBottom  : '1px solid #707070',
			paddingBottom : '15px'
		},
		drawerInquiryItem : {
			backgroundColor : '#FFF'
		},
		drawerInquirySection : {
			backgroundColor : '#FFF',
			bottom          : '0',
			width           : '100%'
		},
		drawerLogoutBtn : {
			backgroundColor : '#FFF',
			padding         : '3px 15px',
			color           : '#292929',
			'&:hover' :
			{
				backgroundColor : '#DDD'
			}
		},
		drawerAPIBtn : {
			backgroundColor : '#8eba14',
			padding         : '3px 15px',
			color           : '#FFF',
			'&:hover' :
			{
				backgroundColor : '#8eba14'
			}
		},
		drawerBtmDevider : {
			backgroundColor : 'rgba(0, 0, 0, 0.20)',
			margin          : '10px 0 5px 0',
			width           : '100%'
		},
		policyText : {
			textDecoration : 'none',
			color          : '#292929'
		},
		report : {
			width  : '100vw',
			height : '100vh'
		},
		loading : {
			width           : '100vw',
			height          : '100vh',
			zIndex          : 9999999,
			position        : 'fixed',
			top             : 0,
			left            : 0,
			display         : 'flex',
			justifyContent  : 'center',
			alignItems      : 'center',
			backgroundColor : 'rgba(255, 255, 255, 1)'
		}
	});

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	}
});

const ConnectAdmin = ({
	classes
}) =>
{

	const intl = useIntl();

	const [ drawerOpen, setDrawerOpen ] = useState(false);

	const [ page, setPage ] = useState('list');

	const [ isLoading, setIsLoading ] = useState(false);

	const [ serviceAdminLogin ] = useAuthState(auth);
	const [ connectAccount, setConnectAccount ] = useState({});

	const [ subdomains, setSubdomains ] = useState([]);

	const [ loginDialogState, setLoginDialogState ] = useState({ show: true, page: 'login' });

	const [ connections, setConnections ] = useState([]);

	const [ showApiClientDialog, setShowApiClientDialog ] = useState(false);
	const [ confirmDialogState, setConfirmDialogState ] = useState(
		{
			show            : false,
			title           : '',
			message         : '',
			acceptMethod    : () => {},
			acceptMethodArg : undefined,
			closeButtonOnly : true,
			success         : false
		}
	);

	const closeConfirmDialog = () =>
	{
		setConfirmDialogState(
			{
				show            : false,
				title           : '',
				message         : '',
				acceptMethod    : () => {},
				acceptMethodArg : undefined,
				closeButtonOnly : true
			}
		);
	};

	const closeApiClientDialog = () =>
	{
		setShowApiClientDialog(false);
		closeConfirmDialog();
	};

	const fetchConnections = useCallback(() =>
	{
		if (connectAccount?.id)
		{
			setIsLoading(true);

			firestore.collection('connections')
				.where('accountId', '==', connectAccount?.id)
				.onSnapshot((querySnapshot) =>
				{
					const connectionsList = [];

					querySnapshot.forEach((doc) =>
					{
						const docData = doc.data();

						connectionsList.push({
							id       : doc.id,
							selected : false,
							...docData
						});

					});
					connectionsList.sort((a, b) =>
					{
						if (a.salesAmount < b.salesAmount) { return -1; }
						if (a.salesAmount > b.salesAmount) { return 1; }

						return 0;
					});

					connectionsList.sort((a, b) =>
					{
						if (a.cratedAt < b.createdAt) { return -1; }
						if (a.createdAt > b.createdAt) { return 1; }

						return 0;
					});

					setConnections([ ...connectionsList ]);

					setIsLoading(false);

				});
		}
	}, [ connectAccount?.id ]);

	const selectPage = (p) =>
	{
		setPage(p);
		setDrawerOpen(false);
	};

	useEffect(() =>
	{
		if (connectAccount?.id)
		{
			fetchConnections();
		}

	}, [ connectAccount?.id, fetchConnections ]);

	const beforeunloadHandler = useCallback((e) =>
	{

		e.preventDefault();

		e.returnValue = true;

	}, []);

	useEffect(() =>
	{
		if (!loginDialogState.show)
		{
			window.addEventListener('beforeunload', beforeunloadHandler);
		}

		return () => window.removeEventListener('beforeunload', beforeunloadHandler);
	}, [ beforeunloadHandler, loginDialogState.show ]);

	const logout = () =>
	{
		setLoginDialogState({ show: true, page: 'login' });
		setConnectAccount({});
		auth.signOut();
	};

	// const moveToCallPage = () =>
	// {
	// 	history.push({
	// 		pathname : '/service',
	// 		search   : `?id=${connectAccount.paramKey}`
	// 	});
	// };

	let drawerItems = [ 'list', 'report' ];

	if (connectAccount.selfPurchase)
	{
		drawerItems = [ 'list', 'purchase', 'report' ];
	}

	const drawer = (
		<Box className={classes.drawerBox}>
			<Box>
				<div className={classes.drawerHeader}>
					<IconButton onClick={() => setDrawerOpen(false)}>
						<ChevronLeftIcon />
					</IconButton>
				</div>

				<Box className={classes.drawerLogo}>
					<img alt={'logo'} src={TranslateLogo} className={classes.drawerLogoImg}/>
					<Typography className={classes.drawerLogoText}>
						{connectAccount?.accountName}
					</Typography>
				</Box>

				<List className={classes.drawlerList}>
					{ drawerItems.map((text) => (
						<ListItem
							button
							key={text}
							onClick={() => selectPage(text)}
							className={page === text ? classes.selectedItem : classes.notSelectedItem}
						>
							<ListItemIcon >
								{	text === 'purchase' ?
									<CloudUploadIcon
										className={classes.drawerItemIcon}
									/>
									: text === 'report' ?

										< AssessmentIcon
											className={classes.drawerItemIcon}
										/>
										:
										<ListAltIcon
											className={classes.drawerItemIcon}
										/>

								}
							</ListItemIcon>
							<ListItemText
								className={classes.drawerText}
								primary={
									intl.formatMessage({
										id             : `connect.${text}`,
										defaultMessage : text
									})
								}
							/>
						</ListItem>
					))}
				</List>
			</Box>

			<Box className={classes.drawerBottomSection}>
				<Box className={`${classes.drawerBottomItem} ${classes.drawerAPIItem}`}>
					<Button
						className={classes.drawerAPIBtn}
						onClick={() => setShowApiClientDialog(true)}
					>
						API設定
					</Button>
				</Box>
				<Box className={`${classes.drawerBottomItem} ${classes.drawerLogoutItem}`}>
					<Button
						className={classes.drawerLogoutBtn}
						onClick={logout}
					>
						ログアウト
					</Button>
				</Box>
				<Box className={`${classes.drawerBottomItem} ${classes.drawerInquirySection}`}>
					<Button>
						<a
							className={classes.policyText}
							href={'https://www.tuuyaku.com/corporate-inquiries'}
							target='_blank' rel='noreferrer'
						>
							お問い合わせ
						</a>
					</Button>
				</Box>
			</Box>
		</Box>
	);

	const container = window !== undefined ? () => window.document.body : undefined;

	return (
		<MuiThemeProvider theme={theme}>
			{
				isLoading &&
					<Box className={classes.loading}>
						<CircularProgress />
					</Box>
			}

			{loginDialogState.show === null ? null
				: loginDialogState.show ?
					<ConnectAdminLoginPage
						loginDialogState={loginDialogState}
						connectAccountId={serviceAdminLogin?.uid}
						setLoginDialogState={setLoginDialogState}
						setConnectAccount={setConnectAccount}
						setIsLoading={setIsLoading}
						setSubdomains={setSubdomains}
					/>
					:
					<>
						<ConfirmDialog
							show={confirmDialogState.show}
							title={confirmDialogState.title}
							message={confirmDialogState.message}
							accept={confirmDialogState.acceptMethod}
							acceptArg={confirmDialogState.acceptMethodArg}
							cancel={closeConfirmDialog}
							closeButtonOnly={confirmDialogState.closeButtonOnly}
						/>

						<ApiClientDialog
							show={showApiClientDialog}
							closeMethod={closeApiClientDialog}
							setConfirmDialogState={setConfirmDialogState}
							idName={'connectionAccountId'}
							idVal={connectAccount?.id}
						/>
						{ page === 'report' ?
							<Box className={classes.report}>
								<Report
									show={page === 'report'}
									connectionAccountId={connectAccount?.id}
									subdomains={subdomains}
									buttons={connectAccount?.buttons || []}
									close={() => setPage('list')}
								/>
							</Box>
							: <Box className={classes.root}>
								<AppBar
									position='fixed'
									className={drawerOpen ? `${classes.appBar} ${classes.appBarShift}`
										: classes.appBar}
								>
									<Toolbar>
										<IconButton
											color='inherit'
											aria-label='open drawer'
											onClick={() => setDrawerOpen(true)}
											edge='start'

											className={drawerOpen ? `${classes.menuButton} ${classes.hide}`
												: classes.menuButton}
										>
											<MenuIcon />
										</IconButton>
									</Toolbar>
								</AppBar>
								<Box className={classes.wrapper}>
									<nav className={classes.drawer} aria-label='mailbox folders'>
										<Hidden smUp implementation='css'>
											<Drawer
												container={container}
												variant='temporary'
												anchor={'left'}
												open={drawerOpen}
												classes={{
													paper : classes.drawerPaper
												}}
												ModalProps={{
													keepMounted : true // Better open performance on mobile.
												}}
											>
												{drawer}
											</Drawer>
										</Hidden>
										<Hidden xsDown implementation='css'>
											<Drawer
												classes={{
													paper : classes.drawerPaper
												}}
												variant='permanent'
												open
											>
												{drawer}
											</Drawer>
										</Hidden>
									</nav>
									<Box
										className={classes.content}
									>
										{ page === 'purchase' && connectAccount.selfPurchase ?
											<ConnectPurchase
												connectAccount={connectAccount}
												page={page}
												fetchConnections={fetchConnections}
												drawerWidthProp={drawerWidth}
											/>
											:
											<ConnectUrls
												connections={connections}
												setConnections={setConnections}
											/>
										}
									</Box>
								</Box>
							</Box>
						}
					</>
			}
		</MuiThemeProvider>
	);

};

ConnectAdmin.propTypes =
{
	classes : PropTypes.object.isRequired
};

const mapStateToProps = () =>
{
	return {
	};
};

const mapDispatchToProps = () =>
{
	return {
	};
};

export default withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{}
)(withStyles(styles)(ConnectAdmin)));